import { createContext, useState, useEffect, useContext } from "react";
import { jwtDecode } from "jwt-decode"; // Ensure this import is correct
import { useHistory } from "react-router-dom";
import ApiLink from "../../api/ApiLink";
import { useSelector, useDispatch } from "react-redux";
import {
  setCredentials,
  setUserDetails,
  logout,
} from "../../../store/authSlice";
import { useSelectedCompany } from "../apiContext/SelectedCompanyContext";

const swal = require("sweetalert2");

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [accountDetails, setAccountDetails] = useState("");
  const [username, setUsername] = useState("");
  const [lastname, setLastname] = useState("");
  const [userType, setUserType] = useState(0);
  const [is_read_only, setis_read_only] = useState(0);
  const [company_location_id, setCompany_location_id] = useState(null);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const history = useHistory();

  const [authTokens, setAuthTokens] = useState(() => {
    const tokens = localStorage.getItem("authTokens");
    return tokens ? JSON.parse(tokens) : null;
  });

  const [user, setUser] = useState(() => {
    const tokens = localStorage.getItem("authTokens");
    if (tokens) {
      try {
        return jwtDecode(tokens.access);
      } catch (error) {
        console.error("Error decoding token during initialization:", error);
        return null;
      }
    }
    return null;
  });

  const [loading, setLoading] = useState(true);

  const loginUser = async (email, password) => {
    try {
      const response = await ApiLink.post("/api/account-login", {
        email,
        password,
      });

      if (response.status === 200) {
        const data = response.data;
        const { first_name, last_name, usertype } = data.Data.User;

        setAccountDetails(data);
        const username = data.Data.User.first_name;
        const lastname = data.Data.User.last_name;
        const userType = data.Data.User.usertype;
        const is_read_only = data.Data.User.is_read_only;
        const company_location_id = data.Data.User.company_location_id;
        const usersid = data.Data.User.usersid;
        const verification_code = data.Data.User.verification_code;
        const company_id = data.Data.User.company_id;

        setUsername(username);
        setLastname(lastname);
        setUserType(userType);
        setis_read_only(is_read_only);
        setCompany_location_id(company_location_id);

        setAuthTokens(data.Data);
        localStorage.setItem("authTokens", JSON.stringify(data.Data));

        setAuthUser({
          username: data.Data.User.first_name,
          lastname: data.Data.User.last_name,
          email: email,
          userType: userType,
        });
        dispatch(
          setCredentials({ user: data.Data.User, authTokens: data.tokens })
        );
        dispatch(
          setUserDetails({
            email,
            password,
            username: first_name,
            lastname: last_name,
            userType: usertype,
            is_read_only: is_read_only,
            company_location_id: company_location_id,
            usersid: usersid,
            verification_code: verification_code,
            company_id: company_id,
          })
        );
        setEmail(email);
        setPassword(password);

        switch (userType) {
          case 1:
            history.push("/accounts");
            break;
          case 2:
            const companyNameResponse = await fetchCompanyInfo(email, password);
            const companyname = companyNameResponse.data.Data.Company.name;
            const type = companyNameResponse.data.Data.Company.type;

            if (type === 8) {
              // sessionStorage.setItem("activeItem", "companyType8");

              // history.push(`/accounts/${companyname}`);
              history.push("/portfolio-account-home");
            } else {
              sessionStorage.setItem("activeItem", "dashboard");

              history.push(`/accounts/${companyname}`);
            }

            break;
          case 4:
            const companyInfoResponse = await fetchCompanyInfo(email, password);
            const company_name = companyInfoResponse.data.Data.Company.name;
            const type8 = companyInfoResponse.data.Data.Company.type;

            if (type8 === 8) {
              history.push("/portfolio-account-home");
            } else {
              history.push(`/location/${company_name}`);
            }
            break;
          default:
            console.error("Unknown user type:", userType);
            history.push("/"); // Redirect to home or login page if unknown user type
        }

        // Success alert
        swal.fire({
          title: "Login Successfully",
          icon: "success",
          toast: true,
          timer: 4000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      } else {
        // Handle incorrect login
        swal.fire({
          title: "Username or password does not exist",
          icon: "error",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Error during login:", error);
      swal.fire({
        title: "Username or password does not exist",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const logoutUser = () => {
    dispatch(logout());
    setAuthTokens(null);
    setUser(null);
    setAuthUser(false);
    setEmail(""); // Clear email state
    setUsername(""); // Clear username state
    localStorage.removeItem("authTokens");
    history.push("/login");

    swal.fire({
      title: "You have been logged out...",
      icon: "success",
      toast: true,
      timer: 3000,
      position: "top-right",
      timerProgressBar: true,
      showConfirmButton: false,
    });
  };

  const contextData = {
    user,
    setUser,
    authTokens,
    setAuthTokens,
    loginUser,
    logoutUser,
    authUser,

    username,
    lastname,
    email,
    password,
    userType,

    ...auth,
  };

  const fetchCompanyInfo = async (email, password) => {
    try {
      const response = await ApiLink.post("/api/company-info", {
        email,
        password,
      });
      return response; // Return the response so it can be used in the switch case
    } catch (error) {
      console.error("Error fetching company info:", error);
      throw error; // Re-throw the error if you want to handle it elsewhere
    }
  };
  useEffect(() => {
    const tokens = localStorage.getItem("authTokens");
    if (tokens) {
      try {
        const parsedTokens = JSON.parse(tokens);
        const decodedUser = jwtDecode(parsedTokens.access);
        dispatch(
          setCredentials({ user: decodedUser, authTokens: parsedTokens })
        );
        setAuthUser(true);
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
    setLoading(false);
  }, [dispatch]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
