import React, { useContext, useEffect, useState } from "react";
import AsideLayout from "./AsideLayout/AsideLayout";
import { ThemeContext } from "../../components/context/themeContext/ThemeContext";
import ApiLink from "../../components/api/ApiLink";
import { useSelector } from "react-redux";

export default function FlowSensor() {
  const Swal = require("sweetalert2");

  const { sideBarOpen } = useContext(ThemeContext);

  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);
  const {
    color,
    textColor,
    backgroundColor,
    tableHeaderColor,
    tableHeaderTextColor,
  } = useContext(ThemeContext);
  const [addFlowSensor, setAddFlowSensor] = useState(false);
  const [editFlowSensor, setEditFlowSensor] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("1");

  const [flowSensorData, setFlowSensorData] = useState([]);
  const [loading, setLoading] = useState(false);
  const is_read_only = useSelector((state) => state.auth.is_read_only);
  // const is_read_only = useState(0);

  const [flowSensorDetails, setFlowSensorDetails] = useState({
    id: null,
    manufacturer: null,
    model: null,
    pipeSize: null,
    kValue: null,
    offset: null,
  });

  const [addFlowSensorForm, setAddFlowSensorForm] = useState({
    manufacturer: null,
    model: null,
    pipeSize: null,
    kValue: null,
    offset: null,
  });

  useEffect(() => {
    fetchFlowSensor();
  }, [selectedStatus, email, password]);

  const fetchFlowSensor = async () => {
    setLoading(true);
    try {
      const response = await ApiLink.post("/api/flow-sensor", {
        email,
        password,
        status: selectedStatus,
      });
      if (response.status === 200) {
        if (Array.isArray(response.data.Data)) {
          setFlowSensorData(response.data.Data);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleEditFlowSensor = (
    id,
    manufacturer,
    model,
    pipeSize,
    kValue,
    offset
  ) => {
    setEditFlowSensor(true);

    setFlowSensorDetails({
      id: id,
      manufacturer: manufacturer,
      model: model,
      pipeSize: pipeSize,
      kValue: kValue,
      offset: offset,
    });
  };
  const toggleCloseEditFlowSensor = () => {
    setEditFlowSensor(false);

    setFlowSensorDetails({
      id: null,
      manufacturer: null,
      model: null,
      pipeSize: null,
      kValue: null,
      offset: null,
    });
  };

  const handleEditFlowSensorSubmit = (e) => {
    e.preventDefault();
    console.log("Edit Flow Sensor: ", flowSensorDetails);
    Swal.fire({
      title: "Do you want to update this flow-sensor?",
      showCancelButton: true,
      confirmButtonText: "Update",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#FF0000",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await ApiLink.post("/api/update-flow-sensor", {
            email: email,
            password: password,
            flow_sensor_id: flowSensorDetails.id,
            manufacturer: flowSensorDetails.manufacturer,
            model: flowSensorDetails.model,
            pipe_size: flowSensorDetails.pipeSize,
            k_value: flowSensorDetails.kValue,
            offset: flowSensorDetails.offset,
          });
          if (response.status === 200) {
            Swal.fire({
              title: "Flow-Sensor Updated Successfully",
              icon: "success",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
            toggleCloseEditFlowSensor();
            fetchFlowSensor();
          } else {
            Swal.fire({
              title: "Error Occured",
              icon: "error",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        } catch (error) {
          Swal.fire({
            title: "Error Occured",
            icon: "error",
            toast: true,
            timer: 4000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      }
    });
  };

  const toggleAddFlowSensor = () => {
    setAddFlowSensor(!addFlowSensor);
    clearAddDeviceForm();
  };

  const clearAddDeviceForm = () => {
    setAddFlowSensorForm({
      manufacturer: null,
      model: null,
      pipeSize: null,
      kValue: null,
      offset: null,
    });
  };

  const handleAddFlowSensorSubmit = (e) => {
    e.preventDefault();
    console.log("Add Flow Sensor: ", addFlowSensorForm);

    Swal.fire({
      title: "Do you want to add this flow sensor?",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await ApiLink.post("/api/add-flow-sensor", {
            email: email,
            password: password,
            manufacturer: addFlowSensorForm.manufacturer,
            model: addFlowSensorForm.model,
            pipe_size: addFlowSensorForm.pipeSize,
            k_value: addFlowSensorForm.kValue,
            offset: addFlowSensorForm.offset,
          });
          if (response.status === 200) {
            Swal.fire({
              title: "FlowSensor Added Successfully",
              icon: "success",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
            fetchFlowSensor();
            toggleAddFlowSensor();
          }
        } catch (error) {
          Swal.fire({
            title: "Error Occured",
            icon: "error",
            toast: true,
            timer: 4000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      }
    });
  };

  const toggleBodyScroll = (disable) => {
    if (disable) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    toggleBodyScroll(addFlowSensor);
    return () => toggleBodyScroll(false);
  }, [addFlowSensor]);

  const handleDeleteFlowSensor = (id, manufacturer) => {
    Swal.fire({
      title: "Do you want to delete this flowsensor?",
      text: `"${manufacturer}" will be deleted permanently.`,
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#FF0000",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await ApiLink.post("/api/delete-flow-sensor", {
            email: email,
            password: password,
            flow_sensor_id: id,
          });

          if (response.status === 200) {
            if (response.data.Data.is_valid == false) {
              Swal.fire({
                title: "Error Occured. Please try again.",
                icon: "warning",
                toast: true,
                timer: 4000,
                position: "top-right",
                timerProgressBar: true,
                showConfirmButton: false,
              });
              fetchFlowSensor();
            } else if (response.data.Data.is_valid == true) {
              Swal.fire({
                title: "FlowSensor Deleted Successfully",
                icon: "success",
                toast: true,
                timer: 4000,
                position: "top-right",
                timerProgressBar: true,
                showConfirmButton: false,
              });
              fetchFlowSensor();
            }
          }
        } catch (error) {
          Swal.fire({
            title: "Error Occured. Please try again.",
            icon: "warning",
            toast: true,
            timer: 4000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
          fetchFlowSensor();
        }
      }
    });
  };

  const handleRestoreFlowSensor = (id, manufacturer) => {
    Swal.fire({
      title: "Do you want to restore this flowsensor?",
      text: `"${manufacturer}" will be restored.`,
      showCancelButton: true,
      confirmButtonText: "Restore",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#FF0000",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await ApiLink.post("/api/restore-flow-sensor", {
            email: email,
            password: password,
            flow_sensor_id: id,
          });

          if (response.status === 200) {
            console.log(response.data);
            if (response.data.Data.is_valid == false) {
              Swal.fire({
                title: "Error Occured. Please try again.",
                icon: "warning",
                toast: true,
                timer: 4000,
                position: "top-right",
                timerProgressBar: true,
                showConfirmButton: false,
              });
            } else if (response.data.Data.is_valid == true) {
              Swal.fire({
                title: "FlowSensor Restored Successfully",
                icon: "success",
                toast: true,
                timer: 4000,
                position: "top-right",
                timerProgressBar: true,
                showConfirmButton: false,
              });
              fetchFlowSensor();
            }
          }
        } catch (error) {
          Swal.fire({
            title: "Error Occured. Please try again.",
            icon: "warning",
            toast: true,
            timer: 4000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      }
    });
  };

  return (
    <AsideLayout>
      <div
        className={`min-h-screen bg-gray-100 dark:bg-gray-700 transition-all duration-300 `}
        style={{ marginLeft: sideBarOpen ? "16rem" : "0" }}
      >
        <div className="p-4 mt-20">
          <div className="p-4 shadow-md bg-white dark:bg-gray-800 rounded-md">
            <div className="flex justify-between mb-1">
              <h1 className="p-0 text-black dark:text-white text-xl font-bold text-left rtl:text-right">
                List of Flow Sensors
              </h1>
              {is_read_only !== 1 && (
                <button
                  className={`px-3 py-1.5 text-sm rounded-md bg-${color}-200 hover:bg-${color}-400 text-${color}-800 font-semibold`}
                  style={{
                    backgroundColor: `${backgroundColor}`,
                    color: `${textColor}`,
                  }}
                  onClick={toggleAddFlowSensor}
                >
                  + Add Flow Sensor
                </button>
              )}
            </div>
            <div className="flex justify-start">
              <div className="flex flex-col py-2 w-36 ml-2">
                <label
                  htmlFor="status"
                  className="block mb-0 text-sm font-semibold text-gray-900 dark:text-white"
                >
                  Status
                </label>
                <select
                  id="status"
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="1">Enabled</option>
                  <option value="3">Deleted</option>
                </select>
              </div>
            </div>
            <div className="relative overflow-x-auto ">
              <table className="animate__animated animate__fadeIn rounded-md w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 cursor-pointer font-inter leading-4">
                <thead
                  className="border border-black btn-blue text-sm text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400 font-inter leading-4"
                  style={{
                    backgroundColor: `${tableHeaderColor}`,
                    color: `${tableHeaderTextColor}`,
                  }}
                >
                  <tr className="">
                    <th scope="col" className="px-6 py-3 border border-black">
                      Manufacturer
                    </th>
                    <th scope="col" className="px-6 py-3 border border-black">
                      Model
                    </th>
                    <th scope="col" className="px-6 py-3 border border-black">
                      Piple Size
                    </th>
                    <th scope="col" className="px-6 py-3 border border-black">
                      K Value
                    </th>
                    <th scope="col" className="px-6 py-3 border border-black">
                      Offset
                    </th>
                    <th scope="col" className="px-6 py-3 border border-black">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm font-inter leading-4">
                  {loading ? (
                    <tr>
                      <td colSpan="7" className="h-64">
                        <div
                          role="status"
                          className="flex justify-center items-center h-full"
                        >
                          <svg
                            aria-hidden="true"
                            className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span className="sr-only">Loading...</span>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    flowSensorData.map((flowSensor) => (
                      <tr
                        key={flowSensor.id}
                        className="odd:bg-gray-100 odd:dark:bg-gray-900 even:bg-purple-200 even:dark:bg-gray-800 bg-white border-b text-black dark:text-white dark:bg-gray-800 dark:border-gray-700"
                      >
                        <td className="border border-black dark:border-gray-300 px-6 py-4">
                          {flowSensor.manufacturer}
                        </td>
                        <td className="border border-black dark:border-gray-300 px-6 py-4">
                          {flowSensor.model}{" "}
                        </td>
                        <td className="border border-black dark:border-gray-300 px-6 py-4">
                          {flowSensor.pipe_size}
                        </td>
                        <td className="border border-black dark:border-gray-300 px-6 py-4">
                          {flowSensor.k_value}
                        </td>
                        <td className="border border-black dark:border-gray-300 px-6 py-4">
                          {flowSensor.offset}
                        </td>
                        <td className="border border-black dark:border-gray-300 px-6 py-4">
                          {is_read_only !== 1 && (
                            <button
                              className="edit p-1 hover:bg-white dark:hover:bg-gray-200 rounded-full"
                              onClick={() =>
                                toggleEditFlowSensor(
                                  flowSensor.id,
                                  flowSensor.manufacturer,
                                  flowSensor.model,
                                  flowSensor.pipe_size,
                                  flowSensor.k_value,
                                  flowSensor.offset
                                )
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="blue"
                                className="size-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                />
                              </svg>
                            </button>
                          )}
                          {is_read_only !== 1 && selectedStatus !== "3" && (
                            <button
                              className="delete p-1 hover:bg-white dark:hover:bg-gray-200 rounded-full"
                              onClick={() =>
                                handleDeleteFlowSensor(
                                  flowSensor.id,
                                  flowSensor.manufacturer
                                )
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="red"
                                className="size-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                />
                              </svg>
                            </button>
                          )}
                          {is_read_only !== 1 && selectedStatus == "3" && (
                            <button
                              className="delete p-1 hover:bg-white dark:hover:bg-gray-200 rounded-full"
                              onClick={() =>
                                handleRestoreFlowSensor(
                                  flowSensor.id,
                                  flowSensor.manufacturer
                                )
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="size-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                                />
                              </svg>
                            </button>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {addFlowSensor && (
          <div
            id="defaultModal"
            tabindex="-1"
            aria-hidden="true"
            className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full font-inter"
            style={{
              // backdropFilter: "blur(5px)",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
              {/* <!-- Modal content --> */}
              <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                {/* <!-- Modal header --> */}
                <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    Add Flow Sensor
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="defaultModal"
                    onClick={toggleAddFlowSensor}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                {/* <!-- Modal body --> */}
                <form onSubmit={handleAddFlowSensorSubmit}>
                  <div className="grid gap-4 mb-4 sm:grid-cols-2">
                    <div>
                      <label
                        for="manufacturer"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Manufacturer
                      </label>
                      <input
                        type="text"
                        name="manufacturer"
                        value={addFlowSensorForm.manufacturer}
                        onChange={(e) =>
                          setAddFlowSensorForm((prev) => ({
                            ...prev,
                            manufacturer: e.target.value,
                          }))
                        }
                        required
                        id="manufacturer"
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>
                    <div>
                      <label
                        for="model"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Model
                      </label>
                      <input
                        type="text"
                        name="model"
                        value={addFlowSensorForm.model}
                        onChange={(e) =>
                          setAddFlowSensorForm((prev) => ({
                            ...prev,
                            model: e.target.value,
                          }))
                        }
                        required
                        id="model"
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>
                    <div>
                      <label
                        for="pipeSize"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Pipe Size
                      </label>
                      <input
                        type="text"
                        name="pipeSize"
                        value={addFlowSensorForm.pipeSize}
                        onChange={(e) =>
                          setAddFlowSensorForm((prev) => ({
                            ...prev,
                            pipeSize: e.target.value,
                          }))
                        }
                        required
                        id="pipeSize"
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>

                    <div>
                      <label
                        for="kValue"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        K Value
                      </label>
                      <input
                        type="number"
                        name="kValue"
                        value={addFlowSensorForm.kValue}
                        onChange={(e) =>
                          setAddFlowSensorForm((prev) => ({
                            ...prev,
                            kValue: e.target.value,
                          }))
                        }
                        step="any"
                        required
                        id="kValue"
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>

                    <div>
                      <label
                        for="offset"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Offset
                      </label>
                      <input
                        type="number"
                        value={addFlowSensorForm.offset}
                        onChange={(e) =>
                          setAddFlowSensorForm((prev) => ({
                            ...prev,
                            offset: e.target.value,
                          }))
                        }
                        step="any"
                        required
                        name="offset"
                        id="offset"
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-end">
                    <button
                      className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                      style={{ backgroundColor: "red" }}
                      onClick={toggleAddFlowSensor}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center"
                      style={{ backgroundColor: "#009D01" }}
                    >
                      Save Flow Sensor
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        {editFlowSensor && (
          <div
            id="defaultModal"
            tabindex="-1"
            aria-hidden="true"
            className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full font-inter"
            style={{
              // backdropFilter: "blur(5px)",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
              {/* <!-- Modal content --> */}
              <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                {/* <!-- Modal header --> */}
                <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    Edit Flow Sensor
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="defaultModal"
                    onClick={toggleCloseEditFlowSensor}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                {/* <!-- Modal body --> */}
                <form onSubmit={handleEditFlowSensorSubmit}>
                  <div className="grid gap-4 mb-4 sm:grid-cols-2">
                    <div>
                      <label
                        for="manufacturer"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Manufacturer
                      </label>
                      <input
                        type="text"
                        name="manufacturer"
                        value={flowSensorDetails.manufacturer}
                        onChange={(e) =>
                          setFlowSensorDetails((prev) => ({
                            ...prev,
                            manufacturer: e.target.value,
                          }))
                        }
                        id="manufacturer"
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>
                    <div>
                      <label
                        for="model"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Model
                      </label>
                      <input
                        type="text"
                        name="model"
                        value={flowSensorDetails.model}
                        onChange={(e) =>
                          setFlowSensorDetails((prev) => ({
                            ...prev,
                            model: e.target.value,
                          }))
                        }
                        id="model"
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>
                    <div>
                      <label
                        for="pipeSize"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Pipe Size
                      </label>
                      <input
                        type="text"
                        name="pipeSize"
                        value={flowSensorDetails.pipeSize}
                        onChange={(e) =>
                          setFlowSensorDetails((prev) => ({
                            ...prev,
                            pipeSize: e.target.value,
                          }))
                        }
                        id="pipeSize"
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>

                    <div>
                      <label
                        for="kValue"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        K Value
                      </label>
                      <input
                        type="number"
                        name="kValue"
                        value={flowSensorDetails.kValue}
                        onChange={(e) =>
                          setFlowSensorDetails((prev) => ({
                            ...prev,
                            kValue: e.target.value,
                          }))
                        }
                        id="kValue"
                        step="any"
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>

                    <div>
                      <label
                        for="offset"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Offset
                      </label>
                      <input
                        type="number"
                        name="offset"
                        value={flowSensorDetails.offset}
                        onChange={(e) =>
                          setFlowSensorDetails((prev) => ({
                            ...prev,
                            offset: e.target.value,
                          }))
                        }
                        id="offset"
                        step="any"
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-end">
                    <button
                      className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                      style={{ backgroundColor: "red" }}
                      onClick={toggleCloseEditFlowSensor}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center"
                      style={{ backgroundColor: "#009D01" }}
                    >
                      Save Flow Sensor
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </AsideLayout>
  );
}
