import React, { useState, useContext, useEffect } from "react";
import DatePicker from "react-datepicker";
import { ThemeContext } from "../../../components/context/themeContext/ThemeContext";
import ApiLink from "../../../components/api/ApiLink";
import { useSelector } from "react-redux";
import { useSelectedCompany } from "../../../components/context/apiContext/SelectedCompanyContext";

export default function LocationAuditActivityLogs({ companyName }) {
  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);
  const { sideBarOpen } = useContext(ThemeContext);

  const [isHideDetails, setShowDetails] = useState(false);
  const [activeTab, setActiveTab] = useState("audit");

  const [activity1stDate, setActivity1stDate] = useState(null);
  const [activity2ndDate, setActivity2ndDate] = useState(null);

  const [auditOffset, setAuditOffset] = useState(0);
  const [auditLimit, setAuditLimit] = useState(1);

  const [activityOffset, setActivityOffset] = useState(0);
  const [activityLimit, setActivityLimit] = useState(1);

  const [auditLogsData, setAuditLogsData] = useState([]);
  const [activityLogsData, setActivityLogsData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userListData, setUserListData] = useState([]);

  const {
    color,
    textColor,
    backgroundColor,
    tableHeaderColor,
    tableHeaderTextColor,
  } = useContext(ThemeContext);
  const { selectedLocation } = useSelectedCompany();

  const [auditSelectedDate, setAuditSelectedDate] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    endDate: new Date(new Date().setDate(new Date().getDate() + 0)),
  });

  // ----------------------------------------------------------

  const [activitySelectedDate, setActivitySelectedDate] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    endDate: new Date(new Date().setDate(new Date().getDate() + 0)),
  });

  const fetchAuditLogs = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await ApiLink.post("/api/get-audit-logs", {
        email,
        password,
        limit: 100,

        start_date: formatDate(auditSelectedDate.startDate),
        end_date: formatDate(auditSelectedDate.endDate),
        company_location_id: selectedLocation.locationId,
      });

      if (response.status === 200) {
        setAuditLogsData(
          Array.isArray(response.data.Data) ? response.data.Data : []
        );
      } else {
        throw new Error(response.data.Message || "Failed to fetch audit logs");
      }
    } catch (error) {
      setError("Failed to fetch audit logs. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAuditLogs();
  }, [email, password]);

  const fetchActivityLogs = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await ApiLink.post("/api/get-activity-logs", {
        email,
        password,
        limit: 100,

        start_date: formatDate(activitySelectedDate.startDate),
        end_date: formatDate(activitySelectedDate.endDate),
        company_location_id: selectedLocation.locationId,
      });

      if (response.status === 200 && response.data.Status === 200) {
        setActivityLogsData(
          Array.isArray(response.data.Data) ? response.data.Data : []
        );
        console.log("Activity Logs:", response.data.Data);
      } else {
        throw new Error(response.data.Message || "Failed to fetch audit logs");
      }
    } catch (error) {
      setError("Failed to fetch audit logs. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchActivityLogs();
  }, [email, password]);

  const handleAuditFirstDateChange = (date) => {
    if (date) {
      const startDate = new Date(date);
      setAuditSelectedDate((prev) => ({
        ...prev,
        startDate,
      }));
    }
  };
  const handleAuditEndDateChange = (date) => {
    if (date) {
      const endDate = new Date(date);
      setAuditSelectedDate((prev) => ({
        ...prev,
        endDate,
      }));
    }
  };

  const handleAuditSearch = () => {
    fetchAuditLogs();
  };

  // <------Activity Logs DATEPICKER ------->
  const handleActivityFirstDateChange = (date) => {
    if (date) {
      const startDate = new Date(date);
      setActivitySelectedDate((prev) => ({
        ...prev,
        startDate,
      }));
    }
  };

  const handleActivitySecondtDateChange = (date) => {
    if (date) {
      const endDate = new Date(date);
      setActivitySelectedDate((prev) => ({
        ...prev,
        endDate,
      }));
    }
  };

  const handleActivityLogs = () => {
    fetchActivityLogs();
  };

  // <------------------------------->
  const getColorCode = (color) => {
    switch (color) {
      case "blue":
        return "#1c64f2";
      case "green":
        return "#057a55";
      case "purple":
        return "#7e3af2";
      case "red":
        return "#FF0000";
      case "yellow":
        return "#9F580A";
      case "pink":
        return "#D61F69";
      default:
        return "#057a55";
    }
  };
  const toggleSvg = () => {
    setShowDetails(!isHideDetails);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const fetchUserList = async () => {
      try {
        const response = await ApiLink.post("/api/user-list", {
          email: email,
          password: password,
          company_id: selectedLocation.companyId,
        });
        if (response.status === 200) {
          if (Array.isArray(response.data.Data)) {
            setUserListData(response.data.Data);
          }
        } else {
          console.error("Error:", response.data);
        }
      } catch (error) {}
    };

    fetchUserList();
  }, [email, password, selectedLocation.companyId]);

  const AUDIT_TYPES = {
    1: "Update",
    2: "Add",
    4: "Delete",
    5: "Activate",
    6: "Inactivate",
  };
  return (
    <div className="">
      <div className="">
        <div className="  bg-white rounded-md dark:bg-gray-800">
          <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
            <ul
              className="flex flex-wrap -mb-px text-sm font-medium text-center"
              id="default-styled-tab"
              data-tabs-toggle="#default-styled-tab-content"
              data-tabs-active-classnames="text-blue-600 hover:text-blue-600 dark:text-gray-500 dark:hover:text-gray-500 border-gray-600 dark:border-gray-500"
              data-tabs-inactive-classnames="dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300"
              role="tablist"
            >
              <li className="me-2" role="presentation">
                <button
                  className={`inline-block p-4 border-b-2 rounded-t-lg ${
                    activeTab === "audit"
                      ? "border-blue-600 text-blue-600"
                      : "text-gray-300 hover:text-gray-800 hover:border-gray-300 dark:hover:text-gray-300"
                  }`}
                  onClick={() => setActiveTab("audit")}
                  type="button"
                  role="tab"
                  aria-selected={activeTab === "audit"}
                >
                  Audit Log
                </button>
              </li>
              <li className="me-2" role="presentation">
                <button
                  className={`inline-block p-4 border-b-2 rounded-t-lg ${
                    activeTab === "activity"
                      ? "border-blue-600 text-blue-600"
                      : "text-gray-300 hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                  }`}
                  onClick={() => setActiveTab("activity")}
                  type="button"
                  role="tab"
                  aria-selected={activeTab === "activity"}
                >
                  Activity Log
                </button>
              </li>
            </ul>
          </div>
          <div id="default-styled-tab-content">
            {activeTab === "audit" && (
              <div
                className="py-4 rounded-md bg-white- dark:bg-gray-800"
                id="styled-profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <div
                  date-rangepicker="true"
                  className="flex flex-col md:flex-row items-center"
                >
                  <div className="relative mb-2 md:mb-0">
                    <div className="absolute left-3 top-1/2 transform -translate-y-1/2 z-10 text-gray-600">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                        />
                      </svg>
                    </div>
                    <DatePicker
                      selected={auditSelectedDate.startDate}
                      onChange={handleAuditFirstDateChange}
                      dateFormat="yyyy-MM-dd"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-40 pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholderText="Select date start"
                    />
                  </div>

                  <span className="mx-4 text-gray-500">to</span>

                  <div className="relative mb-2 md:mb-0">
                    <div className="absolute left-3 top-1/2 transform -translate-y-1/2 z-10 text-gray-600">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                        />
                      </svg>
                    </div>
                    <DatePicker
                      selected={auditSelectedDate.endDate}
                      onChange={handleAuditEndDateChange}
                      dateFormat="yyyy-MM-dd"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-40 pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholderText="Select date end"
                    />
                  </div>

                  <div className="relative flex items-center px-4">
                    <button
                      style={{
                        backgroundColor: `${backgroundColor}`,
                        color: `${textColor}`,
                      }}
                      onClick={handleAuditSearch}
                      className={`bg-${color}-200 p-2 rounded-md text-${color}-800 font-medium`}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            )}

            {activeTab === "audit" && (
              <div>
                <div className="flex justify-start">
                  <button
                    className={`flex py-1.5 px-2 items-center text-sm rounded-md border-2 border-gray-400 mr-2 gap-1 hover:bg-gray-400 text-gray-600 hover:text-white dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-4 "
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                      />
                    </svg>
                    <h1>Download CSV</h1>
                  </button>
                </div>
                <h1 className="p-5 text-xl font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                  Audit Logs
                </h1>
                <div className="relative overflow-x-auto mt-4">
                  <table className="border border-black dark:border-white animate__animated animate__fadeIn w-full text-sm text-left rtl:text-right font-inter leading-4">
                    <thead
                      className={`text-sm uppercase  dark:text-${color}-800 bg-${color}-200 dark:bg-${color}-200 font-inter leading-4`}
                      style={{
                        backgroundColor: `${tableHeaderColor}`,
                        color: `${tableHeaderTextColor}`,
                      }}
                    >
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 border border-black"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 border border-black"
                        >
                          Section
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 border border-black"
                        >
                          Action
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 border border-black"
                        >
                          User
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 border border-black"
                        >
                          Account Info
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 border border-black"
                        >
                          Location Info
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 border border-black"
                        >
                          Request
                        </th>
                      </tr>
                    </thead>

                    {isLoading ? (
                      <tr>
                        <td colSpan="7" className="h-64">
                          <div
                            role="status"
                            className="flex justify-center items-center h-full"
                          >
                            <svg
                              aria-hidden="true"
                              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className="sr-only">Loading...</span>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tbody className="text-sm font-inter leading-4">
                        {auditLogsData.length > 0 ? (
                          auditLogsData.map((auditLog) => {
                            const user = userListData.find(
                              (user) => user.usersid === auditLog.user_id
                            );
                            const userName = user
                              ? `${user.first_name} ${user.last_name}`
                              : auditLog.user_id;

                            return (
                              <tr
                                key={auditLog.audit_log_id}
                                className="odd:bg-gray-100 odd:dark:bg-gray-900 even:bg-purple-200 even:dark:bg-gray-800 text-black dark:text-white bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                              >
                                <td className="border border-black dark:border-white px-6 py-4">
                                  {auditLog.date_added}
                                </td>
                                <td className="border border-black dark:border-white px-6 py-4">
                                  {auditLog.section_id}
                                </td>
                                <td className="border border-black dark:border-white px-6 py-4">
                                  {AUDIT_TYPES[auditLog.audit_type]}
                                </td>
                                <td className="border border-black dark:border-white px-6 py-4">
                                  <h1>{userName}</h1>
                                  <h1>({auditLog.user_id})</h1>
                                </td>
                                <td className="border border-black dark:border-white px-6 py-4">
                                  {auditLog.company_name || "N/A"}
                                </td>
                                <td className="border border-black dark:border-white px-6 py-4">
                                  <div>
                                    <h1>{auditLog.location_name || "N/A"}</h1>
                                    <h1
                                      className={`${
                                        auditLog.company_location_id === null
                                          ? "hidden"
                                          : ""
                                      }`}
                                    >
                                      ({auditLog.company_location_id})
                                    </h1>
                                  </div>
                                </td>
                                <td className="border border-black dark:border-white px-3 py-4">
                                  <button className="" onClick={toggleSvg}>
                                    {isHideDetails ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="size-6"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="size-6"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                                        />
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                        />
                                      </svg>
                                    )}
                                  </button>
                                  <div className="mt-1 w-40 overflow-x-auto">
                                    <div className="whitespace-nowrap">
                                      {isHideDetails && auditLog.request}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td
                              colSpan="7"
                              className="text-center py-4 text-black dark:text-white"
                            >
                              No audit logs found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            )}

            {/* <-----------------ACTIVITY LOG-----------------------------> */}

            {activeTab === "activity" && (
              <div
                className="p-4 rounded-md bg-white dark:bg-gray-800"
                id="styled-dashboard"
                role="tabpanel"
                aria-labelledby="dashboard-tab"
              >
                <div
                  date-rangepicker="true"
                  className="flex flex-col md:flex-row items-center"
                >
                  <div className="relative mb-2 md:mb-0">
                    <div className="absolute left-3 top-1/2 transform -translate-y-1/2 z-10 text-gray-600">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                        />
                      </svg>
                    </div>
                    <DatePicker
                      selected={activitySelectedDate.startDate}
                      onChange={handleActivityFirstDateChange}
                      dateFormat="yyyy-MM-dd"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-40 pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholderText="Select date start"
                    />
                  </div>

                  <span className="mx-4 text-gray-500">to</span>

                  <div className="relative mb-2 md:mb-0">
                    <div className="absolute left-3 top-1/2 transform -translate-y-1/2 z-10 text-gray-600">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                        />
                      </svg>
                    </div>
                    <DatePicker
                      selected={activitySelectedDate.endDate}
                      onChange={handleActivitySecondtDateChange}
                      dateFormat="yyyy-MM-dd"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-40 pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholderText="Select date end"
                    />
                  </div>

                  <div className="relative flex items-center px-4">
                    <button
                      style={{
                        backgroundColor: `${backgroundColor}`,
                        color: `${textColor}`,
                      }}
                      onClick={handleActivityLogs}
                      className={`bg-${color}-200 p-2 rounded-md text-${color}-800 font-medium`}
                    >
                      Search
                    </button>
                  </div>
                </div>
                <div className="flex justify-start mt-3">
                  <button
                    className={`flex py-1.5 px-2 items-center text-sm rounded-md border-2 border-gray-400 mr-2 gap-1 hover:bg-gray-400 text-gray-600 hover:text-white dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-4 "
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                      />
                    </svg>
                    <h1>Download CSV</h1>
                  </button>
                </div>
                <div>
                  <h1 className="p-5 text-xl font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                    Activity Logs
                  </h1>
                  <div className="relative overflow-x-auto mt-4">
                    <table className="border border-black animate__animated animate__fadeIn w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <thead
                        className={`text-sm text-gray-700 uppercase dark:text-gray-400 font-inter leading-4`}
                        style={{
                          backgroundColor: `${tableHeaderColor}`,
                          color: `${tableHeaderTextColor}`,
                        }}
                      >
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 border border-black"
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border border-black"
                          >
                            Action
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border border-black"
                          >
                            User
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border border-black"
                          >
                            Account Info
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border border-black"
                          >
                            Location Info
                          </th>
                        </tr>
                      </thead>{" "}
                      {isLoading ? (
                        <tr>
                          <td colSpan="7" className="h-64">
                            <div
                              role="status"
                              className="flex justify-center items-center h-full"
                            >
                              <svg
                                aria-hidden="true"
                                className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                              <span className="sr-only">Loading...</span>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <tbody className="text-sm font-inter leading-4 border border-black dark:border-white">
                          {activityLogsData.length > 0 ? (
                            activityLogsData.map((activityLogs, index) => (
                              <tr
                                key={index}
                                className="odd:bg-gray-100 odd:dark:bg-gray-900 even:bg-purple-200 even:dark:bg-gray-800 text-black dark:text-white bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                              >
                                <td className="border border-black dark:border-white px-6 py-4">
                                  {activityLogs.Date.split(" ").map(
                                    (part, idx) => (
                                      <div key={idx}>
                                        {idx === 0
                                          ? part
                                          : idx === 1
                                          ? part +
                                            " " +
                                            activityLogs.Date.split(" ")[2]
                                          : null}
                                        {idx === 0 && <br />}{" "}
                                        {/* Add a line break after the date */}
                                      </div>
                                    )
                                  )}
                                </td>
                                <td className="border border-black dark:border-white px-6 py-4">
                                  <div>
                                    <h1>
                                      {activityLogs.Audit_Event}:{" "}
                                      {activityLogs.Details}
                                    </h1>
                                    <h1>
                                      Scheduled Start Date:{" "}
                                      {activityLogs.Schedule_Start}
                                    </h1>
                                    <h1>
                                      Scheduled End Date:{" "}
                                      {activityLogs.Schedule_End}
                                    </h1>
                                  </div>
                                </td>
                                <td className="border border-black dark:border-white px-6 py-4">
                                  {activityLogs.User}
                                </td>
                                <td className="border border-black dark:border-white px-6 py-4">
                                  <div>
                                    <h1>{activityLogs.Company_name}</h1>
                                    <h1>({activityLogs.Company_id}) </h1>
                                  </div>
                                </td>
                                <td className="border border-black dark:border-white px-6 py-4">
                                  <div>
                                    <h1>{activityLogs.Location_name}</h1>
                                    <h1>({activityLogs.Location_id})</h1>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="7"
                                className="text-center py-4 text-black dark:text-white"
                              >
                                No activity logs found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
