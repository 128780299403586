import React, { useState, useContext, useEffect } from "react";
import { ThemeContext } from "../context/themeContext/ThemeContext";
import states from "states-us";
import { useSelector } from "react-redux";
import ApiLink from "../api/ApiLink";

const EditAccounts = ({
  companyname,
  companystatus,
  companyphone,
  companycity,
  companyaddress,
  companytype,
  companyZip,
  companyState,
  companyaddress2,
  website,
  is_test_account,
  company_id,
  fetchCompanyList,
}) => {
  const Swal = require("sweetalert2");

  const { color, handleThemeColor } = useContext(ThemeContext);

  const [editAccount, setEditAccount] = useState(false);

  const [name, setName] = useState(companyname);
  const [status, setStatus] = useState(companystatus);
  const [phone, setPhone] = useState(companyphone);
  const [city, setCity] = useState(companycity);
  const [address, setAddress] = useState(companyaddress);
  const [address2, setAddress2] = useState(companyaddress2);
  const [type, setType] = useState(companytype);
  const [zip, setZip] = useState(companyZip);
  const [state, setState] = useState(companyState);
  const [websiteLink, setWebsiteLink] = useState(website);
  const [isTestAccount, setIsTestAccount] = useState(is_test_account);
  const [companyID, setCompanyID] = useState(company_id);

  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);

  const getStateNameFromAbbreviation = (abbreviation) => {
    const state = states.find((state) => state.abbreviation === abbreviation);
    return state ? state.name : abbreviation;
  };

  // Helper function to get the state abbreviation from name
  const getStateAbbreviationFromName = (name) => {
    const state = states.find((state) => state.name === name);
    return state ? state.abbreviation : name;
  };

  const toggleEditAccount = () => {
    setEditAccount(true);
  };
  const closeEditAccount = () => {
    setEditAccount(false);
  };
  const getColorCode = (color) => {
    switch (color) {
      case "blue":
        return "#1c64f2";
      case "green":
        return "#057a55";
      case "purple":
        return "#7e3af2";
      case "red":
        return "#FF0000";
      case "yellow":
        return "#9F580A";
      case "pink":
        return "#D61F69";
      default:
        return "#057a55";
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("company_id", companyID);

    console.log("Name: ", name);
    console.log("companyType: ", type);
    console.log("Phone: ", phone);
    console.log("WebsiteURL: ", websiteLink);
    console.log("isTestAccount?: ", isTestAccount ? 1 : 0);
    console.log("Address1: ", address);
    console.log("Address2: ", address2);
    console.log("City: ", city);
    console.log("State: ", state);
    console.log("Zip: ", zip);
    Swal.fire({
      title: "Do you want to update this company account?",
      showCancelButton: true,
      confirmButtonText: "Save",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await ApiLink.post("/api/update-company", {
            email: email,
            password: password,
            company_id: companyID,
            name: name,
            type: type,
            phone: phone,
            website_url: websiteLink,
            is_test_account: isTestAccount ? 1 : 0,
            address_line1: address,
            address_line2: address2 || "",
            city: city,
            state: state,
            zip: zip,
          });
          if (response.status === 200) {
            try {
              const zohoResponse = await ApiLink.post(
                "/api/zoho-crm-update-account",
                {
                  name: name,
                  type: type,
                  phone: phone,
                  website: websiteLink || "",
                  is_test_account: isTestAccount ? 1 : 0,
                  address_line1: address,
                  address_line2: address2 || "",
                  city: city,
                  state: state,
                  zip: zip,
                  recordid: companyID
                }
              );
              if (zohoResponse.data[0].code == "SUCCESS") {
                Swal.fire({
                  title: "Company Account Updated Successfully",
                  icon: "success",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
                setCompanyID("");
                setName("");
                setType("");
                setPhone("");
                setWebsiteLink("");
                setIsTestAccount(false);
                setAddress("");
                setAddress2("");
                setCity("");
                setState("");
                setZip("");
                closeEditAccount();
                fetchCompanyList();
              }
            } catch (error) {
              Swal.fire({
                title: "There is something wrong in updating the company account",
                icon: "error",
                toast: true,
                timer: 4000,
                position: "top-right",
                timerProgressBar: true,
                showConfirmButton: false,
              });
            }

            // if (response.data.Data.is_valid == true) {
            //   Swal.fire({
            //     title: "Company Account Updated Successfully",
            //     icon: "success",
            //     toast: true,
            //     timer: 4000,
            //     position: "top-right",
            //     timerProgressBar: true,
            //     showConfirmButton: false,
            //   });
            //   setCompanyID("");
            //   setName("");
            //   setType("");
            //   setPhone("");
            //   setWebsiteLink("");
            //   setIsTestAccount(false);
            //   setAddress("");
            //   setAddress2("");
            //   setCity("");
            //   setState("");
            //   setZip("");
            //   closeEditAccount();
            //   fetchCompanyList();
            // }
          } else if (response.data.Data.is_valid == false) {
            // Extract error messages from the object
            const errorMessages = Object.entries(response.data.Data.errors)
              .map(([field, messages]) => `${messages.join(", ")}`)
              .join("\n");

            Swal.fire({
              title: errorMessages,

              icon: "error",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        } catch (error) {
         
        }
      }
    });
  };

  const COMPANY_TYPES = {
    1: "City",
    2: "Commercial Building",
    3: "Community",
    4: "Construction",
    5: "County",
    6: "Gold Course",
    7: "Park",
    8: "Landscape Company",
    9: "Homeowner",
    0: "INFO_NOT_AVAILABLE",
  };

  const handleZipChange = (e) => {
    const value = e.target.value;
    // Allow only digits and limit to 5 characters
    if (/^\d{0,5}$/.test(value)) {
      setZip(value);
    }
  };

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/\D/g, "");

    if (phoneNumber.length <= 3) {
      return phoneNumber;
    } else if (phoneNumber.length <= 6) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    } else {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    }
  };

  const toggleBodyScroll = (disable) => {
    if (disable) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    toggleBodyScroll(editAccount);
    return () => toggleBodyScroll(false);
  }, [editAccount]);

  return (
    <>
      <button onClick={toggleEditAccount}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="blue"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
          />
        </svg>
      </button>

      {editAccount && (
        <div
          id="defaultModal"
          tabindex="-1"
          aria-hidden="true"
          className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full"
          style={{
            // backdropFilter: "blur(5px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
            {/* <!-- Modal content --> */}
            <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
              {/* <!-- Modal header --> */}
              <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Edit Sustain Sprinkler Account
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal"
                  onClick={closeEditAccount}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* <!-- Modal body --> */}

              <form onSubmit={handleSubmit}>
                <div className="grid gap-4 mb-4 sm:grid-cols-2">
                  <div>
                    <label
                      for="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Company Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      id="name"
                      className="bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="address"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Address
                    </label>
                    <input
                      type="text"
                      name="address"
                      id="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      className="bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="companyType"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Company Type
                    </label>
                    <select
                      id="companyType"
                      onChange={(e) => setType(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option disabled selected>
                        {COMPANY_TYPES[type]}
                      </option>
                      <option value="1">City</option>
                      <option value="2">Commercial Building</option>
                      <option value="3">Community</option>
                      <option value="4">Construction</option>
                      <option value="5">County</option>
                      <option value="6">Gold Course</option>
                      <option value="7">Park</option>
                      <option value="8">Landscape Company</option>
                      <option value="9">Homeowner</option>
                      <option value="0">INFO_NOT_AVAILABLE</option>
                    </select>
                  </div>

                  <div>
                    <label
                      for="brand"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Address 2
                    </label>
                    <input
                      type="text"
                      name="status"
                      value={address2}
                      onChange={(e) => setAddress2(e.target.value)}
                      id="status"
                      className="bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="phone"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Phone
                    </label>
                    <input
                      type="text"
                      name="brand"
                      id="brand"
                      value={phone}
                      onChange={(e) =>
                        setPhone(formatPhoneNumber(e.target.value))
                      }
                      className="bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="brand"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      City
                    </label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      className="bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="websiteUrl"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Website Url
                    </label>
                    <input
                      type="text"
                      name="websiteUrl"
                      value={websiteLink}
                      onChange={(e) => setWebsiteLink(e.target.value)}
                      id="websiteUrl"
                      className="bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="location"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Location
                    </label>
                    <select
                      id="location"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={state}
                      onChange={(e) =>
                        setState(getStateAbbreviationFromName(e.target.value))
                      }
                    >
                      <option value={state}>
                        {getStateNameFromAbbreviation(state)}{" "}
                      </option>

                      {states.map((stateObj) => (
                        <option
                          key={stateObj.abbreviation}
                          value={stateObj.abbreviation}
                        >
                          {stateObj.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      checked={isTestAccount}
                      onChange={(e) => setIsTestAccount(e.target.checked)}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="default-checkbox"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Is Test Account
                    </label>
                  </div>
                  <div>
                    <label
                      for="zip"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Zip Code
                    </label>
                    <input
                      type="number"
                      name="zip"
                      id="zip"
                      maxLength={5}
                      value={zip}
                      onChange={handleZipChange}
                      className="bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-end">
                  <button
                    className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                    style={{ backgroundColor: "red" }}
                    onClick={closeEditAccount}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center"
                    style={{ backgroundColor: "#009D01" }}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditAccounts;
