import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../components/context/themeContext/ThemeContext";
import { useSelectedCompany } from "../../../components/context/apiContext/SelectedCompanyContext";
import states from "states-us";
import ApiLink from "../../../components/api/ApiLink";
import { useSelector } from "react-redux";
import { setSelectedLocation } from "../../../store/selectedCompanySlice";
import Select from "react-select";

export default function ViewLocation() {
  const swal = require("sweetalert2");

  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);
  const is_read_only = useSelector((state) => state.auth.is_read_only);
  // const is_read_only = useState(0);
  const company_location_id = useSelector(
    (state) => state.auth.company_location_id
  );
  const [selectedStatus, setSelectedStatus] = useState("1");

  const [addLocation, setAddLocation] = useState(false);
  const [editLocation, setEditLocation] = useState(false);
  const {
    selectedCompanyName,
    viewLocationCompanyLocation,
    setViewLocationCompanyLocation,
    updateSelectedLocation,
  } = useSelectedCompany();

  const [companyData, setCompanyData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const {
    textColor,
    backgroundColor,
    tableHeaderTextColor,
    tableHeaderColor,
    sideBarOpen,
  } = useContext(ThemeContext);
  const userType = useSelector((state) => state.auth.userType);

  const [sfwmdLogRawData, setsfwmdLogRawData] = useState({});

  const [selectedLocationEdit, setSelectedLocationEdit] = useState({
    locationName: null,
    locationAddressLine1: null,
    locationType: null,
    locationAddressLine2: null,
    locationWaterType: null,
    locationCity: null,
    locationGeoLat: null,
    locationGeoLong: null,
    locationState: null,
    locationZip: null,
    locationSFWMD1: null,
    locationSFWMD2: null,
    locationSFWMD3: null,
    locationSFWMD4: null,
    locationSFWMDLogic: null,
    locationWateringAlgo: null,
    locationWateringInterval: null,
    locationWateringRainThreshold: null,
    locationForecast: null,
    locationContactName1: null,
    locationContactName2: null,
    locationContactEmail1: null,
    locationContactEmail2: null,
    locationContactPhone1: null,
    locationContactPhone2: null,
    company_location_id: null,
    company_id: null,
  });

  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [addLocationForm, setAddLocationForm] = useState({
    location_name: null,
    address_line1: null,
    type: null,
    address_line2: null,
    water_type: null,
    city: null,
    state: null,
    zip: null,
    sfwmd1: null,
    sfwmd2: null,
    sfwmd3: null,
    sfwmd4: null,
    sfwmd_logic: "AND",
    watering_algo: null,
    watering_rain_threshold: null,
    contact1_name: null,
    contact1_email: null,
    contact1_phone: null,
    contact2_name: null,
    contact2_email: null,
    contact2_phone: null,
  });

  const [gridDetails, setGridDetails] = useState({
    grid: null,
    latitude: null,
    longitude: null,
  });

  const handleGetLocation = () => {
    swal
      .fire({
        html: `
      <h3 style="font-size: 24px; font-weight: bold; margin-bottom: 10px;">
        Choose an option to proceed
      </h3>
     
    `,
        showCloseButton: true,
        showDenyButton: true,
        confirmButtonText: "Get Current Location",
        denyButtonText: "Get Address Coordinates",
        confirmButtonColor: "#62CB31",
        denyButtonColor: "#337AB7",
        customClass: {
          popup: "swal-custom-popup",
          confirmButton: "swal-custom-confirm-button",
          denyButton: "swal-custom-deny-button",
          closeButton: "swal-custom-close-button",
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          getCurrentLocation();
        } else if (result.isDenied) {
          getOnlyLocation(latitude, longitude);
        }
      });
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude.toString());
          setLongitude(longitude.toString());
          swal
            .fire({
              title: "Update SFWMD #1?",
              text: "Do you want to update SFWMD #1 with the current location?",
              icon: "question",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, update it!",
              cancelButtonText: "No, just get coordinates",
            })
            .then((result) => {
              if (result.isConfirmed) {
                getSFWMDGrid(latitude, longitude);
              } else if (result.isDenied) {
              }
            });
        },
        (error) => {
          console.error("Error getting location:", error);
          swal.fire(
            "Error",
            "Unable to get your current location. Please try again.",
            "error"
          );
        }
      );
    } else {
      swal.fire(
        "Error",
        "Geolocation is not supported by your browser.",
        "error"
      );
    }
  };

  const getOnlyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude.toString());
          setLongitude(longitude.toString());
        },
        (error) => {
          console.error("Error getting location:", error);
          swal.fire(
            "Error",
            "Unable to get your current location. Please try again.",
            "error"
          );
        }
      );
    } else {
      swal.fire(
        "Error",
        "Geolocation is not supported by your browser.",
        "error"
      );
    }
  };

  const getSFWMDGrid = async (lat, lng) => {
    let address = "";

    // Check if all address fields are provided
    if (
      addLocationForm.address_line1 &&
      addLocationForm.city &&
      addLocationForm.state &&
      addLocationForm.zip
    ) {
      address = `${addLocationForm.address_line1}, ${
        addLocationForm.address_line2 || ""
      } ${addLocationForm.city}, ${addLocationForm.state}, ${
        addLocationForm.zip
      }`;
    }

    try {
      let requestBody = {};

      // Only add latitude and longitude if available
      if (lat && lng) {
        requestBody.latitude = lat.toString();
        requestBody.longitude = lng.toString();
      }

      // Only add address if it's a non-empty valid string
      if (address.trim()) {
        requestBody.address = address;
      }

      // Check if we have valid data to send (latitude, longitude, or address)
      if (Object.keys(requestBody).length === 0) {
        throw new Error("No valid location data provided");
      }

      const response = await ApiLink.post("/api/sfwmd-grid", requestBody);

      // Update the form with the response grid details
      setAddLocationForm((prevForm) => ({
        ...prevForm,
        sfwmd1: response.data.Grid,
      }));

      // Handle the response here
      console.log("SFWMD Grid Response:", response.data);

      // Success message
      swal.fire("Success", "SFWMD Grid updated successfully", "success");
    } catch (error) {
      console.error("Error updating SFWMD Grid:", error);

      // Check if the error response has a message and use it if available
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "Failed to update SFWMD Grid. Please try again.";

      swal.fire("Error", errorMessage, "error");
    }
  };

  const handleEditGetLocation = () => {
    swal
      .fire({
        html: `
        <h3 style="font-size: 24px; font-weight: bold; margin-bottom: 10px;">
          Choose an option to proceed
        </h3>
      `,
        showCloseButton: true,
        showDenyButton: true,
        confirmButtonText: "Get Current Location",
        denyButtonText: "Get Address Coordinates",
        confirmButtonColor: "#62CB31",
        denyButtonColor: "#337AB7",
        customClass: {
          popup: "swal-custom-popup",
          confirmButton: "swal-custom-confirm-button",
          denyButton: "swal-custom-deny-button",
          closeButton: "swal-custom-close-button",
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          getEditCurrentLocation();
        } else if (result.isDenied) {
          getEditOnlyLocation();
        }
      });
  };

  const getEditCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          setSelectedLocationEdit((prev) => ({
            ...prev,
            locationGeoLat: latitude.toString(),
            locationGeoLong: longitude.toString(),
          }));

          swal
            .fire({
              title: "Update SFWMD #1?",
              text: "Do you want to update SFWMD #1 with the current location?",
              icon: "question",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, update it!",
              cancelButtonText: "No, just get coordinates",
            })
            .then((result) => {
              if (result.isConfirmed) {
                getEditSFWMDGrid(latitude, longitude);
              }
            });
        },
        (error) => {
          console.error("Error getting location:", error);
          swal.fire(
            "Error",
            "Unable to get your current location. Please try again.",
            "error"
          );
        }
      );
    } else {
      swal.fire(
        "Error",
        "Geolocation is not supported by your browser.",
        "error"
      );
    }
  };

  const getEditOnlyLocation = () => {
    console.log("getEditOnlyLocation: ", getEditSFWMDGrid());
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     (position) => {
    //       const { latitude, longitude } = position.coords;
    //       setSelectedLocationEdit((prev) => ({
    //         ...prev,
    //         locationGeoLat: latitude.toString(),
    //         locationGeoLong: longitude.toString(),
    //       }));
    //     },
    //     (error) => {
    //       console.error("Error getting location:", error);
    //       swal.fire(
    //         "Error",
    //         "Unable to get your current location. Please try again.",
    //         "error"
    //       );
    //     }
    //   );
    // } else {
    //   swal.fire(
    //     "Error",
    //     "Geolocation is not supported by your browser.",
    //     "error"
    //   );
    // }
  };

  const getEditSFWMDGrid = async (lat, lng) => {
    let address = "";

    // Check if all address fields are provided
    if (
      selectedLocationEdit.locationAddressLine1 &&
      selectedLocationEdit.locationCity &&
      selectedLocationEdit.locationState &&
      selectedLocationEdit.locationZip
    ) {
      address = `${selectedLocationEdit.locationAddressLine1}, ${
        selectedLocationEdit.locationAddressLine2 || ""
      } ${selectedLocationEdit.locationCity}, ${
        selectedLocationEdit.locationState
      }, ${selectedLocationEdit.locationZip}`;
    }

    try {
      let requestBody = {};

      // Only add latitude and longitude if available
      if (lat && lng) {
        requestBody.latitude = lat.toString();
        requestBody.longitude = lng.toString();
      }

      // Only add address if it's a non-empty valid string
      if (address.trim()) {
        requestBody.address = address;
      }

      // Check if we have valid data to send (latitude, longitude, or address)
      if (Object.keys(requestBody).length === 0) {
        throw new Error("No valid location data provided");
      }

      const response = await ApiLink.post("/api/sfwmd-grid", requestBody);

      // Check if the returned Grid is in sfwmdLogRawData
      if (
        !Object.keys(sfwmdLogRawData).includes(response.data.Grid.toString())
      ) {
        const result = await swal.fire({
          title: "Warning",
          text:
            "The returned SFWMD Grid #" +
            response.data.Grid.toString() +
            " does not match any in the SFWMD Log. Do you want to update anyway?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, update anyway",
          cancelButtonText: "No, don't update",
        });

        if (result.isConfirmed) {
          // Update the form with the response grid details
          setSelectedLocationEdit((prevForm) => ({
            ...prevForm,
            locationSFWMD1: response.data.Grid.toString(),
            locationGeoLat: response.data.latitude,
            locationGeoLong: response.data.longitude,
          }));

          await swal.fire(
            "Updated",
            "SFWMD Grid #" +
              response.data.Grid.toString() +
              " updated with non-matching value.",
            "info"
          );
        } else {
          setSelectedLocationEdit((prevForm) => ({
            ...prevForm,
            //locationSFWMD1: response.data.Grid.toString(),
            locationGeoLat: response.data.latitude,
            locationGeoLong: response.data.longitude,
          }));
          await swal.fire(
            "Cancelled",
            "SFWMD Grid update was cancelled. Geolocation updated instead.",
            "info"
          );
          return; // Exit the function without updating
        }
      } else {
        // Update the form with the response grid details
        setSelectedLocationEdit((prevForm) => ({
          ...prevForm,
          locationSFWMD1: response.data.Grid.toString(),
          locationGeoLat: response.data.latitude,
          locationGeoLong: response.data.longitude,
        }));

        // Success message
        await swal.fire(
          "Success",
          "SFWMD Grid updated successfully",
          "success"
        );
      }

      // Handle the response here
      console.log("SFWMD Grid Response:", response.data);
    } catch (error) {
      console.error("Error updating SFWMD Grid:", error);

      // Check if the error response has a message and use it if available
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "Failed to update SFWMD Grid. Please try again.";

      await swal.fire("Error", errorMessage, "error");
    }
  };

  useEffect(() => {
    ApiLink.get("/api/sfwmdLogRaw")
      .then((response) => {
        setsfwmdLogRawData(response.data.sfwmdLogRaw);
      })
      .catch((error) => {});
  }, []);

  const fetchCompanyList = async () => {
    setIsLoading(true);
    try {
      const response = await ApiLink.post("/api/company-locations", {
        email: email,
        password: password,
        company_id: selectedCompanyName.company_id,
        status: selectedStatus,
      });

      if (response.status === 200) {
        if (Array.isArray(response.data.Data.Company_location)) {
          setCompanyData(response.data.Data.Company_location);
        }
      }
    } catch (error) {
      // Handle error
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userType === 1) {
      fetchCompanyList();
    }
  }, [
    email,
    password,
    selectedCompanyName.company_id,
    userType,
    selectedStatus,
  ]);

  useEffect(() => {
    if (userType === 2) {
      fetchCompanyList4UserType2();
    }
  }, [
    email,
    password,
    selectedCompanyName.company_id,
    setCompanyData,
    userType,
  ]);

  const fetchCompanyList4UserType2 = async () => {
    setIsLoading(true);
    try {
      const response = await ApiLink.post("/api/company-locations", {
        email: email,
        password: password,
        company_id: selectedCompanyName.company_id,
      });

      if (response.status === 200) {
        const companyLocationIdString = company_location_id;

        if (companyLocationIdString !== null) {
          const validLocationIds = JSON.parse(companyLocationIdString);
          console.log("validLocationIds:", validLocationIds);

          if (Array.isArray(response.data.Data.Company_location)) {
            const filteredLocations =
              response.data.Data.Company_location.filter((location) =>
                validLocationIds.includes(
                  location.company_location_id.toString()
                )
              );
            setCompanyData(filteredLocations); // Update state with filtered data
          }
        } else {
          setCompanyData(response.data.Data.Company_location);
        }
      } else {
      }
    } catch (error) {
      console.error("Error fetching company locations:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getColorCode = (color) => {
    switch (color) {
      case "blue":
        return "#1c64f2";
      case "green":
        return "#057a55";
      case "purple":
        return "#7e3af2";
      case "red":
        return "#FF0000";
      case "yellow":
        return "#9F580A";
      case "pink":
        return "#D61F69";
      default:
        return "#057a55";
    }
  };

  const handleLocationClick = (
    locationId,
    locationName,
    companyId,
    city,
    state,
    zip,
    address
  ) => {
    updateSelectedLocation({
      locationId,
      locationName,
      companyId,
      city,
      state,
      zip,
      address,
    });
  };
  const COMPANY_TYPES = {
    1: "City",
    2: "Commercial Building",
    3: "Community",
    4: "Construction",
    5: "County",
    6: "Gold Course",
    7: "Park",
    8: "Landscape Company",
    9: "Homeowner",
    0: "INFO_NOT_AVAILABLE",
  };

  const WATER_TYPES = {
    1: "City",
    2: "Lake/Well Water",
    3: "Reclaimed Water",
    4: "Info Not Available",
  };

  const toggleAddLocation = () => {
    setAddLocation(!addLocation);
    clearAddLocationForm();
    setLongitude("");
    setLatitude("");
  };

  const toggleEditLocation = () => {
    setEditLocation(true);
  };
  const toggleCloseEditLocation = () => {
    setEditLocation(false);
    clearEditLocation();
  };

  const clearEditLocation = () => {
    setSelectedLocationEdit({
      locationName: null,
      locationAddressLine1: null,
      locationType: null,
      locationAddressLine2: null,
      locationWaterType: null,
      locationCity: null,
      locationGeoLat: null,
      locationGeoLong: null,
      locationState: null,
      locationZip: null,
      locationSFWMD1: null,
      locationSFWMD2: null,
      locationSFWMD3: null,
      locationSFWMD4: null,
      locationSFWMDLogic: null,
      locationWateringAlgo: null,
      locationWateringInterval: null,
      locationWateringRainThreshold: null,
      locationForecast: null,
      locationContactName1: null,
      locationContactName2: null,
      locationContactEmail1: null,
      locationContactEmail2: null,
      locationContactPhone1: null,
      locationContactPhone2: null,
      company_location_id: null,
      company_id: null,
    });
  };

  const handleAddLocationSubmit = (e) => {
    e.preventDefault();
    console.log("Email: ", email);
    console.log("Password: ", password);
    console.log("Company_id", selectedCompanyName.company_id);
    console.log("Location Name: ", addLocationForm.location_name);
    console.log("AddressLine1: ", addLocationForm.address_line1);
    console.log("Company Type: ", addLocationForm.type);
    console.log("AddressLine2: ", addLocationForm.address_line2);
    console.log("Water Type: ", addLocationForm.water_type);
    console.log("Longitude: ", longitude);
    console.log("Latitude: ", latitude);
    console.log("City: ", addLocationForm.city);
    console.log("State: ", addLocationForm.state);
    console.log("Zip", addLocationForm.zip);
    console.log("SFWMD1: ", addLocationForm.sfwmd1);
    console.log("SFWMD2: ", addLocationForm.sfwmd2);
    console.log("SFWMD3: ", addLocationForm.sfwmd3);
    console.log("SFWMD4: ", addLocationForm.sfwmd4);
    console.log("SFWMD Logic: ", addLocationForm.sfwmd_logic);
    console.log("Watering Algo: ", addLocationForm.watering_algo || "");
    console.log(
      "Watering RainThreshold: ",
      addLocationForm.watering_rain_threshold
    );
    console.log("contact 1 name: ", addLocationForm.contact1_name);
    console.log("contact 1 email: ", addLocationForm.contact1_email);
    console.log("contact 1 phone: ", addLocationForm.contact1_phone);
    console.log("contact 2 name: ", addLocationForm.contact2_name);
    console.log("contact 2 email: ", addLocationForm.contact2_email);
    console.log("contact 2 phone: ", addLocationForm.contact2_phone);
    swal
      .fire({
        title: "Do you want to add this account location?",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#dc3545",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiLink.post("/api/add-company-location", {
              email: email,
              password: password,
              company_id: selectedCompanyName.company_id,
              name: addLocationForm.location_name,
              type: addLocationForm.type,
              water_type: addLocationForm.water_type,
              geo_lat: latitude,
              geo_long: longitude,
              address_line1: addLocationForm.address_line1,
              address_line2: addLocationForm.address_line2,
              city: addLocationForm.city,
              state: addLocationForm.state,
              zip: addLocationForm.zip,
              sfwmd_account_number: addLocationForm.sfwmd1,
              sfwmd_account_number_2: addLocationForm.sfwmd2,
              sfwmd_account_number_3: addLocationForm.sfwmd3,
              sfwmd_account_number_4: addLocationForm.sfwmd4,
              sfwmd_account_logic: addLocationForm.sfwmd_logic,
              watering_algo: addLocationForm.watering_algo,
              watering_rain_threshold: addLocationForm.watering_rain_threshold,
              contact1_name: addLocationForm.contact1_name || "",
              contact1_email: addLocationForm.contact1_email || "",
              contact1_phone: addLocationForm.contact1_phone || "",
              contact2_name: addLocationForm.contact2_name || "",
              contact2_email: addLocationForm.contact2_email || "",
              contact2_phone: addLocationForm.contact2_phone || "",
            });

            if (response.status === 200) {
              if (response.data == "error") {
                swal.fire({
                  title: `${response.data.message}`,
                  icon: "warning",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
              }
              if (response.data.Data.is_valid == false) {
                swal.fire({
                  title: `There's something wrong in adding account location`,
                  icon: "warning",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
              } else if (response.data.Data.is_valid == true) {
                const locationId = response.data.Data.primary_key;
                try {
                  const zohoResponse = await ApiLink.post(
                    "/api/zoho-crm-add-location",
                    {
                      name: addLocationForm.location_name,
                      type: addLocationForm.type,
                      water_type: addLocationForm.water_type,
                      geo_lat: latitude,
                      geo_long: longitude,
                      address_line1: addLocationForm.address_line1,
                      address_line2: addLocationForm.address_line2 || "",
                      city: addLocationForm.city,
                      state: addLocationForm.state,
                      zip: addLocationForm.zip,
                      sfwmd_account_number: addLocationForm.sfwmd1,
                      sfwmd_account_number_2: addLocationForm.sfwmd2,
                      sfwmd_account_number_3: addLocationForm.sfwmd3,
                      sfwmd_account_number_4: addLocationForm.sfwmd4,
                      sfwmd_account_logic: addLocationForm.sfwmd_logic,
                      watering_algo: addLocationForm.watering_algo,
                      watering_rain_threshold:
                        addLocationForm.watering_rain_threshold,
                      contact1_name: addLocationForm.contact1_name || "",
                      contact1_email: addLocationForm.contact1_email || "",
                      contact1_phone: addLocationForm.contact1_phone || "",
                      contact2_name: addLocationForm.contact2_name || "",
                      contact2_email: addLocationForm.contact2_email || "",
                      contact2_phone: addLocationForm.contact2_phone || "",
                      company_id: selectedCompanyName.company_id.toString(),
                      location_id: locationId,
                    }
                  );

                  if (zohoResponse.data[0].code == "SUCCESS") {
                    swal.fire({
                      title: "Account Location Added Successfully",
                      icon: "success",
                      toast: true,
                      timer: 4000,
                      position: "top-right",
                      timerProgressBar: true,
                      showConfirmButton: false,
                    });
                    clearAddLocationForm();
                    setAddLocation(false);

                    fetchCompanyList();
                  }
                } catch (error) {
                  swal.fire({
                    title: `Something went wrong`,
                    icon: "error",
                    toast: true,
                    timer: 4000,
                    position: "top-right",
                    timerProgressBar: true,
                    showConfirmButton: false,
                  });
                }
              }
            }
          } catch (error) {
            swal.fire({
              title: `Something went wrong`,
              icon: "error",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        }
      });
  };

  const clearAddLocationForm = () => {
    setAddLocationForm({
      type: "",
      water_type: "",
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      zip: "",
      sfwmd1: "",
      sfwmd2: "",
      sfwmd3: "",
      sfwmd4: "",
      sfwmd_logic: "",
      watering_algo: "",
      watering_rain_threshold: "",
      contact1_name: "",
      contact1_email: "",
      contact1_phone: "",
      contact2_name: "",
      contact2_email: "",
      contact2_phone: "",
    });
  };

  const handleEditLocationSubmit = (e) => {
    e.preventDefault();
    console.log("Location Name: ", selectedLocationEdit.locationName);
    console.log(
      "Location Address Line 1: ",
      selectedLocationEdit.locationAddressLine1
    );
    console.log("Company Type: ", selectedLocationEdit.locationType);
    console.log(
      "Location Address Line2: ",
      selectedLocationEdit.locationAddressLine2
    );
    console.log("Water Type: ", selectedLocationEdit.locationWaterType);
    console.log("GeoLoc Longitude: ", selectedLocationEdit.locationGeoLong);
    console.log("Geoloc Latitude: ", selectedLocationEdit.locationGeoLat);
    console.log("City: ", selectedLocationEdit.locationCity);
    console.log("State: ", selectedLocationEdit.locationState);
    console.log("Zip: ", selectedLocationEdit.locationZip);
    console.log("SFWMD_1: ", selectedLocationEdit.locationSFWMD1);
    console.log("SFWMD_2: ", selectedLocationEdit.locationSFWMD2);
    console.log("SFWMD_3: ", selectedLocationEdit.locationSFWMD3);
    console.log("SFWMD_4: ", selectedLocationEdit.locationSFWMD4);
    console.log("SFWMD_Grind Logic: ", selectedLocationEdit.locationSFWMDLogic);
    console.log("Watering Algo: ", selectedLocationEdit.locationWateringAlgo);
    console.log(
      "Watering RainThreshold: ",
      selectedLocationEdit.locationWateringRainThreshold
    );
    console.log("Contact 1 Name: ", selectedLocationEdit.locationContactName1);
    console.log(
      "Contact 1 Email: ",
      selectedLocationEdit.locationContactEmail1
    );
    console.log(
      "Contact 1 Phone: ",
      selectedLocationEdit.locationContactPhone1
    );
    console.log("Contact 2 Name: ", selectedLocationEdit.locationContactName2);
    console.log(
      "Contact 2 Email: ",
      selectedLocationEdit.locationContactEmail2
    );
    console.log(
      "Contact 2 Phone: ",
      selectedLocationEdit.locationContactPhone2
    );
    console.log(
      "company_location_id: ",
      selectedLocationEdit.company_location_id
    );

    const formattedThreshold = formatThreshold(
      selectedLocationEdit.locationWateringRainThreshold
    );

    swal
      .fire({
        title: "Do you want to update this account location?",
        showCancelButton: true,
        confirmButtonText: "Update",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#FF0000",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiLink.post(
              "/api/update-company-location",
              {
                email: email,
                password: password,
                company_id: selectedLocationEdit.company_id,
                recordid: selectedLocationEdit.company_location_id,
                name: selectedLocationEdit.locationName,
                type: selectedLocationEdit.locationType,
                water_type: selectedLocationEdit.locationWaterType,
                geo_lat: selectedLocationEdit.locationGeoLat,
                geo_long: selectedLocationEdit.locationGeoLong,
                address_line1: selectedLocationEdit.locationAddressLine1,
                address_line2: selectedLocationEdit.locationAddressLine2,
                city: selectedLocationEdit.locationCity,
                state: selectedLocationEdit.locationState,
                zip: selectedLocationEdit.locationZip,
                sfwmd_account_number:
                  selectedLocationEdit.locationSFWMD1 || null,
                sfwmd_account_number_2:
                  selectedLocationEdit.locationSFWMD2 || null,
                sfwmd_account_number_3:
                  selectedLocationEdit.locationSFWMD3 || null,
                sfwmd_account_number_4:
                  selectedLocationEdit.locationSFWMD4 || null,
                sfwmd_account_logic: selectedLocationEdit.locationSFWMDLogic,
                watering_algo: selectedLocationEdit.locationWateringAlgo,
                watering_rain_threshold: formattedThreshold,
                contact1_name:
                  selectedLocationEdit.locationContactName1 || null,
                contact1_email:
                  selectedLocationEdit.locationContactEmail1 || null,
                contact1_phone:
                  selectedLocationEdit.locationContactPhone1 || "",
                contact2_name:
                  selectedLocationEdit.locationContactName2 || null,
                contact2_email:
                  selectedLocationEdit.locationContactEmail2 || null,
                contact2_phone:
                  selectedLocationEdit.locationContactPhone2 || "",
              }
            );
            if (response.status === 200) {
              if (response.data.Data.is_valid == true) {
                try {
                  const zohoResponse = await ApiLink.post(
                    "/api/zoho-crm-update-location",
                    {
                      name: selectedLocationEdit.locationName,
                      type: selectedLocationEdit.locationType,
                      water_type: selectedLocationEdit.locationWaterType,
                      geo_lat: selectedLocationEdit.locationGeoLat,
                      geo_long: selectedLocationEdit.locationGeoLong,
                      address_line1: selectedLocationEdit.locationAddressLine1,
                      address_line2: selectedLocationEdit.locationAddressLine2,
                      city: selectedLocationEdit.locationCity,
                      state: selectedLocationEdit.locationState,
                      zip: selectedLocationEdit.locationZip,
                      sfwmd_account_number:
                        selectedLocationEdit.locationSFWMD1 || null,
                      sfwmd_account_number_2:
                        selectedLocationEdit.locationSFWMD2 || null,
                      sfwmd_account_number_3:
                        selectedLocationEdit.locationSFWMD3 || null,
                      sfwmd_account_number_4:
                        selectedLocationEdit.locationSFWMD4 || null,
                      sfwmd_account_logic:
                        selectedLocationEdit.locationSFWMDLogic,
                      watering_algo: selectedLocationEdit.locationWateringAlgo,
                      watering_rain_threshold: formattedThreshold,
                      contact1_name:
                        selectedLocationEdit.locationContactName1 || null,
                      contact1_email:
                        selectedLocationEdit.locationContactEmail1 || null,
                      contact1_phone:
                        selectedLocationEdit.locationContactPhone1 || "",
                      contact2_name:
                        selectedLocationEdit.locationContactName2 || null,
                      contact2_email:
                        selectedLocationEdit.locationContactEmail2 || null,
                      contact2_phone:
                        selectedLocationEdit.locationContactPhone2 || "",
                      recordid: selectedLocationEdit.company_location_id,
                    }
                  );

                  if (zohoResponse.data[0].code == "SUCCESS") {
                    swal.fire({
                      title: "Account Location Updated Successfully",
                      icon: "success",
                      toast: true,
                      timer: 4000,
                      position: "top-right",
                      timerProgressBar: true,
                      showConfirmButton: false,
                    });
                    clearEditLocation();
                    fetchCompanyList();
                    toggleCloseEditLocation();
                  }
                } catch (error) {}
              } else if (response.data.Data.is_valid == false) {
                // Extract error messages from the object
                const errorMessages = Object.entries(response.data.Data.errors)
                  .map(([field, messages]) => `${messages.join(", ")}`)
                  .join("\n");

                swal.fire({
                  title: errorMessages,

                  icon: "error",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
              }
            }
          } catch (error) {
            swal.fire({
              title: "There is something wrong in updating the company account",
              icon: "error",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        }
      });
  };

  const handleEditClick = (
    locationName,
    locationAddressLine1,
    locationType,
    locationAddressLine2,
    locationWaterType,
    locationCity,
    locationGeoLat,
    locationGeoLong,
    locationState,
    locationZip,
    locationSFWMD1,
    locationSFWMD2,
    locationSFWMD3,
    locationSFWMD4,
    locationSFWMDLogic,
    locationWateringAlgo,
    locationWateringInterval,
    locationWateringRainThreshold,
    locationForecast,
    locationContactName1,
    locationContactName2,
    locationContactEmail1,
    locationContactEmail2,
    locationContactPhone1,
    locationContactPhone2,
    company_location_id,
    company_id
  ) => {
    setSelectedLocationEdit({
      locationName,
      locationAddressLine1,
      locationType,
      locationAddressLine2,
      locationWaterType,
      locationCity,
      locationGeoLat,
      locationGeoLong,
      locationState,
      locationZip,
      locationSFWMD1,
      locationSFWMD2,
      locationSFWMD3,
      locationSFWMD4,
      locationSFWMDLogic,
      locationWateringAlgo,
      locationWateringInterval,
      locationWateringRainThreshold,
      locationForecast,
      locationContactName1,
      locationContactName2,
      locationContactEmail1,
      locationContactEmail2,
      locationContactPhone1,
      locationContactPhone2,
      company_location_id,
      company_id,
    });
    toggleEditLocation();
  };

  const toggleBodyScroll = (disable) => {
    if (disable) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    toggleBodyScroll(addLocation || editLocation);
    return () => toggleBodyScroll(false);
  }, [addLocation, editLocation]);

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/\D/g, "");

    if (phoneNumber.length <= 3) {
      return phoneNumber;
    } else if (phoneNumber.length <= 6) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    } else {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    }
  };

  const deleteCompanyLocation = (company_location_id, name) => {
    swal
      .fire({
        title: "Do you want to delete this account location?",
        text: `"${name} " will be deleted permanently.`,
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#FF0000",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiLink.post(
              "/api/delete-company-location",
              {
                email: email,
                password: password,
                company_location_id: company_location_id,
              }
            );

            if (response.status === 200) {
              console.log(response.data);
              if (response.data.Data.is_valid == true) {
                swal.fire({
                  title: "Account Location Deleted Successfully",

                  icon: "success",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
                fetchCompanyList();
              } else if (response.data.Data.is_valid == false) {
                // Extract error messages from the object
                const errorMessages = Object.entries(response.data.Data.errors)
                  .map(([field, messages]) => `${messages.join(", ")}`)
                  .join("\n");

                swal.fire({
                  title: errorMessages,

                  icon: "error",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
              }
            }
          } catch (error) {
            swal.fire({
              title: "Account Location Deleted Successfully",
              icon: "error",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        }
      });
  };

  const restoreCompanyLocation = (company_location_id, name) => {
    swal
      .fire({
        title: "Do you want to restore this user?",
        text: `Account Location "${name} " will be restored.`,
        showCancelButton: true,
        confirmButtonText: "Restore",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#FF0000",
      })

      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiLink.post(
              "/api/restore-company-location",
              {
                email: email,
                password: password,
                company_location_id: company_location_id,
              }
            );

            if (response.status === 200) {
              if (response.data.Data.success == true) {
                swal.fire({
                  title: "Account Location Restored Successfully",
                  icon: "success",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
                fetchCompanyList();
              } else if (response.data.Data.success == false) {
                swal.fire({
                  title: "Error Occured. Please try again later.",
                  icon: "warning",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
              } else {
                swal.fire({
                  title: "Unknown Error Occured. Please try again later.",
                  icon: "error",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
              }
            }
          } catch (error) {}
        }
      });
  };

  const formatThreshold = (value) => {
    // Convert the value to a number and then to a string with 3 decimal places
    return Number(value).toFixed(3);
  };

  const options = Object.entries(sfwmdLogRawData).map(([key, value]) => ({
    value: value,
    label: value,
  }));

  const handleChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    setAddLocationForm((prevForm) => ({
      ...prevForm,
      [name]: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleChangeEdit = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    setSelectedLocationEdit((prevForm) => ({
      ...prevForm,
      [name]: selectedOption ? selectedOption.value : "",
    }));
  };

  return (
    <div
      className={`min-h-screen bg-gray-100 dark:bg-gray-700 transition-all duration-300 p-4 mt-20`}
      style={{ marginLeft: sideBarOpen ? "16rem" : "0" }}
    >
      <div className="p-4 bg-white shadow-md rounded-md dark:bg-gray-800">
        {isLoading ? (
          <div role="status" className="flex py-2 justify-center items-center">
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <div>
            <div className="flex justify-between mb-1">
              <div className="p-1 text-xl font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                <h1>Sustain Sprinklers Account Locations</h1>
              </div>
              {userType !== 2 && is_read_only !== 1 && (
                <button
                  className={`px-3 py-1.5 text-sm rounded-md font-semibold `}
                  style={{
                    backgroundColor: `${backgroundColor}`,
                    color: `${textColor}`,
                  }}
                  onClick={toggleAddLocation}
                >
                  + Add Location
                </button>
              )}
            </div>
            {userType !== 2 && (
              <div className="flex flex-col py-2 w-36 ml-2">
                <label
                  htmlFor="status"
                  className="block mb-0 text-sm font-semibold text-gray-900 dark:text-white"
                >
                  Status
                </label>
                <select
                  id="status"
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="1">Enabled</option>
                  <option value="2">Pending</option>
                  <option value="3">Deleted</option>
                </select>
              </div>
            )}

            <div className="mt-2 overflow-x-auto">
              <table className="animate__animated animate__fadeIn text-black dark:text-white w-full text-sm text-left rtl:text-right ">
                <thead
                  className={`text-sm uppercase leading-4`}
                  style={{
                    backgroundColor: `${tableHeaderColor}`,
                    color: `${tableHeaderTextColor}`,
                  }}
                >
                  <tr>
                    <th scope="col" className="px-6 py-3 border border-black">
                      Location Name
                    </th>
                    <th scope="col" className="px-6 py-3 border border-black">
                      Location Type
                    </th>
                    <th scope="col" className="px-6 py-3 border border-black">
                      Address
                    </th>
                    <th scope="col" className="px-6 py-3 border border-black">
                      Water Type
                    </th>
                    <th scope="col" className="px-6 py-3 border border-black">
                      Date Added
                    </th>
                    <th scope="col" className="px-6 py-3 border border-black">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3 border border-black">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm leading-4">
                  {companyData.length > 0 ? (
                    companyData.map((location) => (
                      <tr
                        key={location.company_location_id}
                        className="bg-white dark:bg-gray-800 border-b dark:border-gray-700 "
                      >
                        <td className="border border-black dark:border-white px-6 py-4 hover:underline">
                          <Link
                            to={`/location/${selectedCompanyName.companyName.replace(
                              /\s+/g,
                              "-"
                            )}`}
                          >
                            <div
                              onClick={() =>
                                handleLocationClick(
                                  location.company_location_id,
                                  location.name,

                                  location.company_id,
                                  location.city,
                                  location.state,
                                  location.zip,
                                  location.address_line1
                                )
                              }
                            >
                              {location.name}
                            </div>
                          </Link>
                        </td>
                        <td className="border border-black dark:border-white px-6 py-4">
                          {COMPANY_TYPES[location.type]}
                        </td>
                        <td className="border border-black dark:border-white px-6 py-4">
                          {location.city} {location.state} {location.zip}{" "}
                        </td>
                        <td className="border border-black dark:border-white px-6 py-4">
                          {WATER_TYPES[location.water_type]}
                        </td>
                        <td className="border border-black dark:border-white px-6 py-4">
                          {location.date_added}
                        </td>
                        <td
                          className={`border border-black dark:border-gray-300 px-6 py-4 text-xs flex-col font-semibold text-white leading-4 text-center`}
                        >
                          <div
                            className={`status p-2 rounded-md `}
                            style={
                              location.status === 1
                                ? { backgroundColor: "#74D348" }
                                : location.status === 3
                                ? { backgroundColor: "red" }
                                : location.status === 2
                                ? { backgroundColor: "#A4CAFE" }
                                : {}
                            }
                          >
                            {location.status === 1
                              ? "Active"
                              : location.status === 2
                              ? "Pending"
                              : location.status === 3
                              ? "Deleted"
                              : ""}
                          </div>
                        </td>

                        <td className="border border-black dark:border-white px-6 py-4">
                          <div className="flex gap-3 items-center">
                            {userType !== 2 && is_read_only !== 1 && (
                              <div className="edit p-1 hover:bg-gray-200 rounded-full">
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleEditClick(
                                      location.name,
                                      location.address_line1,
                                      location.type,
                                      location.address_line2,
                                      location.water_type,
                                      location.city,
                                      location.geo_lat,
                                      location.geo_long,
                                      location.state,
                                      location.zip,
                                      location.sfwmd_account_number,
                                      location.sfwmd_account_number_2,
                                      location.sfwmd_account_number_3,
                                      location.sfwmd_account_number_4,
                                      location.sfwmd_account_logic,
                                      location.watering_algo,
                                      location.watering_interval,
                                      location.watering_rain_threshold,
                                      location.forecast_datetime,
                                      location.contact1_name,
                                      location.contact2_name,
                                      location.contact1_email,
                                      location.contact2_email,
                                      location.contact1_phone,
                                      location.contact2_phone,
                                      location.company_location_id,
                                      location.company_id
                                    );
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="blue"
                                    className="size-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                    />
                                  </svg>
                                </button>
                              </div>
                            )}
                            {userType !== 2 &&
                              is_read_only !== 1 &&
                              selectedStatus !== "3" && (
                                <div
                                  className="delete p-1 hover:bg-gray-200 rounded-full"
                                  onClick={() =>
                                    deleteCompanyLocation(
                                      location.company_location_id,
                                      location.name
                                    )
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="red"
                                    className="size-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                    />
                                  </svg>
                                </div>
                              )}
                            {selectedStatus === "3" && (
                              <div
                                className="restore p-1 hover:bg-gray-200 rounded-full"
                                onClick={() =>
                                  restoreCompanyLocation(
                                    location.company_location_id,
                                    location.name
                                  )
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="size-6"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                                  />
                                </svg>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center py-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="w-8 h-8 mx-auto text-gray-500 dark:text-gray-400"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <line x1="12" y1="8" x2="12" y2="12"></line>
                          <line x1="12" y1="16" x2="12.01" y2="16"></line>
                        </svg>

                        <p className="animate__animated animate__fadeIn mt-2 text-sm text-gray-500 dark:text-gray-400">
                          No Location Available
                        </p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      {addLocation && (
        <div
          id="defaultModal"
          tabindex="-1"
          aria-hidden="true"
          className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50 font-inter"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          {/* <!-- Add company location modal --> */}
          <div className="relative p-4 w-full max-w-fit h-full md:h-auto my-8">
            {/* <!-- Modal content --> */}
            <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 overflow-y-auto max-h-[90vh]">
              {/* <!-- Modal header --> */}
              <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Add Sustain Sprinkler Account Location
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal"
                  onClick={toggleAddLocation}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <form onSubmit={handleAddLocationSubmit}>
                <div className="grid gap-4 mb-4 sm:grid-cols-5 overflow-y-auto pb-3 border-b dark:border-gray-700">
                  <div>
                    <label
                      for="location_name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Location Name
                    </label>
                    <input
                      type="text"
                      name="location_name"
                      id="location_name"
                      required
                      value={addLocationForm.location_name || ""} // Default to empty string
                      onChange={(e) =>
                        setAddLocationForm((prev) => ({
                          ...prev,
                          location_name: e.target.value,
                        }))
                      }
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>

                  <div>
                    <label
                      for="countries"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Select Company Type
                    </label>
                    <select
                      id="companyType"
                      value={addLocationForm.type}
                      onChange={(e) =>
                        setAddLocationForm((prev) => ({
                          ...prev,
                          type: e.target.value,
                        }))
                      }
                      required
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="">Choose a country</option>
                      <option value="1">City</option>
                      <option value="2">Commercial Building</option>
                      <option value="3">Community</option>
                      <option value="4">Construction</option>
                      <option value="5">County</option>
                      <option value="6">Golf Course</option>
                      <option value="7">Park</option>
                      <option value="8">Landscape Company</option>
                      <option value="9">Homeowner</option>
                      <option value="0">INFO_NOT_AVAILABLE</option>
                    </select>
                  </div>
                  <div>
                    <label
                      for="address_line1"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Address
                    </label>
                    <input
                      type="text"
                      name="address_line1"
                      id="address_line1"
                      required
                      value={addLocationForm.address_line1}
                      onChange={(e) =>
                        setAddLocationForm((prev) => ({
                          ...prev,
                          address_line1: e.target.value,
                        }))
                      }
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>

                  <div>
                    <label
                      for="address2"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Address 2
                    </label>
                    <input
                      type="text"
                      name="address2"
                      id="address2"
                      value={addLocationForm.address_line2}
                      onChange={(e) =>
                        setAddLocationForm((prev) => ({
                          ...prev,
                          address_line2: e.target.value,
                        }))
                      }
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="phone"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Select Water Type
                    </label>
                    <select
                      id="countries"
                      value={addLocationForm.water_type}
                      onChange={(e) =>
                        setAddLocationForm((prev) => ({
                          ...prev,
                          water_type: e.target.value,
                        }))
                      }
                      required
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="" disabled>
                        Select Water Type
                      </option>
                      <option value="1">City</option>
                      <option value="2">Lake/Well Water</option>
                      <option value="3">Reclaimed Water</option>
                      <option value="4">INFO_NOT_AVAILABLE</option>
                    </select>
                  </div>

                  <div>
                    <label
                      for="geoLocation-longitude"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Geo Location - Longitude
                    </label>
                    <input
                      type="text"
                      name="geoLocation-longitude"
                      id="geoLocation-longitude"
                      value={longitude}
                      onChange={(e) => setLongitude(e.target.value)}
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="geoLocation-latitude"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Geo Location - Latitude
                    </label>
                    <input
                      type="text"
                      name="geoLocation-latitude"
                      id="geoLocation-latitude"
                      value={latitude}
                      onChange={(e) => setLatitude(e.target.value)}
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="city"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      City
                    </label>
                    <input
                      type="text"
                      value={addLocationForm.city}
                      onChange={(e) =>
                        setAddLocationForm((prev) => ({
                          ...prev,
                          city: e.target.value,
                        }))
                      }
                      name="city"
                      required
                      id="city"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="state"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      State
                    </label>
                    <select
                      id="state"
                      value={addLocationForm.state}
                      onChange={(e) =>
                        setAddLocationForm((prev) => ({
                          ...prev,
                          state: e.target.value,
                        }))
                      }
                      required
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="" disabled>
                        Select a State
                      </option>
                      {states.map((state) => (
                        <option
                          key={state.abbreviation}
                          value={state.abbreviation}
                        >
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label
                      for="zip"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Zip Code
                    </label>
                    <input
                      type="number"
                      name="zip"
                      value={addLocationForm.zip}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length <= 5) {
                          setAddLocationForm((prev) => ({
                            ...prev,
                            zip: value,
                          }));
                        }
                      }}
                      required
                      max={99999}
                      id="zip"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                </div>
                <div className="grid gap-4 mb-4 sm:grid-cols-6 overflow-y-auto pb-3 border-b dark:border-gray-700">
                  <button
                    className=" font-bold py-2 px-4 rounded-md"
                    style={{
                      backgroundColor: `${backgroundColor}`,
                      color: `${textColor}`,
                    }}
                    onClick={() => handleGetLocation()}
                  >
                    Get Location
                  </button>
                </div>
                <div className="grid gap-4 sm:grid-cols-3 border-b dark:border-gray-700 pb-3">
                  <div>
                    <label
                      for="sfwmdGrid-1"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      SFWMD Grid #1
                    </label>
                    <Select
                      id="sfwmdGrid-1"
                      name="sfwmd1"
                      options={options}
                      value={
                        options.find(
                          (option) => option.value === addLocationForm.sfwmd1
                        ) || null
                      }
                      onChange={handleChange}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      placeholder="Select SFWMD Grid #1"
                    />
                  </div>
                  <div>
                    <label
                      for="sfwmdGrid-2"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      SFWMD Grid #2
                    </label>
                    <Select
                      id="sfwmdGrid-2"
                      name="sfwmd2"
                      options={options}
                      value={
                        options.find(
                          (option) => option.value === addLocationForm.sfwmd2
                        ) || null
                      }
                      onChange={handleChange}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      placeholder="Select SFWMD Grid #2"
                    />
                  </div>
                  <div>
                    <label
                      for="sfwmdGrid-3"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      SFWMD Grid #3
                    </label>
                    <Select
                      id="sfwmdGrid-3"
                      name="sfwmd3"
                      options={options}
                      value={
                        options.find(
                          (option) => option.value === addLocationForm.sfwmd3
                        ) || null
                      }
                      onChange={handleChange}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      placeholder="Select SFWMD Grid #3"
                    />
                  </div>
                  <div>
                    <label
                      for="sfwmdGrid-4"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      SFWMD Grid #4
                    </label>
                    <Select
                      id="sfwmdGrid-4"
                      name="sfwmd4"
                      options={options}
                      value={
                        options.find(
                          (option) => option.value === addLocationForm.sfwmd4
                        ) || null
                      }
                      onChange={handleChange}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      placeholder="Select SFWMD Grid #4"
                    />
                  </div>
                  <div>
                    <label
                      for="sfwmdGridLogic"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      SFWMD Grid Logic
                    </label>
                    <select
                      id="sfwmdGridLogic"
                      value={addLocationForm.sfwmd_logic}
                      onChange={(e) =>
                        setAddLocationForm((prev) => ({
                          ...prev,
                          sfwmd_logic: e.target.value,
                        }))
                      }
                      required
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="" disabled></option>
                      <option value="AND">AND</option>
                      <option value="OR">OR</option>
                    </select>
                  </div>
                  <div>
                    <label
                      for="wateringAlgo"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Watering Algo
                    </label>
                    <select
                      id="wateringAlgo"
                      value={addLocationForm.watering_algo}
                      onChange={(e) =>
                        setAddLocationForm((prev) => ({
                          ...prev,
                          watering_algo: e.target.value,
                        }))
                      }
                      required
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="" disabled></option>
                      <option value="formula">
                        Formula (using SFWMD Data)
                      </option>
                      <option value="forecast">Weathering Forecast</option>
                      <option value="formula_and_forecast">
                        Forecast and Formula
                      </option>
                    </select>
                  </div>
                  <div>
                    <label
                      for="wateringRainThreshold"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Watering Rain Threshold
                    </label>
                    <select
                      id="wateringAlgo"
                      value={addLocationForm.watering_rain_threshold}
                      onChange={(e) =>
                        setAddLocationForm((prev) => ({
                          ...prev,
                          watering_rain_threshold: e.target.value,
                        }))
                      }
                      required
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="" disabled></option>
                      <option value="0.079">0.079</option>
                      <option value="0.125">0.125"</option>
                      <option value="0.150">0.15"</option>
                      <option value="0.175">0.175"(default)</option>
                      <option value="0.200">0.20"</option>
                      <option value="0.250">0.25"</option>
                      <option value="0.300">0.3"</option>
                      <option value="0.400">0.4"</option>
                      <option value="0.500">0.5"</option>
                      <option value="0.750">0.75"</option>
                      <option value="1">1"</option>
                    </select>
                  </div>
                </div>
                <div className="pt-2 grid gap-4 mb-4 sm:grid-cols-3">
                  <div>
                    <label
                      for="contact1Name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Contact 1 Name
                    </label>
                    <input
                      type="text"
                      value={addLocationForm.contact1_name}
                      onChange={(e) =>
                        setAddLocationForm((prev) => ({
                          ...prev,
                          contact1_name: e.target.value,
                        }))
                      }
                      name="contact1Name"
                      id="contact1Name"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="contact1Email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Contact 1 Email
                    </label>
                    <input
                      type="email"
                      value={addLocationForm.contact1_email}
                      onChange={(e) =>
                        setAddLocationForm((prev) => ({
                          ...prev,
                          contact1_email: e.target.value,
                        }))
                      }
                      name="contact1Email"
                      id="contact1Email"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="contact1Phone"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Contact 1 Phone
                    </label>
                    <input
                      type="text"
                      name="contact1Phone"
                      value={addLocationForm.contact1_phone}
                      onChange={(e) => {
                        const formattedValue = formatPhoneNumber(
                          e.target.value
                        );
                        setAddLocationForm((prev) => ({
                          ...prev,
                          contact1_phone: formattedValue,
                        }));
                      }}
                      id="contact1Phone"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="contact2Name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Contact 2 Name
                    </label>
                    <input
                      type="text"
                      value={addLocationForm.contact2_name}
                      onChange={(e) =>
                        setAddLocationForm((prev) => ({
                          ...prev,
                          contact2_name: e.target.value,
                        }))
                      }
                      name="contact2Name"
                      id="contact2Name"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="contact2Email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Contact 2 Email
                    </label>
                    <input
                      type="text"
                      value={addLocationForm.contact2_email}
                      onChange={(e) =>
                        setAddLocationForm((prev) => ({
                          ...prev,
                          contact2_email: e.target.value,
                        }))
                      }
                      name="contact2Email"
                      id="contact2Email"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="contact2Phone"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Contact 2 Phone
                    </label>
                    <input
                      type="text"
                      value={addLocationForm.contact2_phone}
                      onChange={(e) => {
                        const formattedValue = formatPhoneNumber(
                          e.target.value
                        );
                        setAddLocationForm((prev) => ({
                          ...prev,
                          contact2_phone: formattedValue,
                        }));
                      }}
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      name="contact2Phone"
                      id="contact2Phone"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                </div>

                <div className="mt-2 flex items-center justify-end">
                  <button
                    className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                    style={{ backgroundColor: "red" }}
                    onClick={toggleAddLocation}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center"
                    style={{ backgroundColor: "#009D01" }}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {editLocation && (
        <div
          id="defaultModal"
          tabindex="-1"
          aria-hidden="true"
          className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50 font-inter"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          {/* <!-- Edit company location modal --> */}
          <div className="relative p-4 w-full max-w-fit h-full md:h-auto my-8">
            {/* <!-- Modal content --> */}
            <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 overflow-y-auto max-h-[90vh]">
              {/* <!-- Modal header --> */}
              <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Edit Sustain Sprinkler Account Location
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal"
                  onClick={toggleCloseEditLocation}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <form onSubmit={handleEditLocationSubmit}>
                <div className="grid gap-4 mb-4 sm:grid-cols-5 overflow-y-auto pb-3 border-b dark:border-gray-700">
                  <div>
                    <label
                      for="locationName"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Location Name
                    </label>
                    <input
                      type="text"
                      name="locationName"
                      id="locationName"
                      value={selectedLocationEdit.locationName}
                      onChange={(e) =>
                        setSelectedLocationEdit((prev) => ({
                          ...prev,
                          locationName: e.target.value,
                        }))
                      }
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="brand"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Address
                    </label>
                    <input
                      type="text"
                      name="status"
                      id="status"
                      value={selectedLocationEdit.locationAddressLine1}
                      onChange={(e) =>
                        setSelectedLocationEdit((prev) => ({
                          ...prev,
                          locationAddressLine1: e.target.value,
                        }))
                      }
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="countries"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Select Company Type
                    </label>
                    <select
                      id="companyType"
                      value={selectedLocationEdit.locationType}
                      onChange={(e) =>
                        setSelectedLocationEdit((prev) => ({
                          ...prev,
                          locationType: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option selected>Choose a country</option>
                      <option value="1">City</option>
                      <option value="2">Commercial Building</option>
                      <option value="3">Community</option>
                      <option value="4">Construction</option>
                      <option value="5">County</option>
                      <option value="6">Golf Course</option>
                      <option value="7">Park</option>
                      <option value="8">Landscape Company</option>
                      <option value="9">Homeowner</option>
                      <option value="0">INFO_NOT_AVAILABLE</option>
                    </select>
                  </div>

                  <div>
                    <label
                      for="address2"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Address 2
                    </label>
                    <input
                      type="text"
                      name="address2"
                      id="address2"
                      value={selectedLocationEdit.locationAddressLine2}
                      onChange={(e) =>
                        setSelectedLocationEdit((prev) => ({
                          ...prev,
                          locationAddressLine2: e.target.value,
                        }))
                      }
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="phone"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Select Water Type
                    </label>
                    <select
                      id="waterType"
                      value={selectedLocationEdit.locationWaterType}
                      onChange={(e) =>
                        setSelectedLocationEdit((prev) => ({
                          ...prev,
                          locationWaterType: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option selected>Select Water Type</option>
                      <option value="1">City</option>
                      <option value="2">Lake/Well Water</option>
                      <option value="3">Reclaimed Water</option>
                      <option value="4">INFO_NOT_AVAILABLE</option>
                    </select>
                  </div>

                  <div>
                    <label
                      for="geoLocation-longitude"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Geo Location - Longitude
                    </label>
                    <input
                      type="text"
                      name="geoLocation-longitude"
                      id="geoLocation-longitude"
                      value={selectedLocationEdit.locationGeoLong}
                      onChange={(e) =>
                        setSelectedLocationEdit((prev) => ({
                          ...prev,
                          locationGeoLong: e.target.value,
                        }))
                      }
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="geoLocation-latitude"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Geo Location - Latitude
                    </label>
                    <input
                      type="text"
                      name="geoLocation-latitude"
                      id="geoLocation-latitude"
                      value={selectedLocationEdit.locationGeoLat}
                      onChange={(e) =>
                        setSelectedLocationEdit((prev) => ({
                          ...prev,
                          locationGeoLat: e.target.value,
                        }))
                      }
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="city"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      City
                    </label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      value={selectedLocationEdit.locationCity}
                      onChange={(e) =>
                        setSelectedLocationEdit((prev) => ({
                          ...prev,
                          locationCity: e.target.value,
                        }))
                      }
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="state"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      State
                    </label>
                    <select
                      id="state"
                      value={selectedLocationEdit.locationState}
                      onChange={(e) =>
                        setSelectedLocationEdit((prev) => ({
                          ...prev,
                          locationState: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option>Select a State</option>

                      {states.map((state) => (
                        <option value={state.abbreviation}>{state.name}</option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label
                      for="zip"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Zip Code
                    </label>
                    <input
                      type="number"
                      name="zip"
                      id="zip"
                      max={99999}
                      value={selectedLocationEdit.locationZip}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length <= 5) {
                          setSelectedLocationEdit((prev) => ({
                            ...prev,
                            locationZip: value,
                          }));
                        }
                      }}
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                </div>
                <div className="grid gap-4 mb-4 sm:grid-cols-6 overflow-y-auto pb-3 border-b dark:border-gray-700">
                  <button
                    className=" font-bold py-2 px-4 rounded-md"
                    style={{
                      backgroundColor: `${backgroundColor}`,
                      color: `${textColor}`,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleEditGetLocation();
                    }}
                  >
                    Get Location
                  </button>
                </div>

                <div className="grid gap-4 sm:grid-cols-3 border-b dark:border-gray-700 pb-3">
                  <div>
                    <label
                      for="sfwmdGrid-1"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      SFWMD Grid #1
                    </label>

                    <Select
                      id="sfwmdGrid-1"
                      name="locationSFWMD1"
                      options={options}
                      value={
                        options.find(
                          (option) =>
                            option.value === selectedLocationEdit.locationSFWMD1
                        ) || null
                      }
                      onChange={handleChangeEdit}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      placeholder="Select SFWMD Grid #1"
                    />
                  </div>
                  <div>
                    <label
                      for="sfwmdGrid-2"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      SFWMD Grid #2
                    </label>
                    <Select
                      id="sfwmdGrid-2"
                      name="locationSFWMD2"
                      options={options}
                      value={
                        options.find(
                          (option) =>
                            option.value === selectedLocationEdit.locationSFWMD2
                        ) || null
                      }
                      onChange={handleChangeEdit}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      placeholder="Select SFWMD Grid #2"
                      isClearable
                    />
                  </div>
                  <div>
                    <label
                      for="sfwmdGrid-3"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      SFWMD Grid #3
                    </label>
                    <Select
                      id="sfwmdGrid-3"
                      name="locationSFWMD3"
                      options={options}
                      value={
                        options.find(
                          (option) =>
                            option.value === selectedLocationEdit.locationSFWMD3
                        ) || null
                      }
                      onChange={handleChangeEdit}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      placeholder="Select SFWMD Grid #3"
                      isClearable
                    />
                  </div>
                  <div>
                    <label
                      for="sfwmdGrid-4"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      SFWMD Grid #4
                    </label>
                    <Select
                      id="sfwmdGrid-4"
                      name="locationSFWMD4"
                      options={options}
                      value={
                        options.find(
                          (option) =>
                            option.value === selectedLocationEdit.locationSFWMD4
                        ) || null
                      }
                      onChange={handleChangeEdit}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      placeholder="Select SFWMD Grid #4"
                      isClearable
                    />
                  </div>
                  <div>
                    <label
                      for="sfwmdGridLogic"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      SFWMD Grid Logic
                    </label>
                    <select
                      id="sfwmdGridLogic"
                      value={selectedLocationEdit.locationSFWMDLogic}
                      onChange={(e) =>
                        setSelectedLocationEdit((prev) => ({
                          ...prev,
                          locationSFWMDLogic: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="AND">AND</option>
                      <option value="OR">OR</option>
                    </select>
                  </div>
                  <div>
                    <label
                      for="wateringAlgo"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Watering Algo
                    </label>
                    <select
                      id="wateringAlgo"
                      value={selectedLocationEdit.locationWateringAlgo}
                      onChange={(e) =>
                        setSelectedLocationEdit((prev) => ({
                          ...prev,
                          locationWateringAlgo: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option selected></option>
                      <option value="formula">
                        Formula (using SFWMD Data)
                      </option>
                      <option value="forecast">Weathering Forecast</option>
                      <option value="formula_and_forecast">
                        Forecast and Formula
                      </option>
                    </select>
                  </div>
                  <div>
                    <label
                      for="wateringRainThreshold"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Watering Rain Threshold
                    </label>
                    <select
                      id="wateringAlgo"
                      value={selectedLocationEdit.locationWateringRainThreshold}
                      onChange={(e) =>
                        setSelectedLocationEdit((prev) => ({
                          ...prev,
                          locationWateringRainThreshold: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option></option>
                      <option value="0.079">0.079</option>
                      <option value="0.125">0.125"</option>
                      <option value="0.15">0.150"</option>
                      <option value="0.175">0.175"(default)</option>
                      <option value="0.2">0.20"</option>
                      <option value="0.25">0.25"</option>
                      <option value="0.3">0.3"</option>
                      <option value="0.4">0.4"</option>
                      <option value="0.5">0.5"</option>
                      <option value="0.75">0.75"</option>
                      <option value="1">1"</option>
                    </select>
                  </div>
                </div>
                <div className="pt-2 grid gap-4 mb-4 sm:grid-cols-3">
                  <div>
                    <label
                      for="contact1Name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Contact 1 Name
                    </label>
                    <input
                      type="text"
                      name="contact1Name"
                      id="contact1Name"
                      value={selectedLocationEdit.locationContactName1}
                      onChange={(e) =>
                        setSelectedLocationEdit((prev) => ({
                          ...prev,
                          locationContactName1: e.target.value,
                        }))
                      }
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="contact1Email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Contact 1 Email
                    </label>
                    <input
                      type="email"
                      name="contact1Email"
                      id="contact1Email"
                      value={selectedLocationEdit.locationContactEmail1}
                      onChange={(e) =>
                        setSelectedLocationEdit((prev) => ({
                          ...prev,
                          locationContactEmail1: e.target.value,
                        }))
                      }
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="contact1Phone"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Contact 1 Phone
                    </label>
                    <input
                      type="text"
                      name="contact1Phone"
                      id="contact1Phone"
                      value={selectedLocationEdit.locationContactPhone1}
                      onChange={(e) => {
                        const formattedValue = formatPhoneNumber(
                          e.target.value
                        );
                        setSelectedLocationEdit((prev) => ({
                          ...prev,
                          locationContactPhone1: formattedValue,
                        }));
                      }}
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="contact2Name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Contact 2 Name
                    </label>
                    <input
                      type="text"
                      name="contact2Name"
                      id="contact2Name"
                      value={selectedLocationEdit.locationContactName2}
                      onChange={(e) =>
                        setSelectedLocationEdit((prev) => ({
                          ...prev,
                          locationContactName2: e.target.value,
                        }))
                      }
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="contact2Email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Contact 2 Email
                    </label>
                    <input
                      type="text"
                      name="contact2Email"
                      id="contact2Email"
                      value={selectedLocationEdit.locationContactEmail2}
                      onChange={(e) =>
                        setSelectedLocationEdit((prev) => ({
                          ...prev,
                          locationContactEmail2: e.target.value,
                        }))
                      }
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="contact2Phone"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Contact 2 Phone
                    </label>
                    <input
                      type="text"
                      name="contact2Phone"
                      id="contact2Phone"
                      value={selectedLocationEdit.locationContactPhone2}
                      onChange={(e) => {
                        const formattedValue = formatPhoneNumber(
                          e.target.value
                        );
                        setSelectedLocationEdit((prev) => ({
                          ...prev,
                          locationContactPhone2: formattedValue,
                        }));
                      }}
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                </div>

                <div className="mt-2 flex items-center justify-end">
                  <button
                    className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                    style={{ backgroundColor: "red" }}
                    onClick={toggleCloseEditLocation}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center"
                    style={{ backgroundColor: "#009D01" }}
                  >
                    Confirm Company Profile
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
