import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../components/context/themeContext/ThemeContext";
import { useSelectedCompany } from "../../../components/context/apiContext/SelectedCompanyContext";
import { useSelector } from "react-redux";
import ApiLink from "../../../components/api/ApiLink";
import DatePicker from "react-datepicker";
import { set } from "react-hook-form";

export default function LocationBillingHistory() {
  const {
    color,
    textColor,
    backgroundColor,
    tableHeaderColor,
    tableHeaderTextColor,
  } = useContext(ThemeContext);
  const Swal = require("sweetalert2");

  const [addBilling, setAddBilling] = useState(false);
  const [editBilling, setEditBilling] = useState(false);
  const { selectedDeviceId } = useSelectedCompany();
  const is_read_only = useSelector((state) => state.auth.is_read_only);
  const { selectedLocation } = useSelectedCompany();
  const [billingHistoryData, setBillingHistoryData] = useState([]);
  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);
  const userid = useSelector((state) => state.auth.usersid);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("1");

  const [addDates, setAddDates] = useState({
    billDate: new Date(new Date().setDate(new Date().getDate() - 0)),
    readDate: new Date(new Date().setDate(new Date().getDate() - 0)),
    modifiedDate: new Date(new Date().setDate(new Date().getDate() - 0)),
  });

  const [addBillingDetails, setAddBillingDetails] = useState({
    min_amount: 0,
    max_amount: 0,
    amount_per_gallon: 0,
    note: "",
    status: 1,
    amount_of_days: 0,
  });

  const [editBillingDetails, setEditBillingDetails] = useState({
    company_billing_info_id: null,
    company_location_id: null,
    min_amount: 0,
    max_amount: 0,
    amount_per_gallon: 0,
    note: "",
    status: 1,
    read_date: null,
    bill_date: null,
    amount_of_days: 0,
  });

  const toggleAddBilling = () => {
    setAddBilling(!addBilling);
  };

  useEffect(() => {
    console.log("Min Amount: ", addBillingDetails.min_amount);
    console.log("Max Amount: ", addBillingDetails.max_amount);
    console.log("AmountPerGalloon: ", addBillingDetails.amount_per_gallon);
    console.log("Note: ", addBillingDetails.note);
    console.log("Company_location_id: ", selectedLocation.locationId);
    console.log("Status: ", addBillingDetails.status);
    console.log("UserId: ", userid);
    console.log("billDate: ", addDates.billDate);
    console.log("readDate: ", addDates.readDate);
    console.log("modifiedDate: ", addDates.modifiedDate);
  }, [addBillingDetails]);

  const handleAddBillingSubmit = (e) => {
    e.preventDefault();
    const currentDate = new Date();
    console.log("Email: ", email);
    console.log("Password: ", password);
    console.log("Company_Location_ID: ", selectedLocation.locationId);
    console.log("Min Amount: ", addBillingDetails.min_amount);
    console.log("Max Amount: ", addBillingDetails.max_amount);
    console.log("AmountPerGalloon: ", addBillingDetails.amount_per_gallon);
    console.log("Note: ", addBillingDetails.note);
    console.log("Status: ", addBillingDetails.status);
    console.log("Current Date: ", formatDate(currentDate));
    console.log("modifiedDate: ", formatDate(addDates.modifiedDate));

    console.log("UserId: ", userid);
    console.log("billDate: ", formatDate(addDates.billDate));
    console.log("readDate: ", formatDate(addDates.readDate));
    console.log("Amount of days: ", addBillingDetails.amount_of_days);

    Swal.fire({
      title: "Do you want to add this company account?",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await ApiLink.post("/api/add-billing-history", {
            email: email,
            password: password,
            company_location_id: selectedLocation.locationId,
            min_amount: addBillingDetails.min_amount,
            max_amount: addBillingDetails.max_amount,
            amount_per_gallon: addBillingDetails.amount_per_gallon,
            note: addBillingDetails.note,
            status: addBillingDetails.status,
            date_added: formatDate(currentDate),
            user_added: userid,
            user_modified: null,
            bill_date: formatDate(addDates.billDate),
            read_date: formatDate(addDates.readDate),
            amount_of_days: addBillingDetails.amount_of_days,
          });

          if (response.status === 200) {
            Swal.fire({
              title: "Billing Added Successfully",
              icon: "success",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
            setAddBilling(false);
            fetchBillingHistory();
            if (response.data.Data.is_valid == true) {
              Swal.fire({
                title: "Billing Added Successfully",
                icon: "success",
                toast: true,
                timer: 4000,
                position: "top-right",
                timerProgressBar: true,
                showConfirmButton: false,
              });
              setAddBilling(false);
              fetchBillingHistory();
            } else if (response.data.Data.is_valid == false) {
              // Extract error messages from the object
              const errorMessages = Object.entries(response.data.Data.errors)
                .map(([field, messages]) => `${messages.join(", ")}`)
                .join("\n");

              Swal.fire({
                title: errorMessages,

                icon: "error",
                toast: true,
                timer: 4000,
                position: "top-right",
                timerProgressBar: true,
                showConfirmButton: false,
              });
            }
          }
        } catch (error) {
          Swal.fire({
            title: "There is something wrong in adding billing history",
            icon: "error",
            toast: true,
            timer: 4000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      }
    });
  };

  useEffect(() => {
    fetchBillingHistory();
  }, [selectedLocation.locationId, status]);

  const fetchBillingHistory = async () => {
    setLoading(true);
    try {
      const response = await ApiLink.post("/api/get-billing-history", {
        email: email,
        password: password,
        company_location_id: selectedLocation.locationId,
        status: status,
      });
      console.log("Response: ", response.data);
      if (response.status === 200) {
        setBillingHistoryData(response.data.Data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleBillDateChange = (date) => {
    if (date) {
      const billDate = new Date(date);
      setAddDates((prev) => ({
        ...prev,
        billDate,
      }));
    }
  };
  const handleReadDateChange = (date) => {
    if (date) {
      const readDate = new Date(date);
      setAddDates((prev) => ({
        ...prev,
        readDate,
      }));
    }
  };

  const handleModifiedDateChange = (date) => {
    if (date) {
      const modifiedDate = new Date(date);
      setAddDates((prev) => ({
        ...prev,
        modifiedDate,
      }));
    }
  };
  const formatDate = (date) => {
    return date.toISOString().slice(0, 19).replace("T", " ");
  };

  const handleDeleteBilling = (company_billing_info_id) => {
    Swal.fire({
      title: "Do you want to delete this billing?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#FF0000",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await ApiLink.post("/api/delete-billing-history", {
            email: email,
            password: password,
            company_billing_info_id: company_billing_info_id,
          });
          if (response.status === 200) {
            Swal.fire({
              title: "Billing Deleted Successfully",

              icon: "success",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
            fetchBillingHistory();
          } else {
            Swal.fire({
              title: "Billing Deleted Successfully",
              icon: "success",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        } catch (error) {
          Swal.fire({
            title: "Billing Deleted Successfully",
            icon: "success",
            toast: true,
            timer: 4000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      }
    });
  };

  const toggleEditBilling = (
    company_billing_info_id,
    company_location_id,
    min_amount,
    max_amount,
    amount_per_gallon,
    note,
    status,
    read_date,
    bill_date,
    amount_of_days
  ) => {
    console.log(company_billing_info_id);
    console.log(company_location_id);
    console.log(min_amount);
    console.log(max_amount);
    console.log(amount_per_gallon);
    console.log(note);
    console.log(status);
    console.log(read_date);
    console.log(bill_date);
    console.log(amount_of_days);
    setEditBillingDetails({
      company_billing_info_id,
      company_location_id,
      min_amount,
      max_amount,
      amount_per_gallon,
      note,
      status,
      read_date,
      bill_date,
      amount_of_days,
    });
    setEditBilling(true);
  };

  const handleEditBillingSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Do you want to update this billing?",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#FF0000",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await ApiLink.post("/api/update-billing-history", {
            email: email,
            password: password,
            company_billing_info_id: editBillingDetails.company_billing_info_id,
            min_amount: editBillingDetails.min_amount,
            max_amount: editBillingDetails.max_amount,
            amount_per_gallon: editBillingDetails.amount_per_gallon,
            note: editBillingDetails.note,
            user_modified: userid,
            bill_date: formatDate(editBillingDetails.bill_date),
            read_date: formatDate(editBillingDetails.read_date),
            amount_of_days: editBillingDetails.amount_of_days,
          });
          if (response.status === 200) {
            Swal.fire({
              title: "Billing Updated Successfully",
              icon: "success",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
            setEditBilling(false);
            fetchBillingHistory();
          } else {
            Swal.fire({
              title: "There was an error in updating the billing",
              icon: "error",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        } catch (error) {
          Swal.fire({
            title: "There was an error in updating the billing",
            icon: "error",
            toast: true,
            timer: 4000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      }
    });
  };

  return (
    <div className={`p-4 border-gray-200 border-dashed rounded-md`}>
      <div className="flex justify-between items-center mb-1">
        <div>
          <button>
            <select
              id="status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="1">Enabled</option>
              <option value="2">Pending</option>
              <option value="3">Deleted</option>
            </select>
          </button>
        </div>
        {is_read_only !== 1 && (
          <div className="">
            <button
              className={`px-3 py-1.5 text-sm rounded-md bg-${color}-200 hover:bg-${color}-400 text-${color}-800 font-semibold`}
              style={{
                backgroundColor: `${backgroundColor}`,
                color: `${textColor}`,
              }}
              onClick={toggleAddBilling}
            >
              + Add Billing
            </button>
          </div>
        )}
      </div>

      <div className="relative overflow-x-auto animate__animated animate__fadeIn">
        <table className="animate__animated animate__fadeIn w-full rounded-t-md text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 leading-4">
          <thead
            className={`text-sm uppercase leading-4 rounded-t-md`}
            style={{
              backgroundColor: `${tableHeaderColor}`,
              color: `${tableHeaderTextColor}`,
            }}
          >
            <tr>
              <th scope="col" className="px-6 py-3 border border-black">
                <h1>Minimum Consumption</h1>
                <h1>(In Gallons)</h1>
              </th>
              <th scope="col" className="px-6 py-3 border border-black">
                <h1>Maximum Consumption</h1>
                <h1>(In Gallons)</h1>
              </th>
              <th scope="col" className="px-6 py-3 border border-black">
                <h1>Amount Per 1000 Gallon</h1>
                <h1>(In Dollars)</h1>
              </th>
              <th scope="col" className="px-6 py-3 border border-black">
                Note
              </th>
              <th scope="col" className="px-6 py-3 border border-black">
                Date Added
              </th>
              <th scope="col" className="px-6 py-3 border border-black">
                Status
              </th>

              <th scope="col" className="px-6 py-3 border border-black">
                Read Date
              </th>
              <th scope="col" className="px-6 py-3 border border-black">
                Bill Date
              </th>
              <th scope="col" className="px-6 py-3 border border-black">
                Amount of Days
              </th>
              <th scope="col" className="px-6 py-3 border border-black">
                Action
              </th>
            </tr>
          </thead>
          {loading ? (
            <tr>
              <td colSpan="10" className="h-64">
                <div
                  role="status"
                  className="flex justify-center items-center h-full"
                >
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              </td>
            </tr>
          ) : (
            <tbody className="text-sm text-black dark:text-white leading-4">
              {billingHistoryData.length > 0 ? (
                billingHistoryData.map((item) => (
                  <tr
                    key={item.company_billing_info_id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  >
                    <td className="px-6 py-4 border border-black dark:border-white">
                      {item.min_amount}
                    </td>
                    <td className="px-6 py-4 border border-black dark:border-white">
                      {item.max_amount}
                    </td>
                    <td className="px-6 py-4 border border-black dark:border-white">
                      ${item.amount_per_gallon}
                    </td>
                    <td className="px-6 py-4 border border-black dark:border-white">
                      {item.note || "-"}
                    </td>
                    <td className="px-6 py-4 border border-black dark:border-white">
                      <h1>{item.date_added}</h1>
                    </td>
                    <td className="px-6 py-4 border border-black dark:border-white">
                      <div
                        className={`status p-2.5 rounded-md inline-block ${
                          item.status === 0
                            ? "bg-red-300 text-red-900"
                            : item.status === 1
                            ? "text-white"
                            : item.status === 2
                            ? "bg-blue-300 text-blue-900"
                            : item.status === 3
                            ? "bg-red-300 text-red-900"
                            : ""
                        }`}
                        style={
                          item.status === 1
                            ? { backgroundColor: "#74D348" }
                            : {}
                        }
                      >
                        {item.status === 0
                          ? "Disabled"
                          : item.status === 1
                          ? "Active"
                          : item.status === 2
                          ? "Pending"
                          : item.status === 3
                          ? "Deleted"
                          : ""}
                      </div>
                    </td>
                    <td className="px-6 py-4 border border-black dark:border-white">
                      {item.read_date || "-"}
                    </td>
                    <td className="px-6 py-4 border border-black dark:border-white">
                      {item.bill_date || "-"}
                    </td>
                    <td className="px-6 py-4 border border-black dark:border-white">
                      {item.amount_of_days || "-"}
                    </td>
                    <td className="px-6 py-4 border border-black dark:border-white">
                      <div className="flex justify-start items-center gap-3">
                        <div
                          className="p-1 rounded-full hover:bg-gray-200 dark:hover:bg-gray-200"
                          onClick={() =>
                            toggleEditBilling(
                              item.company_billing_info_id,
                              item.company_location_id,
                              item.min_amount,
                              item.max_amount,
                              item.amount_per_gallon,
                              item.note,
                              item.status,
                              item.read_date,
                              item.bill_date,
                              item.amount_of_days
                            )
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="blue"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                            />
                          </svg>
                        </div>
                        <div
                          className="p-1 rounded-full hover:bg-gray-200 dark:hover:bg-gray-200"
                          onClick={() =>
                            handleDeleteBilling(item.company_billing_info_id)
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="red"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10" className="text-center py-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="w-8 h-8 mx-auto text-gray-500 dark:text-gray-400"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="12" y1="8" x2="12" y2="12"></line>
                      <line x1="12" y1="16" x2="12.01" y2="16"></line>
                    </svg>
                    <p className="animate__animated animate__fadeIn mt-2 text-sm text-gray-500 dark:text-gray-400">
                      No billing found
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
      {addBilling && (
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full font-inter"
          style={{
            // backdropFilter: "blur(5px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="relative p-4 w-full max-w-4xl h-full md:h-auto">
            <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
              <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Add Water Billing
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal"
                  onClick={() => setAddBilling(false)}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <form onSubmit={handleAddBillingSubmit}>
                <div className="grid gap-4 mb-4 sm:grid-cols-3">
                  <div>
                    <label
                      for="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Select Bill Date
                    </label>
                    <div className="relative">
                      <div className="absolute left-3 top-1/2 transform -translate-y-1/2 z-10 text-gray-600">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                          />
                        </svg>
                      </div>
                      <DatePicker
                        selected={addDates.billDate}
                        onChange={handleBillDateChange}
                        dateFormat="yyyy-MM-dd"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-40 pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholderText="Select bill date"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      for="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Select Read Date
                    </label>
                    <div className="relative">
                      <div className="absolute left-3 top-1/2 transform -translate-y-1/2 z-10 text-gray-600">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                          />
                        </svg>
                      </div>
                      <DatePicker
                        selected={addDates.readDate}
                        onChange={handleReadDateChange}
                        dateFormat="yyyy-MM-dd"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-40 pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholderText="Select read date"
                      />
                    </div>
                  </div>
                  <div></div>
                  <div>
                    <label
                      htmlFor="mimumWaterConsumption"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      <p>Minimum Water Consumption</p> <p>(In Gallons)</p>
                    </label>
                    <input
                      type="number"
                      name="mimumWaterConsumption"
                      id="mimumWaterConsumption"
                      value={addBillingDetails.min_amount}
                      onChange={(e) =>
                        setAddBillingDetails({
                          ...addBillingDetails,
                          min_amount: e.target.value,
                        })
                      }
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="maximumWaterConsumption"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      <p> Maximum Water Consumption</p>
                      <p> (In Gallons)</p>
                    </label>
                    <input
                      type="number"
                      name="maximumWaterConsumption"
                      id="maximumWaterConsumption"
                      value={addBillingDetails.max_amount}
                      onChange={(e) =>
                        setAddBillingDetails({
                          ...addBillingDetails,
                          max_amount: e.target.value,
                        })
                      }
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="amountPerGallon"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      <p>Amount Per Gallon </p>
                      <p>(In Dollars)</p>
                    </label>
                    <input
                      type="number"
                      name="amountPerGallon"
                      id="amountPerGallon"
                      value={addBillingDetails.amount_per_gallon}
                      onChange={(e) =>
                        setAddBillingDetails({
                          ...addBillingDetails,
                          amount_per_gallon: e.target.value,
                        })
                      }
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="mimumWaterConsumption"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Amount of Days
                    </label>
                    <input
                      type="number"
                      name="mimumWaterConsumption"
                      id="mimumWaterConsumption"
                      value={addBillingDetails.amount_of_days}
                      onChange={(e) =>
                        setAddBillingDetails({
                          ...addBillingDetails,
                          amount_of_days: e.target.value,
                        })
                      }
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="countries"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Select Status
                    </label>
                    <select
                      id="status"
                      value={addBillingDetails.status}
                      onChange={(e) =>
                        setAddBillingDetails({
                          ...addBillingDetails,
                          status: e.target.value,
                        })
                      }
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="1">Enabled</option>
                      <option value="2">Pending</option>
                      <option value="3">Deleted</option>
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="note"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Note
                    </label>
                    <textarea
                      id="note"
                      rows="4"
                      value={addBillingDetails.note}
                      onChange={(e) =>
                        setAddBillingDetails({
                          ...addBillingDetails,
                          note: e.target.value,
                        })
                      }
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder=""
                    ></textarea>
                  </div>
                </div>
                <div className="flex items-center justify-end">
                  <button
                    className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                    style={{ backgroundColor: "red" }}
                    onClick={() => setAddBilling(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center"
                    style={{ backgroundColor: "#009D01" }}
                  >
                    Save Billing
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {editBilling && (
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full font-inter"
          style={{
            // backdropFilter: "blur(5px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="relative p-4 w-full max-w-4xl h-full md:h-auto">
            <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
              <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Edit Water Billing
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal"
                  onClick={() => setEditBilling(false)}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <form onSubmit={handleEditBillingSubmit}>
                <div className="grid gap-4 mb-4 sm:grid-cols-3">
                  <div>
                    <label
                      for="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Select Bill Date
                    </label>
                    <div className="relative">
                      <div className="absolute left-3 top-1/2 transform -translate-y-1/2 z-10 text-gray-600">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                          />
                        </svg>
                      </div>
                      <DatePicker
                        selected={editBillingDetails.bill_date}
                        onChange={(e) =>
                          setEditBillingDetails({
                            ...editBillingDetails,
                            bill_date: e,
                          })
                        }
                        dateFormat="yyyy-MM-dd"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-40 pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholderText="Select bill date"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      for="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Select Read Date
                    </label>
                    <div className="relative">
                      <div className="absolute left-3 top-1/2 transform -translate-y-1/2 z-10 text-gray-600">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                          />
                        </svg>
                      </div>
                      <DatePicker
                        selected={editBillingDetails.read_date}
                        onChange={(e) =>
                          setEditBillingDetails({
                            ...editBillingDetails,
                            read_date: e,
                          })
                        }
                        dateFormat="yyyy-MM-dd"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-40 pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholderText="Select read date"
                      />
                    </div>
                  </div>
                  <div></div>
                  <div>
                    <label
                      htmlFor="mimumWaterConsumption"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      <p>Minimum Water Consumption</p> <p>(In Gallons)</p>
                    </label>
                    <input
                      type="number"
                      name="mimumWaterConsumption"
                      id="mimumWaterConsumption"
                      value={editBillingDetails.min_amount}
                      onChange={(e) =>
                        setEditBillingDetails({
                          ...editBillingDetails,
                          min_amount: e.target.value,
                        })
                      }
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="maximumWaterConsumption"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      <p> Maximum Water Consumption</p>
                      <p> (In Gallons)</p>
                    </label>
                    <input
                      type="number"
                      name="maximumWaterConsumption"
                      id="maximumWaterConsumption"
                      value={editBillingDetails.max_amount}
                      onChange={(e) =>
                        setEditBillingDetails({
                          ...editBillingDetails,
                          max_amount: e.target.value,
                        })
                      }
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="amountPerGallon"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      <p>Amount Per Gallon </p>
                      <p>(In Dollars)</p>
                    </label>
                    <input
                      type="number"
                      name="amountPerGallon"
                      id="amountPerGallon"
                      value={editBillingDetails.amount_per_gallon}
                      onChange={(e) =>
                        setEditBillingDetails({
                          ...editBillingDetails,
                          amount_per_gallon: e.target.value,
                        })
                      }
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="mimumWaterConsumption"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Amount of Days
                    </label>
                    <input
                      type="number"
                      name="mimumWaterConsumption"
                      id="mimumWaterConsumption"
                      value={editBillingDetails.amount_of_days}
                      onChange={(e) =>
                        setEditBillingDetails({
                          ...editBillingDetails,
                          amount_of_days: e.target.value,
                        })
                      }
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="countries"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Select Status
                    </label>
                    <select
                      id="status"
                      value={editBillingDetails.status}
                      onChange={(e) =>
                        setEditBillingDetails({
                          ...editBillingDetails,
                          status: e.target.value,
                        })
                      }
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="1">Enabled</option>
                      <option value="2">Pending</option>
                      <option value="3">Deleted</option>
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="note"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Note
                    </label>
                    <textarea
                      id="note"
                      rows="4"
                      value={editBillingDetails.note}
                      onChange={(e) =>
                        setEditBillingDetails({
                          ...editBillingDetails,
                          note: e.target.value,
                        })
                      }
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder=""
                    ></textarea>
                  </div>
                </div>
                <div className="flex items-center justify-end">
                  <button
                    className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                    style={{ backgroundColor: "red" }}
                    onClick={() => setEditBilling(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center"
                    style={{ backgroundColor: "#009D01" }}
                  >
                    Save Billing
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
