import React, { useContext, useEffect, useState, useRef } from "react";
import { ThemeContext } from "../../../components/context/themeContext/ThemeContext";
import ApiLink from "../../../components/api/ApiLink";
import { useSelector } from "react-redux";
import LocationRenameZones from "./LocationRenameZones";
import { useSelectedCompany } from "../../../components/context/apiContext/SelectedCompanyContext";
import AddProgramLocationDevices from "./modals/AddProgramLocationDevices";
import Swal from "sweetalert2";

const TableDisplay = ({
  zoneListData,
  color,
  backgroundColor,
  textColor,
  loading,
  calculateDuration,
  formatDays,
  formatZones,
  getProgramType,
  currentDisplay,
  setCurrentDisplay,
  setSelectedProgramDetails,
  uploadedImages,
  setUploadedImages,
  zoneLabels,
  tableHeaderColor,
  tableHeaderTextColor,
  companyLDID,
  email,
  password,
  fetchCompanyLocationDevice,
  setLocationDeviceProgramModal,
}) => {
  const userType = useSelector((state) => state.auth.userType);

  // const is_read_only = useState(0);
  const is_read_only = useSelector((state) => state.auth.is_read_only);

  const handleSelectedProgramDetails = (
    program,
    start,
    end,
    zone,
    type,
    days,
    programLetter,
    duration
  ) => {
    setCurrentDisplay("second");
    console.log(
      "SelectedProgramDetails: ",
      program,
      start,
      end,
      zone,
      type,
      days,
      programLetter,
      duration
    );
    setSelectedProgramDetails({
      program: program,
      start: start,
      end: end,
      zone: zone,
      type: type,
      days: days,
      programLetter: programLetter,
      duration: duration,
    });
  };
  const formatTime = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    const date = new Date();
    date.setHours(hours, minutes);

    return new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(date);
  };

 

  const handleDeleteProgram = (programId, programLetter) => {
    console.log("programId: ", programId);
    Swal.fire({
      title: "Do you want to delete this account location program?",
      text: `"Program ${programLetter}: ${programId}" will be deleted permanently.`,
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#FF0000",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await ApiLink.post(
            "/api/delete-company-location-watering-program",
            {
              email: email,
              password: password,
              company_location_watering_program_id: programId,
            }
          );

          if (response.status === 200) {
            Swal.fire({
              title: "Watering Program Deleted Successfully",

              icon: "success",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
            setLocationDeviceProgramModal(false);
            fetchCompanyLocationDevice();
          } else {
            Swal.fire({
              title: "There is something wrong in updating the company account",
              icon: "error",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        } catch (error) {
          Swal.fire({
            title: "There is something wrong in updating the company account",
            icon: "error",
            toast: true,
            timer: 4000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      }
    });
  };

  useEffect(() => {
    console.log("zoneListData: ", zoneListData);
  }, []);

  const transformedPrograms = Object.entries(zoneListData.Programs).flatMap(([programLetter, programs]) =>
    programs.map(program => ({
      ...program,
      programLetter
    }))
  );

  return (
    <div>
      <table className="w-full text-sm text-left rtl:text-right text-black dark:text-white">
        <thead
          className={`text-sm uppercase leading-4`}
          style={{
            backgroundColor: `${tableHeaderColor}`,
            color: `${tableHeaderTextColor}`,
          }}
        >
          <tr>
            <th scope="col" className="px-6 py-3 border border-black">
              Program
            </th>
            <th scope="col" className="px-6 py-3 border border-black">
              Start Time
            </th>
            <th scope="col" className="px-6 py-3 border border-black">
              End Time
            </th>
            <th scope="col" className="px-6 py-3 border border-black">
              Duration (mins)
            </th>
            <th scope="col" className="px-6 py-3 border border-black">
              Program Type
            </th>
            <th scope="col" className="px-6 py-3 border border-black">
              Scheduled Days
            </th>
            <th scope="col" className="px-6 py-3 border border-black">
              Zone Info
            </th>
            <th scope="col" className="px-6 py-3 border border-black">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="leading-4">
          {loading ? (
            <tr>
              <td colSpan="8" className="h-16">
                <div
                  role="status"
                  className="flex justify-center items-center h-full"
                >
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              </td>
            </tr>
          ) : (
            transformedPrograms.map((details) => (
              <tr key={details.programId} programId={details.programId} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="border border-black dark:border-white px-6 py-4">{details.programLetter}</td>
                <td className="border border-black dark:border-white px-6 py-4">{formatTime(details.schedule.start_time)}</td>
                <td className="border border-black dark:border-white px-6 py-4">{formatTime(details.schedule.end_time)}</td>
                <td className="border border-black dark:border-white px-6 py-4">
                  {calculateDuration(details.schedule.start_time, details.schedule.end_time)}
                </td>
                <td className="border border-black dark:border-white px-6 py-4">
                  <button className="relative mt-2 inline-flex items-center justify-center p-0.5 mb-2 me-2 text-sm font-medium text-gray-900 rounded-lg group dark:text-white">
                    <span className="relative px-2 py-2 transition-all ease-in duration-75 font-bold rounded-md"
                      style={{
                        backgroundColor: `${backgroundColor}`,
                        color: `${textColor}`,
                      }}>
                      {details.schedule.is_regular ? "Regular" : ""}
                      {details.schedule.is_half_sprinkle === 1 ? " 1/2 Sprinkle" : ""}
                      {!(details.schedule.is_regular || details.schedule.is_next_day === 1 || details.schedule.is_half_sprinkle === 1) && "Default"}
                    </span>
                    <span className="p-2">
                      {details.schedule.is_next_day === 1 && (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="blue" className="size-4">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                        </svg>
                      )}
                    </span>
                  </button>
                </td>
                <td className="border border-black dark:border-white px-6 py-4">
                  <div className="flex flex-col">{formatDays(details.days)}</div>
                </td>
                <td className="border border-black dark:border-white px-6 py-4">
                  <div className="flex flex-col overflow-x-auto">
                    {formatZones(zoneListData.Zones[details.programLetter], uploadedImages, zoneLabels, details.programLetter)}
                  </div>
                </td>
                <td className="border border-black dark:border-white px-4 py-2 md:z-50">
                  <div className="flex items-center justify-start gap-3 w-full">
                    {userType !== 2 && userType !== 4 && is_read_only !== 1 && (
                      <button className="edit" onClick={() => handleSelectedProgramDetails({
                        program: details.programId,
                        start: details.schedule.start_time,
                        end: details.schedule.end_time,
                        zones: zoneListData.Zones[details.programLetter],
                        type: details.schedule,
                        days: details.days,
                        programLetter: details.programLetter,
                        duration: details.schedule.duration,
                      })}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="blue" className="size-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                        </svg>
                      </button>
                    )}
                    {userType !== 2 && userType !== 4 && is_read_only !== 1 && (
                      <button className="delete" onClick={() => handleDeleteProgram(details.programId, details.programLetter)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="red" className="size-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                        </svg>
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

const EditDeviceProgram = ({
  currentDisplay,
  setCurrentDisplay,
  selectedProgramDetails,
  setSelectedProgramDetails,
  backgroundColor,
  textColor,
  companyLDID,
  selectedLocation,
  zoneListData,
  email,
  password,
  setLocationDeviceProgramModal,
  fetchCompanyLocationDevice,
}) => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef = useRef(null);
  const [searchInputs, setSearchInputs] = useState({});
  const [zoneValues, setZoneValues] = useState({});
  const daysOfWeek = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  useEffect(() => {
    console.log("SelectedProgramDetails: second ", selectedProgramDetails);

    const initialZoneValues = {};
    selectedProgramDetails.program?.zones.forEach((zone) => {
      const [zoneName, value] = Object.entries(zone)[0];
      initialZoneValues[zoneName.trim()] = value;
    });
    setZoneValues(initialZoneValues);
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedProgramDetails, selectedProgramDetails]);

  const toggleDropdown = (zone) => {
    setOpenDropdown(openDropdown === zone ? null : zone);
  };

  const getZoneValue = (zone) => {
    const value = zoneValues[`Zone${zone}`];
    console.log(`Getting value for Zone${zone}:`, value);
    return value !== undefined ? value : "Select";
  };

  const handleZoneValueChange = (zone, value) => {
    setZoneValues((prev) => ({ ...prev, [`Zone${zone}`]: value }));

    setSelectedProgramDetails((prevDetails) => {
      const existingZoneIndex = prevDetails.program.zones.findIndex(
        (z) => Object.keys(z)[0].trim() === `Zone${zone}`
      );

      let updatedZones;
      if (existingZoneIndex !== -1) {
        // Update existing zone
        updatedZones = prevDetails.program.zones.map((z, index) =>
          index === existingZoneIndex ? { [`Zone${zone}`]: value } : z
        );
      } else {
        // Add new zone
        updatedZones = [
          ...prevDetails.program.zones,
          { [`Zone${zone}`]: value },
        ];
      }

      return {
        ...prevDetails,
        program: {
          ...prevDetails.program,
          zones: updatedZones,
        },
      };
    });

    setOpenDropdown(null);
  };

  const handleInputChange = (field, value) => {
    setSelectedProgramDetails((prev) => ({
      ...prev,
      program: {
        ...prev.program,
        [field]: value,
      },
    }));
  };

  const handleTypeChange = (field, value) => {
    setSelectedProgramDetails((prev) => ({
      ...prev,
      program: {
        ...prev.program,
        type: {
          ...prev.program.type,
          [field]: value ? 1 : 0,
        },
      },
    }));
  };

  const handleDayChange = (day, value) => {
    setSelectedProgramDetails((prev) => ({
      ...prev,
      program: {
        ...prev.program,
        days: {
          ...prev.program.days,
          [day]: value ? 1 : 0,
        },
      },
    }));
  };

  function transformZones(zonesData) {
    // Initialize an array of 42 elements, all set to 0
    const result = new Array(42).fill(0);

    // Iterate through the zonesData
    zonesData.forEach((zoneObj, index) => {
      // Extract the zone number and duration
      const zoneKey = Object.keys(zoneObj)[0];
      const zoneNumber = parseInt(zoneKey.trim().replace("Zone", "")) - 1; // Convert to 0-based index
      const zoneDuration = zoneObj[zoneKey];

      // Update the result array if the zone number is valid
      if (zoneNumber >= 0 && zoneNumber < 42) {
        result[zoneNumber] = zoneDuration;
      }
    });

    return result;
  }
  const transformedZones = transformZones(selectedProgramDetails.program.zones);

  function transformDays(daysData) {
    // Define the order of days
    const orderOfDays = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];

    // Create an array of 7 elements based on the order
    const result = orderOfDays.map((day) => daysData[day] || 0);

    return result;
  }
  const transformedDays = transformDays(selectedProgramDetails.program.days);
  useEffect(() => {
    console.log(
      "Updated selectedProgramDetails:",
      selectedProgramDetails.program
    );
    console.log("CompanyLDID: ", companyLDID);
    console.log("Location_ID: ", selectedLocation.locationId);
    console.log("Email: ", email);
    console.log("Password: ", password);
    console.log("RecordID: ", selectedProgramDetails.program.program);
    console.log("Program: ", selectedProgramDetails.program.programLetter);

    console.log("Start: ", selectedProgramDetails.program.start);
    console.log("End: ", selectedProgramDetails.program.end);
    console.log("Duration: ", selectedProgramDetails.program.duration);
    console.log("Next Day: ", selectedProgramDetails.program.type.is_next_day);
    console.log("isRegular: ", selectedProgramDetails.program.type.is_regular);
    console.log(
      "is_half_sprinkle: ",
      selectedProgramDetails.program.type.is_half_sprinkle
    );
    console.log("Zones: ", transformedZones);
    console.log("Days: ", transformedDays);
  }, [selectedProgramDetails, zoneValues, companyLDID]);

  const handleEditSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Do you want to update this watering program?",
      showCancelButton: true,
      confirmButtonText: "Save",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#FF0000",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await ApiLink.post(
            "/api/update-company-location-watering-program",
            {
              company_location_device_id: companyLDID,
              company_location_id: selectedLocation.locationId,
              email: email,
              password: password,
              recordid: selectedProgramDetails.program.program,
              start_time: selectedProgramDetails.program.start,
              end_time: selectedProgramDetails.program.end,
              program: selectedProgramDetails.program.programLetter,
              duration_minutes: selectedProgramDetails.program.duration,
              is_next_day: selectedProgramDetails.program.type.is_next_day,
              is_regular: selectedProgramDetails.program.type.is_regular,
              is_half_sprinkle:
                selectedProgramDetails.program.type.is_half_sprinkle,
              zones: transformedZones,
              days: transformedDays,
            }
          );
          if (response.status === 200) {
            Swal.fire({
              title: "Location Device Updated Successfully",
              icon: "success",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
            setLocationDeviceProgramModal(false);
            fetchCompanyLocationDevice();
          } else if (response.data.Data.is_valid == true) {
            Swal.fire({
              title: "Location Device Updated Successfully",
              icon: "success",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
            setLocationDeviceProgramModal(false);
            fetchCompanyLocationDevice();
          } else if (response.data.Data.is_valid == false) {
            // Extract error messages from the object
            const errorMessages = Object.entries(response.data.Data.errors)
              .map(([field, messages]) => `${messages.join(", ")}`)
              .join("\n");

            Swal.fire({
              title: errorMessages,

              icon: "error",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          } else {
            Swal.fire({
              title: "There is something wrong in updating the company account",
              icon: "error",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        } catch (error) {}
      }
    });
  };

  return (
    <div className="ml-1">
      <div className="flex justify-between">
        <h1 className="font-semibold text-xl my-3 text-gray-900 dark:text-white">
          Days of Week
        </h1>
        <button
          className={`rounded-md h-fit p-2`}
          onClick={() => setCurrentDisplay("first")}
          style={{ backgroundColor: backgroundColor, color: textColor }}
        >
          <h1 className="text-sm">
            {currentDisplay === "second" ? "Go Back" : ""}
          </h1>
        </button>
      </div>
      <div className="grid gap-4 mb-4 sm:grid-cols-6 border-b border-gray-300 pb-5">
        <div>
          <label
            htmlFor="program"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Program
          </label>
          <select
            id="program"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={selectedProgramDetails.program.programLetter}
            onChange={(e) => handleInputChange("programLetter", e.target.value)}
          >
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>
          </select>
        </div>
        <div>
          <label
            htmlFor="startTime"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Start Time
          </label>
          <input
            type="time"
            id="startTime"
            value={selectedProgramDetails.program?.start}
            onChange={(e) => handleInputChange("start", e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div>
          <label
            htmlFor="endTime"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            End Time
          </label>
          <input
            type="time"
            id="endTime"
            value={selectedProgramDetails.program?.end}
            onChange={(e) => handleInputChange("end", e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>

        <div className="flex items-center">
          <input
            id="isRegular"
            type="checkbox"
            checked={selectedProgramDetails.program?.type.is_regular === 1}
            onChange={(e) => handleTypeChange("is_regular", e.target.checked)}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            htmlFor="isRegular"
            className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Is Regular
          </label>
        </div>
        <div className="flex items-center">
          <input
            id="isHalfSprinkle"
            type="checkbox"
            checked={
              selectedProgramDetails.program?.type.is_half_sprinkle === 1
            }
            onChange={(e) =>
              handleTypeChange("is_half_sprinkle", e.target.checked)
            }
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            htmlFor="isHalfSprinkle"
            className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            1/2 Sprinkle
          </label>
        </div>
        <div className="flex items-center">
          <input
            id="isDayOverlap"
            type="checkbox"
            checked={selectedProgramDetails.program?.type.is_next_day === 1}
            onChange={(e) => handleTypeChange("is_next_day", e.target.checked)}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            htmlFor="isDayOverlap"
            className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            is Day Overlap
          </label>
        </div>
      </div>
      <div className="grid gap-4 mb-4 sm:grid-cols-7 border-b border-gray-300 pb-5 my-3">
        {daysOfWeek.map((day) => (
          <div key={day} className="flex items-center">
            <input
              id={day}
              type="checkbox"
              checked={selectedProgramDetails.program?.days[day] === 1}
              onChange={(e) => handleDayChange(day, e.target.checked)}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor={day}
              className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              {day.charAt(0).toUpperCase() + day.slice(1)}
            </label>
          </div>
        ))}
      </div>

      <div className="grid gap-4 mb-4 sm:grid-cols-6 border-b border-gray-300 pb-5 my-3">
        {Array.from({ length: 42 }, (_, i) => i + 1).map((zone) => (
          <div key={zone} className="relative">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Zone {zone}
            </label>
            <div className="relative">
              <button
                type="button"
                onClick={() => toggleDropdown(zone)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                {getZoneValue(zone)}
              </button>
              {openDropdown === zone && (
                <div
                  ref={dropdownRef}
                  className="absolute z-10 w-full bg-white border border-gray-300 mt-1 rounded-lg shadow-lg max-h-60 overflow-y-auto"
                >
                  <div className="p-2">
                    <input
                      type="text"
                      placeholder="Search..."
                      className="w-full p-2 border border-gray-300 rounded"
                      value={searchInputs[zone] || ""}
                      onChange={(e) =>
                        setSearchInputs({
                          ...searchInputs,
                          [zone]: e.target.value,
                        })
                      }
                      onClick={(e) => e.stopPropagation()}
                    />
                  </div>
                  {Array.from({ length: 121 }, (_, i) => i)
                    .filter((value) =>
                      value.toString().includes(searchInputs[zone] || "")
                    )
                    .map((value) => (
                      <button
                        key={value}
                        onClick={() => handleZoneValueChange(zone, value)}
                        className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                      >
                        {value}
                      </button>
                    ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="flex items-center justify-end">
        <button
          className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
          style={{ backgroundColor: "red" }}
          onClick={() => setCurrentDisplay("first")}
        >
          Cancel
        </button>
        <button
          onClick={handleEditSubmit}
          className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center"
          style={{ backgroundColor: "#009D01" }}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

const LocationPrograms = ({
  toggleLocationDeviceProgramModal,
  companyLDID,
  companyLocationDeviceData,
  locationDeviceProgramModal,
  setLocationDeviceProgramModal,
  fetchCompanyLocationDevice,
}) => {
  const Swal = require("sweetalert2");

  const {
    color,
    textColor,
    backgroundColor,
    tableHeaderColor,
    tableHeaderTextColor,
  } = useContext(ThemeContext);
  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);
  const [loading, setLoading] = useState(false);
  const [currentDisplay, setCurrentDisplay] = useState("first");
  const [zoneListData, setZoneListData] = useState({ Programs: {}, Zones: {} });
  const [selectedProgramDetails, setSelectedProgramDetails] = useState({
    program: null,
    start: null,
    end: null,
    zone: null,
    type: null,
    days: null,
    programLetter: null,
  });
  const [uploadedImages, setUploadedImages] = useState([]);
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [viewImage, setViewImage] = useState(null);
  const [imagePreviews, setImagePreviews] = useState({});
  const { selectedLocation } = useSelectedCompany();
  const [zoneLabels, setZoneLabels] = useState({});
  const [addProgramModal, setAddProgramModal] = useState(false);

  const fileInputRef = useRef(null);

  const handleImageChange = (e, zoneIndex) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);

      // Update the image preview for the specific zone index
      setImagePreviews((prevPreviews) => ({
        ...prevPreviews,
        [zoneIndex]: imageUrl,
      }));

      // Optionally update uploadedImages array to keep track of uploaded images
      setUploadedImages((prevImages) =>
        prevImages.map((img, idx) =>
          idx === zoneIndex ? { ...img, image_url: imageUrl, file: file } : img
        )
      );
    }
  };

  const toggleFullScreenImage = (imageUrl) => {
    setFullScreenImage(imageUrl);
  };

  useEffect(() => {
    setLoading(true);

    fetchZoneList();
  }, [email, password, companyLDID]);
  const fetchZoneList = async () => {
    try {
      const response = await ApiLink.post("/api/zone-list", {
        email: email,
        password: password,
        company_location_device_id: companyLDID,
      });

      if (response.status === 200 && response.data.Data) {
        setZoneListData(response.data.Data);
      }
    } catch (error) {
      console.error("Error fetching zone list:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchUploadImage = async () => {
      try {
        const response = await ApiLink.post("/api/get-upload-image", {
          email: email,
          password: password,
          company_location_device_id: companyLDID,
        });

        if (response.status === 200 && response.data.Data) {
          console.log("Upload image:", response.data.Data);
          setUploadedImages(response.data.Data.image_data);
        }
      } catch (error) {
        console.error("Error fetching upload image:", error);
      }
    };
    fetchUploadImage();
  }, [email, password, companyLDID]);

  useEffect(() => {
    const fetchZoneLabels = async () => {
      try {
        const response = await ApiLink.post("/api/company-location-device", {
          email: email,
          password: password,
          company_location_id: selectedLocation.locationId,
        });

        if (
          response.status === 200 &&
          response.data.Data &&
          response.data.Data.length > 0
        ) {
          const zoneLabelsData = JSON.parse(response.data.Data[0].zone_labels);
          setZoneLabels(zoneLabelsData);
        }
      } catch (error) {
        console.error("Error fetching zone labels:", error);
      }
    };

    fetchZoneLabels();
  }, [email, password, selectedLocation.locationId]);
  const formatDays = (days) => {
    const dayNames = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    return dayNames
      .filter((day) => days[day.toLowerCase()] === 1)
      .map((day) => (
        <span key={day} className="block">
          {day}
        </span>
      ));
  };

  const formatZones = (zones, uploadedImages, zoneLabels = {}, program) => {
    return zones.map((zone, index) => {
      const [zoneName, duration] = Object.entries(zone)[0];
      const zoneNumber = parseInt(zoneName.replace("Zone", "").trim());

      const customLabel = (zoneLabels && zoneLabels[`zone${zoneNumber}`]) || "";
      const displayName = customLabel
        ? `${zoneName}: ${customLabel}`
        : zoneName;
      const isZoneA = program === "A";

      const matchingImage = uploadedImages.find(
        (img) => img.zone_number === zoneNumber
      );

      const imageElement = isZoneA ? (
        matchingImage ? (
          <div className="flex items-center">
            <img
              src={matchingImage.image_url}
              alt={displayName}
              className="w-10 h-10 mr-2 cursor-pointer"
              onClick={() => toggleFullScreenImage(matchingImage.image_url)}
            />
            <input
              className="hidden"
              id={`file_input_${index}`}
              type="file"
              accept="image/*"
              onChange={(e) => handleImageChange(e, index)}
            />
            <button
              onClick={() =>
                document.getElementById(`file_input_${index}`).click()
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 ml-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15M9 12l3 3m0 0 3-3m-3 3V2.25"
                />
              </svg>
            </button>
            <button
              className="p-1 rounded-md bg-[#62CB31] hover:bg-[#74D348]"
              onClick={() =>
                handleConfirmUpload(zoneNumber, matchingImage.file)
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="white"
                class="size-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m4.5 12.75 6 6 9-13.5"
                />
              </svg>
            </button>
          </div>
        ) : (
          <div>
            <input
              className="hidden"
              id={`file_input_${index}`}
              type="file"
              accept="image/*"
              onChange={(e) => handleImageChange(e, index)}
            />
            <div
              onClick={() =>
                document.getElementById(`file_input_${index}`).click()
              }
              className="cursor-pointer inline-flex items-center"
            >
              {imagePreviews[index] ? (
                <img
                  src={imagePreviews[index]}
                  alt="Selected"
                  className="w-6 h-6 ml-2"
                />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 ml-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15M9 12l3 3m0 0 3-3m-3 3V2.25"
                  />
                </svg>
              )}
            </div>
          </div>
        )
      ) : null;

      return (
        <div key={index} className="flex justify-between items-center mb-2">
          <h1 className="w-28">{`${displayName}: ${duration} minutes `}</h1>{" "}
          <div className="flex items-center">
            <div>{imageElement}</div>{" "}
          </div>
        </div>
      );
    });
  };

  const handleConfirmUpload = async (zoneNumber, file) => {
    if (!file) return;

    const formData = new FormData();
    formData.append("company_location_device_id", companyLDID); // Assuming you have this ID stored somewhere
    formData.append("zone_number", zoneNumber);
    formData.append("image", file); // Attach the file as 'image'

    // To check the content of formData
    for (const pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    Swal.fire({
      title: "Do you want to add this image??",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await ApiLink.post("/api/upload-image", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          console.log("Image upload successful:", response.data);
          Swal.fire({
            title: `Image upload successful`,
            icon: "success",
            toast: true,
            timer: 4000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        } catch (error) {
          console.error("Error uploading image:", error);
          Swal.fire({
            title: `There was an error uploading the image`,
            icon: "warning",
            toast: true,
            timer: 4000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      }
    });
  };

  const getProgramType = (schedule) => {
    if (schedule.is_regular === 1) return "Regular";
    if (schedule.is_half_sprinkle === 1) return "Half Sprinkle";

    if (schedule.is_half_sprinkle === 1) return "is Day Overlap";

    return "Default";
  };

  const calculateDuration = (start, end) => {
    const startTime = new Date(`2000-01-01T${start}`);
    const endTime = new Date(`2000-01-01T${end}`);
    if (endTime < startTime) endTime.setDate(endTime.getDate() + 1);
    return Math.round((endTime - startTime) / 60000);
  };
  const closeModal = () => {
    setViewImage(false);
  };
  const handleSubmit = () => {
    console.log("Submitting zone data:");
    closeModal();
  };

  const handleSvgClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setFullScreenImage(imageUrl);
    }
  };

  const toggleBodyScroll = (disable) => {
    if (disable) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    toggleBodyScroll(locationDeviceProgramModal);
    return () => toggleBodyScroll(false);
  }, [locationDeviceProgramModal]);

  return (
    <div
      id="defaultModal"
      tabindex="-1"
      aria-hidden="true"
      className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50 font-inter"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      {fullScreenImage && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
          onClick={() => setFullScreenImage(null)}
        >
          <img
            src={fullScreenImage}
            alt="Full screen"
            className="max-h-screen max-w-screen object-contain"
          />
          <div>
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </div>
        </div>
      )}

      <div className="relative p-4 w-full max-w-fit h-full md:h-auto my-8">
        {/* <!-- Modal content --> */}
        <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 overflow-y-auto max-h-[90vh]">
          {/* <!-- Modal header --> */}
          <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              Location Device Programs
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="defaultModal"
              onClick={toggleLocationDeviceProgramModal}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {currentDisplay == "first" && (
            <div className="flex justify-end">
              <button
                className={`px-3 py-1.5 my-2 text-sm rounded-md bg-${color}-200 hover:bg-${color}-400 text-${color}-800 font-semibold`}
                style={{
                  backgroundColor: `${backgroundColor}`,
                  color: `${textColor}`,
                }}
                onClick={() => setAddProgramModal(true)}
              >
                + Add Program
              </button>
            </div>
          )}
          <div className="relative overflow-x-auto mt-4 ">
            {currentDisplay === "first" ? (
              <TableDisplay
                zoneListData={zoneListData}
                color={color}
                backgroundColor={backgroundColor}
                textColor={textColor}
                loading={loading}
                calculateDuration={calculateDuration}
                formatDays={formatDays}
                formatZones={formatZones}
                getProgramType={getProgramType}
                currentDisplay={currentDisplay}
                setCurrentDisplay={setCurrentDisplay}
                setSelectedProgramDetails={setSelectedProgramDetails}
                selectedProgramDetails={selectedProgramDetails}
                uploadedImages={uploadedImages}
                setUploadedImages={setUploadedImages}
                zoneLabels={zoneLabels}
                tableHeaderColor={tableHeaderColor}
                tableHeaderTextColor={tableHeaderTextColor}
                companyLDID={companyLDID}
                email={email}
                password={password}
                setLocationDeviceProgramModal={setLocationDeviceProgramModal}
                fetchCompanyLocationDevice={fetchCompanyLocationDevice}
              />
            ) : (
              <EditDeviceProgram
                toggleLocationDeviceProgramModal={
                  toggleLocationDeviceProgramModal
                }
                zoneListData={zoneListData}
                currentDisplay={currentDisplay}
                setCurrentDisplay={setCurrentDisplay}
                setSelectedProgramDetails={setSelectedProgramDetails}
                selectedProgramDetails={selectedProgramDetails}
                tableHeaderColor={tableHeaderColor}
                backgroundColor={backgroundColor}
                textColor={textColor}
                tableHeaderTextColor={tableHeaderTextColor}
                companyLDID={companyLDID}
                selectedLocation={selectedLocation}
                email={email}
                password={password}
                setLocationDeviceProgramModal={setLocationDeviceProgramModal}
              />
            )}
          </div>
        </div>
      </div>

      {addProgramModal && (
        <AddProgramLocationDevices
          addProgramModal={addProgramModal}
          setAddProgramModal={setAddProgramModal}
          companyLDID={companyLDID}
          loading={loading}
          setLoading={setLoading}
          fetchZoneList={fetchZoneList}
          fetchCompanyLocationDevice={fetchCompanyLocationDevice}
        />
      )}
    </div>
  );
};

export default LocationPrograms;
