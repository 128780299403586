import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../components/context/themeContext/ThemeContext";
import { useSelector } from "react-redux";
import { useSelectedCompany } from "../../../components/context/apiContext/SelectedCompanyContext";
import ApiLink from "../../../components/api/ApiLink";
import { set } from "date-fns";

export default function CompanyType8() {
  const {
    sideBarOpen,
    tableHeaderColor,
    tableHeaderTextColor,
    backgroundColor,
    textColor,
  } = useContext(ThemeContext);

  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);

  const [userListData, setUserListData] = useState([]);

  const { selectedCompanyName } = useSelectedCompany();

  useEffect(() => {}, []);

  const fetchUserList = async () => {
    try {
      const response = await ApiLink.post("/api/user-list", {
        email: email,
        password: password,
        company_id: selectedCompanyName.company_id,
      });

      if (response.status == 200) {
        if (Array.isArray(response.data.Data)) {
          setUserListData(response.data.Data);
        }
      } else {
        setUserListData([]);
      }
    } catch (error) {
      console.error("Error:", error);
      setUserListData([]);
    }
  };

  const [addCompany, setAddCompany] = useState(false);

  const handleAddCompany = () => {};

  return (
    <div
      className={`min-h-screen bg-gray-100 dark:bg-gray-700 transition-all duration-300 p-4 mt-20`}
      style={{ marginLeft: sideBarOpen ? "16rem" : "0" }}
    >
      <div className="p-4 bg-white shadow-md rounded-md dark:bg-gray-800">
        <div className="flex justify-between">
          <div>
            <div className="p-1 mt-1 text-xl font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
              Sustainable Sprinklers Users
            </div>
          </div>
          <button
            className={`px-3 py-1.5 text-sm rounded-md font-semibold`}
            style={{
              backgroundColor: `${backgroundColor}`,
              color: `${textColor}`,
            }}
            onClick={() => setAddCompany(true)}
          >
            + Add User
          </button>
        </div>
        <div className="relative overflow-x-auto mt-4">
          <table className="border border-black dark:border-white animate__animated animate__fadeIn w-full text-sm text-left rtl:text-right font-inter leading-4">
            <thead
              className={`border border-black dark:border-white text-sm uppercase font-inter leading-4`}
              style={{
                backgroundColor: `${tableHeaderColor}`,
                color: `${tableHeaderTextColor}`,
              }}
            >
              <tr>
                <th scope="col" className="px-6 py-3 border border-black">
                  Name/LoginID
                </th>
                <th scope="col" className="px-6 py-3 border border-black">
                  Accounts & Locations
                </th>
                <th scope="col" className="px-6 py-3 border border-black">
                  Date Added
                </th>
                <th scope="col" className="px-6 py-3 border border-black">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 border border-black">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-sm font-inter leading-4 text-black dark:text-white">
              {userListData.map((user) => (
                <tr key={user.usersid} className="odd:bg-white odd:dark:bg-gray-900 even:bg-purple-200 even:dark:bg-gray-800 bg-white dark:bg-gray-800 dark:border-gray-700">
                  <td className="border border-black dark:border-white px-6 py-4">
                    <div>
                      <h1 className="mb-1 hover:underline text-black dark:text-white">
                        <h1 className="flex items-center text-black dark:text-white">
                          <p className="text-black dark:text-white">sample </p>
                        </h1>
                      </h1>

                      <p className="text-black dark:text-white">sample </p>
                    </div>
                  </td>
                  <td className="border border-black dark:border-white px-6 py-4">
                    <div>
                      <p className="mb-2">Location Sample</p>
                      <p className="mb-1">Location sample</p>
                    </div>
                  </td>
                  <td className="border border-black dark:border-white px-6 py-4">
                    09-09-2022
                  </td>
                  <td className="border border-black dark:border-gray-300 px-6 py-4 text-xs flex-col font-semibold text-white leading-4 text-center">
                    <div className="flex justify-start">
                      <div className="p-2 bg-[#74D348] rounded-md">Active</div>
                    </div>
                  </td>
                  <td className="border border-black dark:border-white px-6 py-4">
                    <div className="flex justify-start items-center">
                      <button className="delete p-1 hover:bg-gray-200 rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="red"
                          className="size-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                          />
                        </svg>
                      </button>
                      <button className="edit p-1 hover:bg-gray-200 rounded-full">
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="blue"
                            class="size-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                            />
                          </svg>
                          <h1 className="ml-1 dark:text-white dark:hover:text-black">
                            Reset Pwd
                          </h1>
                        </div>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {addCompany && (
          <div
            id="defaultModal"
            tabindex="-1"
            aria-hidden="true"
            className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full font-inter"
            style={{
              // backdropFilter: "blur(5px)",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="relative p-4 w-full max-w-7xl h-full md:h-auto">
              {/* <!-- Modal content --> */}
              <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                {/* <!-- Modal header --> */}
                <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    Add Sustain Sprinkler User
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="defaultModal"
                    onClick={() => setAddCompany(false)}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                {/* <!-- Modal body --> */}
                <form onSubmit={handleAddCompany}>
                  <div className="grid gap-4 mb-4 sm:grid-cols-2">
                    <div>
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Email Address
                      </label>
                      <input
                        type="text"
                        name="email"
                        id="email"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="friendlyDeviceName"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="phone"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Phone
                      </label>
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="lastName"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-end mt-2">
                    <button
                      className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                      style={{ backgroundColor: "red" }}
                      onClick={() => setAddCompany(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center"
                      style={{ backgroundColor: "#009D01" }}
                    >
                      Add User
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
