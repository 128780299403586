import React, { useState, useContext, useEffect, useCallback } from "react";
import { ThemeContext } from "../../../components/context/themeContext/ThemeContext";
import ApiLink from "../../../components/api/ApiLink";
import { useSelector } from "react-redux";
import { useSelectedCompany } from "../../../components/context/apiContext/SelectedCompanyContext";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import LocationPrograms from "./LocationPrograms";
import LocationRenameZones from "./LocationRenameZones";
import Select from "react-select";

export default function LocationDevices({ companyName }) {
  const swal = require("sweetalert2");

  const [isModalOpen, setIsModeOpen] = useState(false);
  const {
    color,
    textColor,
    backgroundColor,
    tableHeaderColor,
    tableHeaderTextColor,
  } = useContext(ThemeContext);
  const [openPrograms, setOpenPrograms] = useState(false);
  const [getDeviceData, setGetDeviceData] = useState([]);

  const [addDevice, setAddDevice] = useState(false);
  const [editDevice, setEditDevice] = useState(false);

  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);
  const usersid = useSelector((state) => state.auth.usersid);
  const { selectedLocation } = useSelectedCompany();
  const [companyLocationDeviceData, setCompanyLocationDeviceData] = useState(
    []
  );

  const [loading, setLoading] = useState(false);

  const { updateSerialNumber } = useSelectedCompany();

  const [locationDeviceProgramModal, setLocationDeviceProgramModal] =
    useState(false);

  const [companyLDID, setCompanyLDID] = useState(null);

  const [zoneLoading, setZoneLoading] = useState(false);
  const [zoneListData, setZoneListData] = useState({ Programs: {}, Zones: {} });
  const userType = useSelector((state) => state.auth.userType);
  const is_read_only = useSelector((state) => state.auth.is_read_only);
  // const is_read_only = useState(0);
  const [renameLDID, setRenameLDID] = useState(null);

  const [sortByStatus, setSortByStatus] = useState("1");

  const [editDeviceDetails, setEditDeviceDetails] = useState({
    device_identifier: null,
    identifier: null,
    has_pump: null,
    manual_control_start: null,
    manual_control_end: null,
    watering_interval: null,
    yard_guard_drought_days: null,
    station_delay_seconds: null,
    system_time_offset: null,
    current_on_threshold: null,
    time_drift_threshold: null,
    flow_sample_count: null,
    flow_sample_interval: null,
    watered_on_monday: null,
    watered_on_tuesday: null,
    watered_on_wednesday: null,
    watered_on_thursday: null,
    watered_on_friday: null,
    watered_on_saturday: null,
    watered_on_sunday: null,
    timer_type: null,
    has_flow_sensor: null,
    flow_sensor_id: null,

    flow_threshold_min: null,
    flow_threshold_low: null,
    flow_threshold_high: null,
    device_id: null,
    company_location_device_id: null,
    company_location_id: null,
    is_daylight_savings_offset_enabled: null,
  });

  const [addLocationDeviceForm, setAddLocationDeviceForm] = useState({
    device_id: "",
    identifier: "",
    timer_type: "0",
    has_pump: false,
    manual_control_start: "07:00",
    manual_control_end: "19:00",
    watering_interval: "1",
    yard_guard_drought_days: "0",
    is_daylight_savings_offset_enabled: false,
    station_delay_seconds: "0",
    system_time_offset: "0",
    current_on_threshold: "250",
    time_drift_threshold: "0",
    flow_sample_count: "60",
    flow_sample_interval: "60",
    has_flow_sensor: false,
    flow_sensor_id: "",
    flow_threshold_min: "5",
    flow_threshold_low: "20",
    flow_threshold_high: "20",
    watered_on_monday: false,
    watered_on_tuesday: false,
    watered_on_wednesday: false,
    watered_on_thursday: false,
    watered_on_friday: false,
    watered_on_saturday: false,
    watered_on_sunday: false,
  });

  useEffect(() => {
    console.log("Manual Start: ", addLocationDeviceForm.manual_control_start);
    console.log("Manual End: ", addLocationDeviceForm.manual_control_end);
  }, [addLocationDeviceForm]);

  // useEffect(() => {
  //   fetchCompanyLocationDevice();
  // }, [email, password, selectedLocation.locationId, sortByStatus]);

  const fetchCompanyLocationDevice = useCallback(async () => {
    setLoading(true);
    try {
      const response = await ApiLink.post("/api/company-location-device", {
        email: email,
        password: password,
        company_location_id: selectedLocation.locationId,
        status: sortByStatus,
      });
      if (response.status === 200) {
        if (Array.isArray(response.data.Data)) {
          setCompanyLocationDeviceData(response.data.Data);
        } else {
          setCompanyLocationDeviceData([]);
        }
      } else {
        setCompanyLocationDeviceData([]);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  }, [email, password, selectedLocation.locationId, sortByStatus]);
  useEffect(() => {
    fetchCompanyLocationDevice();
  }, [fetchCompanyLocationDevice]);

  useEffect(() => {
    const fetchDeviceList = async () => {
      try {
        const response = await ApiLink.post("/api/get-device", {
          email: email,
          password: password,
          status: 1,
        });
        if (response.status === 200) {
          if (Array.isArray(response.data.Data)) {
            setGetDeviceData(response.data.Data);
          } else {
            setGetDeviceData([]);
          }
        } else {
          setGetDeviceData([]);
        }
      } catch (error) {
        setGetDeviceData([]);
      }
    };

    // Check if user type is not userType4
    if (userType !== 4) {
      fetchDeviceList();
    } else {
      setGetDeviceData([]); // or handle it as needed
    }
  }, [email, password, selectedLocation.locationId, sortByStatus, userType]);

  const toggleAddDevice = () => {
    setAddDevice(true);
  };

  const closeAddDevice = () => {
    setAddDevice(false);
    clearAddDeviceForm();
  };

  const clearAddDeviceForm = () => {
    setAddLocationDeviceForm({
      device_id: "",
      identifier: "",
      timer_type: "0",
      has_pump: false,
      manual_control_start: null,
      manual_control_end: null,
      watering_interval: "0",
      yard_guard_drought_days: "0",
      is_daylight_savings_offset_enabled: false,
      station_delay_seconds: "0",
      system_time_offset: "0",
      current_on_threshold: "10",
      time_drift_threshold: "0",
      flow_sample_count: "0",
      flow_sample_interval: "0",
      has_flow_sensor: false,
      flow_sensor_id: "",
      flow_threshold_min: "0",
      flow_threshold_low: "0",
      flow_threshold_high: "0",
      watered_on_monday: false,
      watered_on_tuesday: false,
      watered_on_wednesday: false,
      watered_on_thursday: false,
      watered_on_friday: false,
      watered_on_saturday: false,
      watered_on_sunday: false,
    });
  };

  const handleAddDeviceSubmit = (e) => {
    e.preventDefault();
    console.log("Device ID: ", addLocationDeviceForm.device_id);
    console.log("Box Identifier: ", addLocationDeviceForm.identifier);
    console.log("Timer Type: ", addLocationDeviceForm.timer_type);
    console.log("Has Pump: ", addLocationDeviceForm.has_pump === true ? 1 : 0);
    console.log(
      "Manual Control Start: ",
      addLocationDeviceForm.manual_control_start
    );
    console.log(
      "Manual Control End: ",
      addLocationDeviceForm.manual_control_end
    );
    console.log("Watering Interval: ", addLocationDeviceForm.watering_interval);
    console.log(
      "Yard Guard Drought Days: ",
      addLocationDeviceForm.yard_guard_drought_days
    );
    console.log(
      "is_daylight_savings_offset_enabled: ",
      addLocationDeviceForm.is_daylight_savings_offset_enabled === true ? 1 : 0
    );
    console.log(
      "Station Delay Seconds: ",
      addLocationDeviceForm.station_delay_seconds
    );
    console.log(
      "System Time Offset: ",
      addLocationDeviceForm.system_time_offset
    );
    console.log(
      "Current On Threshold: ",
      addLocationDeviceForm.current_on_threshold
    );
    console.log(
      "Time Drift Threshold: ",
      addLocationDeviceForm.time_drift_threshold
    );
    console.log("flow_sample_count: ", addLocationDeviceForm.flow_sample_count);
    console.log(
      "flow_sample_interval: ",
      addLocationDeviceForm.flow_sample_interval
    );
    console.log(
      "has_flow_sensor: ",
      addLocationDeviceForm.has_flow_sensor === true ? 1 : 0
    );
    console.log("flow_sensor_id: ", addLocationDeviceForm.flow_sensor_id);
    console.log(
      "flow_threshold_min: ",
      addLocationDeviceForm.flow_threshold_min
    );
    console.log(
      "flow_threshold_low: ",
      addLocationDeviceForm.flow_threshold_low
    );
    console.log(
      "flow_threshold_high: ",
      addLocationDeviceForm.flow_threshold_high
    );
    console.log(
      "watered_on_monday: ",
      addLocationDeviceForm.watered_on_monday === true ? 1 : 0
    );
    console.log(
      "watered_on_tuesday: ",
      addLocationDeviceForm.watered_on_tuesday === true ? 1 : 0
    );
    console.log(
      "watered_on_wednesday: ",
      addLocationDeviceForm.watered_on_wednesday === true ? 1 : 0
    );
    console.log(
      "watered_on_thursday: ",
      addLocationDeviceForm.watered_on_thursday === true ? 1 : 0
    );
    console.log(
      "watered_on_friday: ",
      addLocationDeviceForm.watered_on_friday === true ? 1 : 0
    );
    console.log(
      "watered_on_saturday: ",
      addLocationDeviceForm.watered_on_saturday === true ? 1 : 0
    );
    console.log(
      "watered_on_sunday: ",
      addLocationDeviceForm.watered_on_sunday === true ? 1 : 0
    );

    swal
      .fire({
        title: "Do you want to add this location device?",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#dc3545",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiLink.post(
              "/api/add-company-location-device",
              {
                email: email,
                password: password,
                company_id: selectedLocation.companyId,
                company_location_id: selectedLocation.locationId,
                device_id: addLocationDeviceForm.device_id,
                identifier: addLocationDeviceForm.identifier,
                timer_type: addLocationDeviceForm.timer_type,
                has_pump: addLocationDeviceForm.has_pump === true ? 1 : 0,
                manual_control_start:
                  addLocationDeviceForm.manual_control_start,
                manual_control_end: addLocationDeviceForm.manual_control_end,
                watering_interval: addLocationDeviceForm.watering_interval,
                yard_guard_drought_days:
                  addLocationDeviceForm.yard_guard_drought_days,
                is_daylight_savings_offset_enabled:
                  addLocationDeviceForm.is_daylight_savings_offset_enabled ===
                  true
                    ? 1
                    : 0,
                station_delay_seconds:
                  addLocationDeviceForm.station_delay_seconds,
                system_time_offset: addLocationDeviceForm.system_time_offset,
                current_on_threshold:
                  addLocationDeviceForm.current_on_threshold,
                time_drift_threshold:
                  addLocationDeviceForm.time_drift_threshold,
                flow_sample_count: addLocationDeviceForm.flow_sample_count,
                flow_sample_interval:
                  addLocationDeviceForm.flow_sample_interval,
                has_flow_sensor:
                  addLocationDeviceForm.has_flow_sensor === true ? 1 : 0,
                flow_sensor_id: addLocationDeviceForm.flow_sensor_id,
                flow_threshold_min: addLocationDeviceForm.flow_threshold_min,
                flow_threshold_low: addLocationDeviceForm.flow_threshold_low,
                flow_threshold_high: addLocationDeviceForm.flow_threshold_high,
                watered_on_monday:
                  addLocationDeviceForm.watered_on_monday === true ? 1 : 0,
                watered_on_tuesday:
                  addLocationDeviceForm.watered_on_tuesday === true ? 1 : 0,
                watered_on_wednesday:
                  addLocationDeviceForm.watered_on_wednesday === true ? 1 : 0,
                watered_on_thursday:
                  addLocationDeviceForm.watered_on_thursday === true ? 1 : 0,
                watered_on_friday:
                  addLocationDeviceForm.watered_on_friday === true ? 1 : 0,
                watered_on_saturday:
                  addLocationDeviceForm.watered_on_saturday === true ? 1 : 0,
                watered_on_sunday:
                  addLocationDeviceForm.watered_on_sunday === true ? 1 : 0,
              }
            );

            if (response.status === 200 || response.data.Status === 200) {
              swal.fire({
                title: "Location Device Added Successfully",
                icon: "success",
                toast: true,
                timer: 4000,
                position: "top-right",
                timerProgressBar: true,
                showConfirmButton: false,
              });

              const addedDevice = response.data.Data.data; // Assuming the first item in the array is the added device
              const primaryKey = response.data.Data.primary_key;
              console.log("Added Device Primary Key: ", primaryKey);
              const section = "sprinkler-location-devices"; // You might need to determine this based on your application logic

              try {
                const zohoResponse = await ApiLink.post(
                  "/api/zoho-crm-add-device",
                  {
                    section: "sprinkler-location-devices",
                    data: {
                      device_id: addedDevice.device_id.toString(),
                      identifier: addedDevice.identifier,
                      timer_type: addedDevice.timer_type.toString(),
                      has_pump: addedDevice.has_pump.toString(),
                      manual_control_start:
                        addedDevice.manual_control_start.slice(0, 5),
                      manual_control_end: addedDevice.manual_control_end.slice(
                        0,
                        5
                      ),
                      watering_interval:
                        addedDevice.watering_interval.toString(),
                      yard_guard_drought_days:
                        addedDevice.yard_guard_drought_days.toString(),
                      is_daylight_savings_offset_enabled:
                        addedDevice.is_daylight_savings_offset_enabled.toString(),
                      station_delay_seconds:
                        addedDevice.station_delay_seconds.toString(),
                      system_time_offset:
                        addedDevice.system_time_offset.toString(),
                      current_on_threshold:
                        addedDevice.current_on_threshold.toString(),
                      time_drift_threshold:
                        addedDevice.time_drift_threshold.toString(),
                      flow_sample_count:
                        addedDevice.flow_sample_count.toString(),
                      flow_sample_interval:
                        addedDevice.flow_sample_interval.toString(),
                      has_flow_sensor: addedDevice.has_flow_sensor.toString(),
                      flow_sensor_id: addedDevice.flow_sensor_id
                        ? addedDevice.flow_sensor_id.toString()
                        : "",
                      flow_threshold_min:
                        addedDevice.flow_threshold_min.toString(),
                      flow_threshold_low:
                        addedDevice.flow_threshold_low.toString(),
                      flow_threshold_high:
                        addedDevice.flow_threshold_high.toString(),
                      watered_on_monday:
                        addedDevice.watered_on_monday.toString(),
                      watered_on_tuesday:
                        addedDevice.watered_on_tuesday.toString(),
                      watered_on_wednesday:
                        addedDevice.watered_on_wednesday.toString(),
                      watered_on_thursday:
                        addedDevice.watered_on_thursday.toString(),
                      watered_on_friday:
                        addedDevice.watered_on_friday.toString(),
                      watered_on_saturday:
                        addedDevice.watered_on_saturday.toString(),
                      watered_on_sunday:
                        addedDevice.watered_on_sunday.toString(),
                      company_id: selectedLocation.companyId.toString(),
                      company_location_id:
                        selectedLocation.locationId.toString(),
                    },
                    primary_key: primaryKey, //addedDevice.primary_key.toString(),
                  }
                );
                swal.fire({
                  title: "Location Device Added to Zoho CRM Successfully",
                  icon: "success",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
                toggleAddDevice();
                clearAddDeviceForm();
                setAddDevice(false);
                fetchCompanyLocationDevice();
                console.log("Zoho Response: ", zohoResponse.data.data[0].code);
                if (zohoResponse.data.data[0].code === "SUCCESS") {
                  swal.fire({
                    title: "Location Device Added to Zoho CRM Successfully",
                    icon: "success",
                    toast: true,
                    timer: 4000,
                    position: "top-right",
                    timerProgressBar: true,
                    showConfirmButton: false,
                  });
                  toggleAddDevice();
                  clearAddDeviceForm();
                  setAddDevice(false);
                  fetchCompanyLocationDevice();
                } else {
                  throw new Error("Zoho CRM add failed");
                }
              } catch (error) {
                console.error("Error updating Zoho CRM:", error);
                swal.fire({
                  title: "Error adding Zoho CRM",
                  text: "Device added locally but failed to update Zoho CRM",
                  icon: "warning",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
              }
            } else {
              // Handle error cases
              const errorMessage =
                response.data.Message || "Unexpected error occurred";
              swal.fire({
                title: "Error Occurred",
                text: errorMessage,
                icon: "error",
                toast: true,
                timer: 4000,
                position: "top-right",
                timerProgressBar: true,
                showConfirmButton: false,
              });
            }
          } catch (error) {
            console.error("Error adding location device:", error);
            swal.fire({
              title: "Error Occurred",
              text: "Failed to add location device",
              icon: "error",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        }
      });
  };

  const getColorCode = (color) => {
    switch (color) {
      case "blue":
        return "#1c64f2";
      case "green":
        return "#057a55";
      case "purple":
        return "#7e3af2";
      case "red":
        return "#FF0000";
      case "yellow":
        return "#9F580A";
      case "pink":
        return "#D61F69";
      default:
        return "#057a55";
    }
  };

  const fetchZoneList = async (company_location_device_id) => {
    setZoneLoading(true);
    try {
      const response = await ApiLink.post("/api/zone-list", {
        email: email,
        password: password,
        company_location_device_id: company_location_device_id,
      });

      if (response.status === 200 && response.data.Data) {
        setZoneListData(response.data.Data);
        console.log("Zone List Data: ", response.data.Data);
      }
    } catch (error) {
      console.error("Error fetching zone list:", error);
    } finally {
      setZoneLoading(false);
    }
  };

  const toggleBodyScroll = (disable) => {
    if (disable) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    toggleBodyScroll(
      isModalOpen || addDevice || editDevice || locationDeviceProgramModal
    );
    return () => toggleBodyScroll(false);
  }, [isModalOpen, addDevice, editDevice, locationDeviceProgramModal]);

  const toggleLocationDeviceProgramModal = (company_location_device_id) => {
    setLocationDeviceProgramModal(!locationDeviceProgramModal);
    setCompanyLDID(company_location_device_id);
  };

  const toggleModal = (company_location_device_id) => {
    setIsModeOpen(true);
    console.log("Clicked LDID: ", company_location_device_id);
    setRenameLDID(company_location_device_id);

    if (company_location_device_id) {
      fetchZoneList(company_location_device_id);
    }
  };

  const formatTime = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(":");
    const date = new Date(0, 0, 0, hours, minutes, seconds);
    let formattedTime = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    // Remove leading zero from hour and remove space in AM/PM
    formattedTime = formattedTime.replace(/^0/, "").replace(" ", " ");

    return formattedTime;
  };

  const formatDateTime = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDate = new Date(dateTimeString).toLocaleString(
      "en-US",
      options
    );
    return formattedDate.replace(",", "");
  };

  const handleSerialNumberClick = (deviceId) => {
    updateSerialNumber({ deviceId });
  };

  const handleZoneChange = (program, zoneName, value) => {
    setZoneListData((prevData) => ({
      ...prevData,
      Zones: {
        ...prevData.Zones,
        [program]: prevData.Zones[program].map((zone) =>
          Object.keys(zone)[0] === zoneName ? { [zoneName]: value } : zone
        ),
      },
    }));
  };

  const toggleEditDevice = (
    device_identifier,
    identifier,
    has_pump,
    manual_control_start,
    manual_control_end,
    watering_interval,
    yard_guard_drought_days,
    station_delay_seconds,
    system_time_offset,
    current_on_threshold,
    time_drift_threshold,
    flow_sample_count,
    flow_sample_interval,
    watered_on_monday,
    watered_on_tuesday,
    watered_on_wednesday,
    watered_on_thursday,
    watered_on_friday,
    watered_on_saturday,
    watered_on_sunday,
    timer_type,
    has_flow_sensor,
    flow_sensor_id,
    flow_threshold_min,
    flow_threshold_low,
    flow_threshold_high,
    device_id,
    company_location_device_id,
    company_location_id,
    is_daylight_savings_offset_enabled
  ) => {
    setEditDevice(true);
    console.log("Device Identifier: ", device_identifier);
    console.log("Identifier: ", identifier);
    console.log("Has Pump: ", has_pump);
    console.log("Manual Control Start: ", manual_control_start);
    console.log("Manual Control End: ", manual_control_end);
    console.log("Watering Interval: ", watering_interval);
    console.log("Yard Guard Drought Days: ", yard_guard_drought_days);
    console.log("Station Delay Seconds: ", station_delay_seconds);
    console.log("System Time Offset: ", system_time_offset);
    console.log("Current On Threshold: ", current_on_threshold);
    console.log("Time Drift Threshold: ", time_drift_threshold);
    console.log("Flow Sample Count: ", flow_sample_count);
    console.log("Flow Sample Interval: ", flow_sample_interval);
    console.log("Watered On Monday: ", watered_on_monday);
    console.log("Watered On Tuesday: ", watered_on_tuesday);
    console.log("Watered On Wednesday: ", watered_on_wednesday);
    console.log("Watered On Thursday: ", watered_on_thursday);
    console.log("Watered On Friday: ", watered_on_friday);
    console.log("Watered On Saturday: ", watered_on_saturday);
    console.log("Watered On Sunday: ", watered_on_sunday);
    console.log("Timer type: ", timer_type);

    console.log("Has Flow Sensor: ", has_flow_sensor);
    console.log("Flow Sensor ID: ", flow_sensor_id);
    console.log("Flow Threshold Min: ", flow_threshold_min);
    console.log("Flow Threshold Low: ", flow_threshold_low);
    console.log("Flow Threshold High: ", flow_threshold_high);

    console.log("Device ID: ", device_id);
    console.log("Company Location Device ID: ", company_location_device_id);
    console.log("Company Location ID: ", company_location_id);
    console.log(
      "is_daylight_savings_offset_enabled: ",
      is_daylight_savings_offset_enabled
    );

    setEditDeviceDetails({
      device_identifier: device_identifier,
      identifier: identifier,
      has_pump: has_pump,
      manual_control_start: manual_control_start,
      manual_control_end: manual_control_end,
      watering_interval: watering_interval,
      yard_guard_drought_days: yard_guard_drought_days,
      station_delay_seconds: station_delay_seconds,
      system_time_offset: system_time_offset,
      current_on_threshold: current_on_threshold,
      time_drift_threshold: time_drift_threshold,
      flow_sample_count: flow_sample_count,
      flow_sample_interval: flow_sample_interval,
      watered_on_monday: watered_on_monday,
      watered_on_tuesday: watered_on_tuesday,
      watered_on_wednesday: watered_on_wednesday,
      watered_on_thursday: watered_on_thursday,
      watered_on_friday: watered_on_friday,
      watered_on_saturday: watered_on_saturday,
      watered_on_sunday: watered_on_sunday,
      timer_type: timer_type,
      has_flow_sensor: has_flow_sensor,
      flow_sensor_id: flow_sensor_id,
      flow_threshold_min: flow_threshold_min,
      flow_threshold_low: flow_threshold_low,
      flow_threshold_high: flow_threshold_high,
      device_id: device_id,
      company_location_device_id: company_location_device_id,
      company_location_id: company_location_id,
      is_daylight_savings_offset_enabled: is_daylight_savings_offset_enabled,
    });
  };

  const clearEditDeviceForm = () => {
    console.log("clearing edit device form");
    setEditDeviceDetails({
      device_identifier: null,
      identifier: null,
      has_pump: null,
      manual_control_start: null,
      manual_control_end: null,
      watering_interval: null,
      yard_guard_drought_days: null,
      station_delay_seconds: null,
      system_time_offset: null,
      current_on_threshold: null,
      time_drift_threshold: null,
      flow_sample_count: null,
      flow_sample_interval: null,
      watered_on_monday: null,
      watered_on_tuesday: null,
      watered_on_wednesday: null,
      watered_on_thursday: null,
      watered_on_friday: null,
      watered_on_saturday: null,
      watered_on_sunday: null,
      timer_type: null,
      has_flow_sensor: null,
      flow_sensor_id: "",

      flow_threshold_min: null,
      flow_threshold_low: null,
      flow_threshold_high: null,
      device_id: null,
      company_location_device_id: null,
      company_location_id: null,
      is_daylight_savings_offset_enabled: null,
    });
  };

  const closeEditDevice = () => {
    setEditDevice(false);
    clearEditDeviceForm();
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    console.log("CLICKED EDIT SUBMIT");
    console.log("device identifier: ", editDeviceDetails.device_identifier);
    console.log("identifier: ", editDeviceDetails.identifier);
    console.log("has pump: ", editDeviceDetails.has_pump);
    console.log(
      "manual control start: ",
      editDeviceDetails.manual_control_start
    );
    console.log("manual control end: ", editDeviceDetails.manual_control_end);
    console.log("watering interval: ", editDeviceDetails.watering_interval);
    console.log(
      "yard guard drought days: ",
      editDeviceDetails.yard_guard_drought_days
    );
    console.log(
      "station delay seconds: ",
      editDeviceDetails.station_delay_seconds
    );
    console.log("system time offset: ", editDeviceDetails.system_time_offset);
    console.log(
      "current on threshold: ",
      editDeviceDetails.current_on_threshold
    );
    console.log(
      "time drift threshold: ",
      editDeviceDetails.time_drift_threshold
    );
    console.log("flow sample count: ", editDeviceDetails.flow_sample_count);
    console.log(
      "flow sample interval: ",
      editDeviceDetails.flow_sample_interval
    );
    console.log(
      "watered on monday: ",
      editDeviceDetails.watered_on_monday === true ? 1 : 0
    );
    console.log(
      "watered on tuesday: ",
      editDeviceDetails.watered_on_tuesday === true ? 1 : 0
    );
    console.log(
      "watered on wednesday: ",
      editDeviceDetails.watered_on_wednesday === true ? 1 : 0
    );
    console.log(
      "watered on thursday: ",
      editDeviceDetails.watered_on_thursday === true ? 1 : 0
    );
    console.log(
      "watered on friday: ",
      editDeviceDetails.watered_on_friday === true ? 1 : 0
    );
    console.log(
      "watered on saturday: ",
      editDeviceDetails.watered_on_saturday === true ? 1 : 0
    );
    console.log(
      "watered on sunday: ",
      editDeviceDetails.watered_on_sunday === true ? 1 : 0
    );
    console.log("timer type: ", editDeviceDetails.timer_type);
    console.log(
      "has flow sensor: ",
      editDeviceDetails.has_flow_sensor === true ? 1 : 0
    );
    console.log("flow sensor id: ", editDeviceDetails.flow_sensor_id);
    console.log("flow threshold min: ", editDeviceDetails.flow_threshold_min);
    console.log("flow threshold low: ", editDeviceDetails.flow_threshold_low);
    console.log("flow threshold high: ", editDeviceDetails.flow_threshold_high);
    console.log("device id: ", editDeviceDetails.device_id);
    console.log(
      "company location device id: ",
      editDeviceDetails.company_location_device_id
    );

    console.log("company location id: ", editDeviceDetails.company_location_id);
    swal
      .fire({
        title: "Do you want to update this location device?",
        showCancelButton: true,
        confirmButtonText: "Update",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#FF0000",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiLink.post("/api/update-location-device", {
              email: email,
              password: password,
              usersid: usersid,
              company_id: selectedLocation.companyId,
              recordid: editDeviceDetails.company_location_device_id,
              company_location_id: editDeviceDetails.company_location_id,
              company_location_device_id:
                editDeviceDetails.company_location_device_id,
              device_id: editDeviceDetails.device_id,
              identifier: editDeviceDetails.identifier,
              timer_type: editDeviceDetails.timer_type,
              has_pump: editDeviceDetails.has_pump,
              manual_control_start: editDeviceDetails.manual_control_start,
              manual_control_end: editDeviceDetails.manual_control_end,
              watering_interval: editDeviceDetails.watering_interval,
              yard_guard_drought_days:
                editDeviceDetails.yard_guard_drought_days,
              is_daylight_savings_offset_enabled:
                editDeviceDetails.is_daylight_savings_offset_enabled,
              station_delay_seconds: editDeviceDetails.station_delay_seconds,
              system_time_offset: editDeviceDetails.system_time_offset,
              current_on_threshold: editDeviceDetails.current_on_threshold,
              time_drift_threshold: editDeviceDetails.time_drift_threshold,
              flow_sample_count: editDeviceDetails.flow_sample_count,
              flow_sample_interval: editDeviceDetails.flow_sample_interval,
              has_flow_sensor:
                editDeviceDetails.has_flow_sensor === true ? 1 : 0,
              flow_sensor_id: editDeviceDetails.flow_sensor_id,
              flow_threshold_min: editDeviceDetails.flow_threshold_min,
              flow_threshold_low: editDeviceDetails.flow_threshold_low,
              flow_threshold_high: editDeviceDetails.flow_threshold_high,
              watered_on_monday: editDeviceDetails.watered_on_monday ? 1 : 0,
              watered_on_tuesday: editDeviceDetails.watered_on_tuesday ? 1 : 0,
              watered_on_wednesday: editDeviceDetails.watered_on_wednesday
                ? 1
                : 0,
              watered_on_thursday: editDeviceDetails.watered_on_thursday
                ? 1
                : 0,
              watered_on_friday: editDeviceDetails.watered_on_friday ? 1 : 0,
              watered_on_saturday: editDeviceDetails.watered_on_saturday
                ? 1
                : 0,
              watered_on_sunday: editDeviceDetails.watered_on_sunday ? 1 : 0,
            });
            if (response.status === 200) {
              swal.fire({
                title: "Location Device Updated Successfully",
                icon: "success",
                toast: true,
                timer: 4000,
                position: "top-right",
                timerProgressBar: true,
                showConfirmButton: false,
              });
              fetchCompanyLocationDevice();
              //closeEditDevice();
              console.log("Response: ", response.data.Data);
              const payload = {
                device_id: editDeviceDetails.device_id.toString(),
                identifier: editDeviceDetails.identifier,
                timer_type: editDeviceDetails.timer_type,
                has_pump: editDeviceDetails.has_pump,
                manual_control_start:
                  editDeviceDetails.manual_control_start.slice(0, 5),
                manual_control_end: editDeviceDetails.manual_control_end.slice(
                  0,
                  5
                ),
                watering_interval: editDeviceDetails.watering_interval,
                yard_guard_drought_days:
                  editDeviceDetails.yard_guard_drought_days,
                is_daylight_savings_offset_enabled:
                  editDeviceDetails.is_daylight_savings_offset_enabled,
                station_delay_seconds: editDeviceDetails.station_delay_seconds,
                system_time_offset: editDeviceDetails.system_time_offset,
                current_on_threshold:
                  editDeviceDetails.current_on_threshold.toString(),
                time_drift_threshold:
                  editDeviceDetails.time_drift_threshold.toString(),
                flow_sample_count:
                  editDeviceDetails.flow_sample_count.toString(),
                flow_sample_interval:
                  editDeviceDetails.flow_sample_interval.toString(),
                has_flow_sensor: editDeviceDetails.has_flow_sensor,
                flow_sensor_id: editDeviceDetails.flow_sensor_id,
                flow_threshold_min:
                  editDeviceDetails.flow_threshold_min.toString(),
                flow_threshold_low:
                  editDeviceDetails.flow_threshold_low.toString(),
                flow_threshold_high:
                  editDeviceDetails.flow_threshold_high.toString(),
                watered_on_monday: editDeviceDetails.watered_on_monday,
                watered_on_tuesday: editDeviceDetails.watered_on_tuesday,
                watered_on_wednesday: editDeviceDetails.watered_on_wednesday,
                watered_on_thursday: editDeviceDetails.watered_on_thursday,
                watered_on_friday: editDeviceDetails.watered_on_friday,
                watered_on_saturday: editDeviceDetails.watered_on_saturday,
                watered_on_sunday: editDeviceDetails.watered_on_sunday,
                company_location_device_id:
                  editDeviceDetails.company_location_device_id,
                recordid:
                  editDeviceDetails.company_location_device_id.toString(),
              };
              console.log(
                "Zoho CRM Update Payload:",
                JSON.stringify(payload, null, 2)
              );
              if (response.data.Data === 1) {
                try {
                  const zohoResponse = await ApiLink.post(
                    "/api/zoho-crm-update-location-device",
                    payload
                  );
                  console.log(
                    "Zoho Response: ",
                    zohoResponse.data["Location Device"].data[0].code
                  );
                  swal.fire({
                    title: "Location Device Updated Successfully",
                    icon: "success",
                    toast: true,
                    timer: 4000,
                    position: "top-right",
                    timerProgressBar: true,
                    showConfirmButton: false,
                  });
                  fetchCompanyLocationDevice();
                  closeEditDevice();

                  if (
                    zohoResponse.data["Location Device"].data[0].code ===
                    "SUCCESS"
                  ) {
                    swal.fire({
                      title: "Location Device Updated Successfully",
                      icon: "success",
                      toast: true,
                      timer: 4000,
                      position: "top-right",
                      timerProgressBar: true,
                      showConfirmButton: false,
                    });
                    fetchCompanyLocationDevice();
                    closeEditDevice();
                  }
                } catch (error) {
                  console.log("Error: ", error);
                }
              }
            } else if (response.data.Data.is_valid === false) {
              // Extract error messages from the object
              const errorMessages = Object.entries(response.data.Data.errors)
                .map(([field, messages]) => `${messages.join(", ")}`)
                .join("\n");

              swal.fire({
                title: errorMessages,

                icon: "error",
                toast: true,
                timer: 4000,
                position: "top-right",
                timerProgressBar: true,
                showConfirmButton: false,
              });
            }
          } catch (error) {
            swal.fire({
              title: "Error Occured. ",
              icon: "error",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        }
      });

    // closeEditDevice();
  };

  const closeModal = () => {
    setIsModeOpen(false);
  };
  const handleSubmit = () => {
    console.log("Submitting zone data:");
    closeModal();
  };

  const generateTimeOptions = () => {
    const options = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 4; j++) {
        // 15-minute intervals
        const hour = i.toString().padStart(2, "0");
        const minute = (j * 15).toString().padStart(2, "0");
        const ampm = i < 12 ? "am" : "pm";
        const time = `${i === 0 ? 12 : i > 12 ? i - 12 : i}:${minute}${ampm}`;
        const value24h = `${(i % 24).toString().padStart(2, "0")}:${minute}`; // Add seconds ":00"
        options.push(
          <option key={time} value={value24h}>
            {time}
          </option>
        );
      }
    }
    return options;
  };

  const handleDeleteDevice = (
    company_location_device_id,
    device_identifier
  ) => {
    swal
      .fire({
        title: "Do you want to delete this company location device?",
        text: `"${device_identifier}" will be deleted permanently.`,
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#FF0000",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiLink.post("/api/delete-location-device", {
              email: email,
              password: password,
              company_location_device_id: company_location_device_id,
            });

            if (response.status === 200) {
              console.log(response.data);
              if (response.data.Data.is_valid === false) {
                swal.fire({
                  title: "Error Occured. Please try again.",
                  icon: "warning",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
              } else if (response.data.Data.is_valid === true) {
                swal.fire({
                  title: "Company Location Device Deleted Successfully",
                  icon: "success",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
                fetchCompanyLocationDevice();
              }
            }
          } catch (error) {}
        }
      });
  };

  const handleRestoreDevice = (
    company_location_device_id,
    device_identifier
  ) => {
    swal
      .fire({
        title: "Do you want to restore/enabled this company location device?",
        text: `"${device_identifier}" will be restored.`,
        showCancelButton: true,
        confirmButtonText: "Restore",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#FF0000",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiLink.post(
              "/api/restore-location-device",
              {
                email: email,
                password: password,
                company_location_device_id: company_location_device_id,
              }
            );

            if (response.status === 200) {
              console.log(response.data);
              if (response.data.Data.is_valid === false) {
                swal.fire({
                  title: "Error Occurred. Please try again.",
                  icon: "warning",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
              } else if (response.data.Data.is_valid === true) {
                swal.fire({
                  title: "Company Location Device Restored Successfully",
                  icon: "success",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
                fetchCompanyLocationDevice();
              }
            }
          } catch (error) {}
        }
      });
  };
  // Add Device
  const filteredDeviceData = getDeviceData.filter(
    (device) => device.location_name === null
  );

  const deviceOptions = filteredDeviceData.map((device) => ({
    value: device.device_id,
    label: device.serial_number + " | " + device.device_identifier,
    serial_number: device.serial_number,
  }));

  const handleDeviceChange = (selectedOption) => {
    setAddLocationDeviceForm((prevForm) => ({
      ...prevForm,
      device_id: selectedOption ? selectedOption.value : "",
    }));
  };

  const customFilter = (option, searchText) => {
    if (!searchText) return true;
    return option.data.serial_number
      .toLowerCase()
      .includes(searchText.toLowerCase());
  };
  //edit device

  return (
    <div className="font-inter bg-white dark:text-white dark:bg-gray-800 h-screen">
      <div className="w-40">
        <label
          htmlFor="status"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Status
        </label>
        <select
          id="status"
          value={sortByStatus}
          onChange={(e) => setSortByStatus(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="1">Enabled</option>
          <option value="2">Pending</option>
          <option value="3">Deleted</option>
        </select>
      </div>
      <div className="flex justify-between mb-1">
        <div className="text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
          <h1 className="p-1">Location Devices</h1>
        </div>
        {userType !== 2 && userType !== 4 && is_read_only !== 1 && (
          <button
            className={`px-3 py-1.5 text-sm rounded-md bg-${color}-200 hover:bg-${color}-400 text-${color}-800 font-semibold`}
            style={{
              backgroundColor: `${backgroundColor}`,
              color: `${textColor}`,
            }}
            onClick={toggleAddDevice}
          >
            + Add Device
          </button>
        )}
      </div>
      <div className="flex flex-col relative overflow-x-auto animate__animated animate__fadeIn">
        <table className="w-full cursor-pointer text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead
            className={`text-sm uppercase leading-4`}
            style={{
              backgroundColor: `${tableHeaderColor}`,
              color: `${tableHeaderTextColor}`,
            }}
          >
            <tr>
              <th scope="col" className="px-6 py-3 border border-black">
                Serial Number
              </th>
              <th scope="col" className="px-6 py-3 border border-black">
                Manual Control
              </th>
              <th scope="col" className="px-6 py-3 border border-black">
                Options
              </th>
              <th scope="col" className="px-6 py-3 border border-black">
                Last heartbeat
              </th>
              <th scope="col" className="px-6 py-3 border border-black">
                Programs
              </th>
              <th scope="col" className="px-6 py-3 border border-black">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="text-sm text-black dark:text-white leading-4">
            {loading ? (
              <tr>
                <td colSpan="7" className="h-64">
                  <div
                    role="status"
                    className="flex justify-center items-center h-full"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
            ) : companyLocationDeviceData &&
              companyLocationDeviceData.length > 0 ? (
              companyLocationDeviceData.map((locationDevice) => (
                <tr
                  key={locationDevice.company_location_device_id}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white odd:dark:bg-gray-900 even:bg-purple-200 even:dark:bg-gray-800"
                >
                  <td className="border border-black dark:border-white px-6 py-4">
                    <div className="flex justify-between ">
                      <div className="flex flex-col text-left">
                        {userType === 1 ? (
                          <Link to={`/devices/${locationDevice.device_id}`}>
                            <h1
                              className="hover:underline mr-5 font-semibold"
                              onClick={() =>
                                handleSerialNumberClick(
                                  locationDevice.device_id
                                )
                              }
                            >
                              {locationDevice.serial_number}
                            </h1>
                          </Link>
                        ) : (
                          <span className="mr-5 font-semibold">
                            {locationDevice.serial_number}
                          </span>
                        )}
                        <h1 className="">{locationDevice.device_identifier}</h1>
                      </div>
                      <div className="">
                        <h1
                          className={`bg-gray-600 text-center text-white flex font-semibold p-1 mt-2 text-xs w-fit rounded-sm `}
                        >
                          {locationDevice.identifier}
                        </h1>
                      </div>
                    </div>
                  </td>
                  <td className="border border-black dark:border-white px-6 py-4">
                    {formatTime(locationDevice.manual_control_start)} -{" "}
                    {formatTime(locationDevice.manual_control_end)}
                  </td>
                  <td className="border border-black dark:border-white px-6 py-4">
                    <div className="flex items-center gap-2">
                      {locationDevice.has_flow_sensor == 1 ? (
                        <div
                          className="p-1 w-fit text-sm font-semibold text-white"
                          style={{ backgroundColor: "#5BC0DE" }}
                        >
                          FS
                        </div>
                      ) : (
                        ""
                      )}
                      {locationDevice.timer_type === 1 && (
                        <div
                          className="p-1 w-fit text-xs font-semibold text-white"
                          style={{ backgroundColor: "#FFB606" }}
                        >
                          Indexing Value
                        </div>
                      )}

                      {locationDevice.timer_type === 0 && (
                        <div
                          className="p-1 w-fit text-xs font-semibold text-white"
                          style={{ backgroundColor: "#FFB606" }}
                        >
                          Digital Timer
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="border border-black dark:border-white px-6 py-4">
                    {formatDateTime(locationDevice.last_hb)}
                  </td>

                  <td className="border border-black dark:border-white px-6 py-4">
                    <div
                      className="hover:text-gray-800 dark:hover:text-white"
                      onClick={() =>
                        toggleLocationDeviceProgramModal(
                          locationDevice.company_location_device_id
                        )
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="size-8 text-gray-500 dark:hover:text-white"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25"
                        />
                      </svg>
                    </div>
                  </td>

                  <td className="border border-black dark:border-white px-4 py-2 md:z-50">
                    <div className="flex items-center justify-around w-full">
                      {is_read_only !== 1 && (
                        <button
                          onClick={() =>
                            toggleModal(
                              locationDevice.company_location_device_id
                            )
                          }
                          className="editZones p-1 hover:bg-white dark:hover:bg-gray-200 rounded-full"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="green"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z"
                            />
                          </svg>
                        </button>
                      )}
                      {userType !== 2 &&
                        userType !== 4 &&
                        is_read_only !== 1 && (
                          <button
                            className="editDevice p-1 hover:bg-white dark:hover:bg-gray-200 rounded-full"
                            onClick={() =>
                              toggleEditDevice(
                                locationDevice.device_identifier,
                                locationDevice.identifier,
                                locationDevice.has_pump,
                                locationDevice.manual_control_start,
                                locationDevice.manual_control_end,
                                locationDevice.watering_interval,
                                locationDevice.yard_guard_drought_days,
                                locationDevice.station_delay_seconds,
                                locationDevice.system_time_offset,
                                locationDevice.current_on_threshold,
                                locationDevice.time_drift_threshold,
                                locationDevice.flow_sample_count,
                                locationDevice.flow_sample_interval,
                                locationDevice.watered_on_monday,
                                locationDevice.watered_on_tuesday,
                                locationDevice.watered_on_wednesday,
                                locationDevice.watered_on_thursday,
                                locationDevice.watered_on_friday,
                                locationDevice.watered_on_saturday,
                                locationDevice.watered_on_sunday,
                                locationDevice.timer_type,
                                locationDevice.has_flow_sensor,
                                locationDevice.flow_sensor_id,
                                locationDevice.flow_threshold_high,
                                locationDevice.flow_threshold_low,
                                locationDevice.flow_threshold_min,
                                locationDevice.device_id,
                                locationDevice.company_location_device_id,
                                locationDevice.company_location_id,
                                locationDevice.is_daylight_savings_offset_enabled
                              )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="blue"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                          </button>
                        )}
                      {userType !== 2 &&
                        userType !== 4 &&
                        is_read_only !== 1 &&
                        sortByStatus !== "3" && (
                          <button
                            className="delete p-1 hover:bg-white dark:hover:bg-gray-200 rounded-full"
                            onClick={() =>
                              handleDeleteDevice(
                                locationDevice.company_location_device_id,
                                locationDevice.device_identifier
                              )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="red"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                          </button>
                        )}

                      {is_read_only !== 1 &&
                        sortByStatus === "3" &&
                        sortByStatus === "2" && (
                          <button
                            className="restore p-1 hover:bg-white dark:hover:bg-gray-200 rounded-full"
                            onClick={() =>
                              handleRestoreDevice(
                                locationDevice.company_location_device_id,
                                locationDevice.device_identifier
                              )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                stroke-linejoin="round"
                                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                              />
                            </svg>
                          </button>
                        )}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center py-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-8 h-8 mx-auto text-gray-500 dark:text-gray-400"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="12" y1="8" x2="12" y2="12"></line>
                    <line x1="12" y1="16" x2="12.01" y2="16"></line>
                  </svg>
                  <p className="animate__animated animate__fadeIn mt-2 text-sm text-gray-500 dark:text-gray-400">
                    No device found
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {isModalOpen && (
        <LocationRenameZones
          setIsModeOpen={setIsModeOpen}
          isModalOpen={isModalOpen}
          zoneLoading={zoneLoading}
          closeModal={closeModal}
          companyLocationDeviceData={companyLocationDeviceData}
          setCompanyLocationDeviceData={setCompanyLocationDeviceData}
          setRenameLDID={setRenameLDID}
          renameLDID={renameLDID}
          zoneListData={zoneListData}
          setZoneListData={setZoneListData}
          fetchCompanyLocationDevice={fetchCompanyLocationDevice}
        />
      )}

      {addDevice && (
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50 font-inter"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="relative p-4 w-full h-full md:h-auto my-8">
            {/* <!-- Modal content --> */}
            <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 overflow-y-auto max-h-[90vh]">
              {/* <!-- Modal header --> */}
              <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Add Device
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal"
                  onClick={closeAddDevice}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <form onSubmit={handleAddDeviceSubmit}>
                <div className="grid gap-4 mb-4 sm:grid-cols-4">
                  <div>
                    <label
                      htmlFor="device"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Device
                    </label>
                    <Select
                      id="device"
                      options={deviceOptions}
                      placeholder="Select Device"
                      classNamePrefix="react-select"
                      className="basic-select"
                      isSearchable
                      value={deviceOptions.find(
                        (option) =>
                          option.value === addLocationDeviceForm.device_id
                      )}
                      onChange={handleDeviceChange}
                      filterOption={customFilter}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "gray-50",
                          borderColor: "gray-300",
                          borderRadius: "0.375rem",
                          padding: "0.5rem",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 100,
                        }),
                      }}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="boxIdentifier"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Box Identifier
                    </label>
                    <input
                      type="text"
                      name="boxIdentifier"
                      id="boxIdentifier"
                      required
                      value={addLocationDeviceForm.identifier}
                      onChange={(e) =>
                        setAddLocationDeviceForm((prev) => ({
                          ...prev,
                          identifier: e.target.value,
                        }))
                      }
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="timerType"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Timer Type
                    </label>
                    <select
                      id="timerType"
                      value={addLocationDeviceForm.timer_type}
                      onChange={(e) =>
                        setAddLocationDeviceForm((prev) => ({
                          ...prev,
                          timer_type: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="0">Digital Timer</option>
                      <option value="1">Indexing Valve</option>
                    </select>
                  </div>

                  <div className="flex items-center">
                    <input
                      id="pump"
                      type="checkbox"
                      checked={addLocationDeviceForm.has_pump}
                      onChange={(e) =>
                        setAddLocationDeviceForm((prev) => ({
                          ...prev,
                          has_pump: e.target.checked,
                        }))
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="pump"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Pump?
                    </label>
                  </div>

                  <div>
                    <label
                      htmlFor="manualControlStart"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Manual Control Start
                    </label>
                    <select
                      id="manualControlStart"
                      value={addLocationDeviceForm.manual_control_start}
                      onChange={(e) =>
                        setAddLocationDeviceForm((prev) => ({
                          ...prev,
                          manual_control_start: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      {generateTimeOptions()}
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="manualControlEnd"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Manual Control End
                    </label>
                    <select
                      id="manualControlEnd"
                      value={addLocationDeviceForm.manual_control_end}
                      onChange={(e) =>
                        setAddLocationDeviceForm((prev) => ({
                          ...prev,
                          manual_control_end: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      {generateTimeOptions()}
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="wateringInterval"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Watering Interval
                    </label>
                    <select
                      id="wateringInterval"
                      value={addLocationDeviceForm.watering_interval}
                      onChange={(e) =>
                        setAddLocationDeviceForm((prev) => ({
                          ...prev,
                          watering_interval: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="1">Everyday</option>
                      <option value="2">1 Day Skip</option>
                      <option value="3">2 Day Skip</option>
                      <option value="4">3 Day Skip</option>
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="yardGuardDrought"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Yard Guard Drought Weeks
                    </label>
                    <select
                      id="yardGuardDrought"
                      value={addLocationDeviceForm.yard_guard_drought_days}
                      onChange={(e) =>
                        setAddLocationDeviceForm((prev) => ({
                          ...prev,
                          yard_guard_drought_days: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="0">Disabled</option>
                      <option value="14">2 Weeks</option>
                      <option value="21">3 Weeks</option>
                      <option value="28">4 Weeks</option>
                    </select>
                  </div>
                </div>
                <div className="grid gap-4 mb-4 sm:grid-cols-3 mt-3 border-t border-gray-300 pt-2">
                  <div className="flex items-center">
                    <input
                      id="enableDaylightSavingsOffset"
                      type="checkbox"
                      checked={
                        addLocationDeviceForm.is_daylight_savings_offset_enabled
                      }
                      onChange={(e) =>
                        setAddLocationDeviceForm((prev) => ({
                          ...prev,
                          is_daylight_savings_offset_enabled: e.target.checked,
                        }))
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="enableDaylightSavingsOffset"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Enabled Daylight Savings Offset
                    </label>
                  </div>
                  <div>
                    <label
                      htmlFor="stationDelay"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Station Delay (sec)
                    </label>
                    <select
                      id="stationDelay"
                      value={addLocationDeviceForm.station_delay_seconds}
                      onChange={(e) =>
                        setAddLocationDeviceForm((prev) => ({
                          ...prev,
                          station_delay_seconds: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      {Array.from({ length: 1201 }, (_, index) => (
                        <option key={index} value={index}>
                          {index}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="systemTimeOffset"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      System Time Offset
                    </label>
                    <select
                      id="systemTimeOffset"
                      value={addLocationDeviceForm.system_time_offset}
                      onChange={(e) =>
                        setAddLocationDeviceForm((prev) => ({
                          ...prev,
                          system_time_offset: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      {Array.from({ length: 1201 }, (_, index) => (
                        <option key={index} value={index}>
                          {index}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="currentThreshold"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Current On Threshold (mA)
                    </label>
                    <select
                      id="currentThreshold"
                      value={Number(addLocationDeviceForm.current_on_threshold)}
                      onChange={(e) =>
                        setAddLocationDeviceForm((prev) => ({
                          ...prev,
                          current_on_threshold: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      {Array.from(
                        { length: 35 },
                        (_, index) => (index + 1) * 10
                      ).map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label
                      htmlFor="timeDriftThreshold"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Time Drift Threshold
                    </label>
                    <select
                      id="timeDriftThreshold"
                      value={addLocationDeviceForm.time_drift_threshold}
                      onChange={(e) =>
                        setAddLocationDeviceForm((prev) => ({
                          ...prev,
                          time_drift_threshold: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      {Array.from({ length: 121 }, (_, index) => (
                        <option key={index} value={index}>
                          {index}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="flowSampleCount"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Flow Sample Count
                    </label>
                    <select
                      id="flowSampleCount"
                      value={addLocationDeviceForm.flow_sample_count}
                      onChange={(e) =>
                        setAddLocationDeviceForm((prev) => ({
                          ...prev,
                          flow_sample_count: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      {Array.from({ length: 256 }, (_, index) => (
                        <option key={index} value={index}>
                          {index}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="flowSampleInterval"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Flow Sample Interval
                    </label>
                    <select
                      id="flowSampleInterval"
                      value={addLocationDeviceForm.flow_sample_interval}
                      onChange={(e) =>
                        setAddLocationDeviceForm((prev) => ({
                          ...prev,
                          flow_sample_interval: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      {Array.from({ length: 256 }, (_, index) => (
                        <option key={index} value={index}>
                          {index}
                        </option>
                      ))}
                    </select>
                    <h1 className="text-sm text-red-600 my-6">
                      Very High Flow triggered when Flow exceeds 2x High Flow
                      Threshold
                    </h1>
                  </div>
                </div>
                <div className="grid gap-4 mb-4 sm:grid-cols-3 mt-3 border-t border-b border-gray-300">
                  <div className="flex items-center my-3">
                    <input
                      id="pump"
                      type="checkbox"
                      checked={addLocationDeviceForm.has_flow_sensor}
                      onChange={(e) =>
                        setAddLocationDeviceForm((prev) => ({
                          ...prev,
                          has_flow_sensor: e.target.checked,
                        }))
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="pump"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Flowsensor?
                    </label>
                  </div>

                  {addLocationDeviceForm.has_flow_sensor && (
                    <div className="my-3">
                      <label
                        htmlFor=" Flow Sensor"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Flow Sensor
                      </label>
                      <select
                        id=" Flow Sensor"
                        value={addLocationDeviceForm.flow_sensor_id}
                        onChange={(e) =>
                          setAddLocationDeviceForm((prev) => ({
                            ...prev,
                            flow_sensor_id: e.target.value,
                          }))
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option disabled value="">
                          Select Flow Sensor
                        </option>
                        <option value="0">
                          Toro; TFS-100; 1"; 0.26112; 1.2
                        </option>
                        <option value="1">
                          Hunter; HFSFCT200; 2"; 2.13; 0.23
                        </option>
                        <option value="2">
                          Badger; 228PV; 2" PVC; 2.8429; 0.1435
                        </option>
                        <option value="3">
                          Sea; YF-S201B; 1/2"; 0.03303; 0
                        </option>
                        <option value="4">
                          Toro; TFS-200; 2"; 2.8429; 0.1435
                        </option>
                        <option value="5">
                          Toro; TFS-150; 1.5"; 1.699; -0.316
                        </option>
                        <option value="6">
                          Toro; TFS-300; 3"; 8.309; 0.227
                        </option>
                        <option value="7">
                          Hunter; HFSFCT158; 1 1/2"; 0.92; 1.22
                        </option>
                        <option value="8">
                          Hunter; HFSFCT150; 1 1/2"; 1.13; 0
                        </option>
                        <option value="9">Mojo; HC-150; 1 1/2"; 1; 0</option>
                        <option value="10">Pressure; 1/8"; 1; 1 ; 0</option>
                      </select>
                    </div>
                  )}

                  {addLocationDeviceForm.has_flow_sensor && (
                    <div className="my-3">
                      <label
                        htmlFor="Low Flow Threshold"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Minimum Flow Threshold
                      </label>
                      <select
                        id="flowSampleCount"
                        value={addLocationDeviceForm.flow_threshold_min}
                        onChange={(e) =>
                          setAddLocationDeviceForm((prev) => ({
                            ...prev,
                            flow_threshold_min: e.target.value,
                          }))
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        {Array.from({ length: 101 }, (_, index) => (
                          <option key={index} value={index}>
                            {index} %
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  <div className="my-3"></div>
                  {addLocationDeviceForm.has_flow_sensor && (
                    <div className="my-3">
                      <label
                        htmlFor="High Flow Threshold"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        High Flow Threshold
                      </label>
                      <select
                        id="High Flow Threshold"
                        value={Number(
                          addLocationDeviceForm.flow_threshold_high
                        )}
                        onChange={(e) =>
                          setAddLocationDeviceForm((prev) => ({
                            ...prev,
                            flow_threshold_high: e.target.value,
                          }))
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        {Array.from({ length: 101 }, (_, index) => (
                          <option key={index} value={index}>
                            {index} %
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {addLocationDeviceForm.has_flow_sensor && (
                    <div className="my-3">
                      <label
                        htmlFor="Low Flow Threshold"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Low Flow Threshold
                      </label>
                      <select
                        id="Low Flow Threshold"
                        value={addLocationDeviceForm.flow_threshold_low}
                        onChange={(e) =>
                          setAddLocationDeviceForm((prev) => ({
                            ...prev,
                            flow_threshold_low: e.target.value,
                          }))
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        {Array.from({ length: 101 }, (_, index) => (
                          <option key={index} value={index}>
                            {index} %
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>

                <div className="grid gap-4 mb-4 sm:grid-cols-7">
                  <div>
                    <input
                      id="monday"
                      type="checkbox"
                      checked={addLocationDeviceForm.watered_on_monday}
                      onChange={(e) =>
                        setAddLocationDeviceForm((prev) => ({
                          ...prev,
                          watered_on_monday: e.target.checked,
                        }))
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="monday"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Monday
                    </label>
                  </div>
                  <div>
                    <input
                      id="tuesday"
                      type="checkbox"
                      checked={addLocationDeviceForm.watered_on_tuesday}
                      onChange={(e) =>
                        setAddLocationDeviceForm((prev) => ({
                          ...prev,
                          watered_on_tuesday: e.target.checked,
                        }))
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="tuesday"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Tuesday
                    </label>
                  </div>
                  <div>
                    <input
                      id="wednesday"
                      type="checkbox"
                      checked={addLocationDeviceForm.watered_on_wednesday}
                      onChange={(e) =>
                        setAddLocationDeviceForm((prev) => ({
                          ...prev,
                          watered_on_wednesday: e.target.checked,
                        }))
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="wednesday"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Wednesday
                    </label>
                  </div>
                  <div>
                    <input
                      id="thursday"
                      type="checkbox"
                      checked={addLocationDeviceForm.watered_on_thursday}
                      onChange={(e) =>
                        setAddLocationDeviceForm((prev) => ({
                          ...prev,
                          watered_on_thursday: e.target.checked,
                        }))
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="thursday"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Thursday
                    </label>
                  </div>
                  <div>
                    <input
                      id="friday"
                      type="checkbox"
                      checked={addLocationDeviceForm.watered_on_friday}
                      onChange={(e) =>
                        setAddLocationDeviceForm((prev) => ({
                          ...prev,
                          watered_on_friday: e.target.checked,
                        }))
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="friday"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Friday
                    </label>
                  </div>
                  <div>
                    <input
                      id="saturday"
                      type="checkbox"
                      checked={addLocationDeviceForm.watered_on_saturday}
                      onChange={(e) =>
                        setAddLocationDeviceForm((prev) => ({
                          ...prev,
                          watered_on_saturday: e.target.checked,
                        }))
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="saturday"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Saturday
                    </label>
                  </div>
                  <div>
                    <input
                      id="sunday"
                      type="checkbox"
                      checked={addLocationDeviceForm.watered_on_sunday}
                      onChange={(e) =>
                        setAddLocationDeviceForm((prev) => ({
                          ...prev,
                          watered_on_sunday: e.target.checked,
                        }))
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="sunday"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Sunday
                    </label>
                  </div>
                </div>

                <div className="flex items-center justify-end">
                  <button
                    className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                    style={{ backgroundColor: "red" }}
                    onClick={closeAddDevice}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center"
                    style={{ backgroundColor: "#009D01" }}
                  >
                    Save Device
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {editDevice && (
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full font-inter"
          style={{
            // backdropFilter: "blur(5px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="relative p-4 w-full max-w-full h-full md:h-auto">
            {/* <!-- Modal content --> */}
            <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
              {/* <!-- Modal header --> */}
              <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Edit Device
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal"
                  onClick={closeEditDevice}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <form onSubmit={handleEditSubmit}>
                <div className="grid gap-4 mb-4 sm:grid-cols-4">
                  <div>
                    <label
                      htmlFor="device"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Device
                    </label>
                    <Select
                      id="device"
                      options={deviceOptions}
                      placeholder="Select Device"
                      classNamePrefix="react-select"
                      className="basic-select"
                      isSearchable
                      value={deviceOptions.find(
                        (option) => option.value === editDeviceDetails.device_id
                      )}
                      onChange={handleDeviceChange}
                      filterOption={customFilter}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "gray-50",
                          borderColor: "gray-300",
                          borderRadius: "0.375rem",
                          padding: "0.5rem",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 100,
                        }),
                      }}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="boxIdentifier"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Box Identifier
                    </label>
                    <input
                      type="text"
                      name="boxIdentifier"
                      value={editDeviceDetails.identifier}
                      onChange={(e) =>
                        setEditDeviceDetails({
                          ...editDeviceDetails,
                          identifier: e.target.value,
                        })
                      }
                      id="boxIdentifier"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="timerType"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Timer Type
                    </label>
                    <select
                      id="timerType"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      defaultValue={
                        editDeviceDetails.timer_type === 0 ? "0" : "1"
                      }
                      onChange={(e) =>
                        setEditDeviceDetails((prevDetails) => ({
                          ...prevDetails,
                          timer_type: e.target.value === "0" ? 0 : 1,
                        }))
                      }
                    >
                      <option value="0">Digital Timer</option>
                      <option value="1">Indexing Valve</option>
                    </select>
                  </div>

                  <div className="flex items-center">
                    <input
                      id="pump"
                      type="checkbox"
                      checked={editDeviceDetails.has_pump === 1}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      onChange={(e) => {
                        setEditDeviceDetails((prevDetails) => ({
                          ...prevDetails,
                          has_pump: e.target.checked ? 1 : 0,
                        }));
                      }}
                    />
                    <label
                      htmlFor="pump"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Pump?
                    </label>
                  </div>

                  <div>
                    <label
                      htmlFor="manualControlStart"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Manual Control Start
                    </label>
                    <input
                      type="time"
                      id="ManualControlStart"
                      defaultValue={editDeviceDetails.manual_control_start}
                      onChange={(e) =>
                        setEditDeviceDetails((prevDetails) => ({
                          ...prevDetails,
                          manual_control_start: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="manualControlEnd"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Manual Control End
                    </label>
                    <input
                      type="time"
                      id="ManualControlStart"
                      defaultValue={editDeviceDetails.manual_control_end}
                      onChange={(e) =>
                        setEditDevice((prevDetails) => ({
                          ...prevDetails,
                          manual_control_end: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="wateringInterval"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Watering Interval
                    </label>
                    <select
                      id="wateringInterval"
                      defaultValue={editDeviceDetails.watering_interval}
                      onChange={(e) =>
                        setEditDeviceDetails((prevDetails) => ({
                          ...prevDetails,
                          watering_interval: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="1">Everyday</option>
                      <option value="2">1 Day Skip</option>
                      <option value="3">2 Day Skip</option>
                      <option value="4">3 Day Skip</option>
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="yardGuardDrought"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Yard Guard Drought Weeks
                    </label>
                    <select
                      id="yardGuardDrought"
                      defaultValue={editDeviceDetails.yard_guard_drought_days}
                      onChange={(e) =>
                        setEditDeviceDetails((prevDetails) => ({
                          ...prevDetails,
                          yard_guard_drought_days: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option></option>
                      <option value="0">Disabled</option>
                      <option value="14">2 Weeks</option>
                      <option value="21">3 Weeks</option>
                      <option value="28">4 Weeks</option>
                    </select>
                  </div>
                </div>
                <div className="grid gap-4 mb-4 sm:grid-cols-3 mt-3 border-t border-gray-300 pt-2">
                  <div className="flex items-center">
                    <input
                      id="pump"
                      type="checkbox"
                      checked={
                        editDeviceDetails.is_daylight_savings_offset_enabled
                      }
                      onChange={(e) =>
                        setEditDeviceDetails((prev) => ({
                          ...prev,
                          is_daylight_savings_offset_enabled: e.target.checked,
                        }))
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="pump"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Enabled Daylight Savings Offset
                    </label>
                  </div>
                  <div>
                    <label
                      htmlFor="stationDelay"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Station Delay (secs)
                    </label>
                    <select
                      id="stationDelay"
                      defaultValue={editDeviceDetails.station_delay_seconds}
                      onChange={(e) =>
                        setEditDeviceDetails((prevDetails) => ({
                          ...prevDetails,
                          station_delay_seconds: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      {Array.from({ length: 1201 }, (_, i) => (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="systemTimeOffset"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      System Time Offset
                    </label>
                    <select
                      id="systemTimeOffset"
                      defaultValue={editDeviceDetails.system_time_offset}
                      onChange={(e) =>
                        setEditDeviceDetails((prevDetails) => ({
                          ...prevDetails,
                          system_time_offset: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      {Array.from({ length: 1201 }, (_, i) => (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="currentThreshold"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Current On Threshold (mA)
                    </label>
                    <select
                      id="currentThreshold"
                      defaultValue={editDeviceDetails.current_on_threshold}
                      onChange={(e) =>
                        setEditDeviceDetails((prevDetails) => ({
                          ...prevDetails,
                          current_on_threshold: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      {Array.from({ length: 35 }, (_, i) => {
                        const value = (i + 1) * 10;
                        return (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div>
                    <label
                      htmlFor="timeDriftThreshold"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Time Drift Threshold
                    </label>
                    <select
                      id="timeDriftThreshold"
                      defaultValue={editDeviceDetails.time_drift_threshold}
                      onChange={(e) =>
                        setEditDeviceDetails((prevDetails) => ({
                          ...prevDetails,
                          time_drift_threshold: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      {Array.from({ length: 121 }, (_, i) => (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="flowSampleCount"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Flow Sample Count
                    </label>
                    <select
                      id="flowSampleCount"
                      defaultValue={editDeviceDetails.flow_sample_count}
                      onChange={(e) =>
                        setEditDeviceDetails((prevDetails) => ({
                          ...prevDetails,
                          flow_sample_count: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      {Array.from({ length: 256 }, (_, i) => (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="flowSampleInterval"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Flow Sample Interval
                    </label>
                    <select
                      id="flowSampleInterval"
                      defaultValue={editDeviceDetails.flow_sample_interval}
                      onChange={(e) =>
                        setEditDeviceDetails((prevDetails) => ({
                          ...prevDetails,
                          flow_sample_interval: e.target.value,
                        }))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      {Array.from({ length: 256 }, (_, i) => (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <h1 className="text-sm text-red-600">
                  Very High Flow triggered when Flow exceeds 2x High Flow
                  Threshold
                </h1>
                <div className="grid gap-4 mb-4 sm:grid-cols-3 mt-3 border-t border-b border-gray-300">
                  <div className="flex items-center my-3">
                    <input
                      id="pump"
                      type="checkbox"
                      checked={editDeviceDetails.has_flow_sensor}
                      onChange={(e) =>
                        setEditDeviceDetails((prev) => ({
                          ...prev,
                          has_flow_sensor: e.target.checked,
                        }))
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="pump"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Flowsensor?
                    </label>
                  </div>

                  {editDeviceDetails.has_flow_sensor && (
                    <div className="my-3">
                      <label
                        htmlFor=" Flow Sensor"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Flow Sensor
                      </label>
                      <select
                        id=" Flow Sensor"
                        value={editDeviceDetails.flow_sensor_id}
                        onChange={(e) =>
                          setEditDeviceDetails((prev) => ({
                            ...prev,
                            flow_sensor_id: e.target.value,
                          }))
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="">Select Flow Sensor</option>
                        <option value="0">
                          Toro; TFS-100; 1"; 0.26112; 1.2
                        </option>
                        <option value="1">
                          Hunter; HFSFCT200; 2"; 2.13; 0.23
                        </option>
                        <option value="2">
                          Badger; 228PV; 2" PVC; 2.8429; 0.1435
                        </option>
                        <option value="3">
                          Sea; YF-S201B; 1/2"; 0.03303; 0
                        </option>
                        <option value="4">
                          Toro; TFS-200; 2"; 2.8429; 0.1435
                        </option>
                        <option value="5">
                          Toro; TFS-150; 1.5"; 1.699; -0.316
                        </option>
                        <option value="6">
                          Toro; TFS-300; 3"; 8.309; 0.227
                        </option>
                        <option value="7">
                          Hunter; HFSFCT158; 1 1/2"; 0.92; 1.22
                        </option>
                        <option value="8">
                          Hunter; HFSFCT150; 1 1/2"; 1.13; 0
                        </option>
                        <option value="9">Mojo; HC-150; 1 1/2"; 1; 0</option>
                        <option value="10">Pressure; 1/8"; 1; 1 ; 0</option>
                      </select>
                    </div>
                  )}

                  {editDeviceDetails.has_flow_sensor && (
                    <div className="my-3">
                      <label
                        htmlFor="Low Flow Threshold"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Minimum Flow Threshold
                      </label>
                      <select
                        id="flowSampleCount"
                        value={editDeviceDetails.flow_threshold_min}
                        onChange={(e) =>
                          setEditDeviceDetails((prev) => ({
                            ...prev,
                            flow_threshold_min: e.target.value,
                          }))
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        {Array.from({ length: 101 }, (_, index) => (
                          <option key={index} value={index}>
                            {index} %
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  <div className="my-3"></div>
                  {editDeviceDetails.has_flow_sensor && (
                    <div className="my-3">
                      <label
                        htmlFor="Low Flow Threshold"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        High Flow Threshold
                      </label>
                      <select
                        id="flowSampleCount"
                        value={editDeviceDetails.flow_threshold_high}
                        onChange={(e) =>
                          setEditDeviceDetails((prev) => ({
                            ...prev,
                            flow_threshold_high: e.target.value,
                          }))
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        {Array.from({ length: 101 }, (_, index) => (
                          <option key={index} value={index}>
                            {index} %
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {editDeviceDetails.has_flow_sensor && (
                    <div className="my-3">
                      <label
                        htmlFor="Low Flow Threshold"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Low Flow Threshold
                      </label>
                      <select
                        id="flowSampleCount"
                        value={editDeviceDetails.flow_threshold_low}
                        onChange={(e) =>
                          setEditDeviceDetails((prev) => ({
                            ...prev,
                            flow_threshold_low: e.target.value,
                          }))
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        {Array.from({ length: 101 }, (_, index) => (
                          <option key={index} value={index}>
                            {index} %
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
                <div className="grid gap-4 mb-4 sm:grid-cols-7">
                  <div>
                    <input
                      id="monday"
                      type="checkbox"
                      checked={editDeviceDetails.watered_on_monday === 1}
                      onChange={(e) =>
                        setEditDeviceDetails((prevDetails) => ({
                          ...prevDetails,
                          watered_on_monday: e.target.checked ? 1 : 0,
                        }))
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="monday"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Monday
                    </label>
                  </div>
                  <div>
                    <input
                      id="tuesday"
                      type="checkbox"
                      checked={editDeviceDetails.watered_on_tuesday === 1}
                      onChange={(e) =>
                        setEditDeviceDetails((prevDetails) => ({
                          ...prevDetails,
                          watered_on_tuesday: e.target.checked ? 1 : 0,
                        }))
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="tuesday"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Tuesday
                    </label>
                  </div>
                  <div>
                    <input
                      id="wednesday"
                      type="checkbox"
                      checked={editDeviceDetails.watered_on_wednesday === 1}
                      onChange={(e) =>
                        setEditDeviceDetails((prevDetails) => ({
                          ...prevDetails,
                          watered_on_wednesday: e.target.checked ? 1 : 0,
                        }))
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="wednesday"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Wednesday
                    </label>
                  </div>
                  <div>
                    <input
                      id="thursday"
                      type="checkbox"
                      checked={editDeviceDetails.watered_on_thursday === 1}
                      onChange={(e) =>
                        setEditDeviceDetails((prevDetails) => ({
                          ...prevDetails,
                          watered_on_thursday: e.target.checked ? 1 : 0,
                        }))
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="thursday"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Thursday
                    </label>
                  </div>
                  <div>
                    <input
                      id="friday"
                      type="checkbox"
                      checked={editDeviceDetails.watered_on_friday === 1}
                      onChange={(e) =>
                        setEditDeviceDetails((prevDetails) => ({
                          ...prevDetails,
                          watered_on_friday: e.target.checked ? 1 : 0,
                        }))
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="friday"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Friday
                    </label>
                  </div>
                  <div>
                    <input
                      id="saturday"
                      type="checkbox"
                      checked={editDeviceDetails.watered_on_saturday === 1}
                      onChange={(e) =>
                        setEditDeviceDetails((prevDetails) => ({
                          ...prevDetails,
                          watered_on_saturday: e.target.checked ? 1 : 0,
                        }))
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="saturday"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Saturday
                    </label>
                  </div>
                  <div>
                    <input
                      id="sunday"
                      type="checkbox"
                      checked={editDeviceDetails.watered_on_sunday === 1}
                      onChange={(e) =>
                        setEditDeviceDetails((prevDetails) => ({
                          ...prevDetails,
                          watered_on_sunday: e.target.checked ? 1 : 0,
                        }))
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="sunday"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Sunday
                    </label>
                  </div>
                </div>

                <div className="flex items-center justify-end">
                  <button
                    className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                    style={{ backgroundColor: "red" }}
                    onClick={closeEditDevice}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center"
                    style={{ backgroundColor: "#009D01" }}
                  >
                    Save Device
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {locationDeviceProgramModal && (
        <LocationPrograms
          companyLocationDeviceData={companyLocationDeviceData}
          setCompanyLocationDeviceData={setCompanyLocationDeviceData}
          toggleLocationDeviceProgramModal={toggleLocationDeviceProgramModal}
          companyLDID={companyLDID}
          locationDeviceProgramModal={locationDeviceProgramModal}
          setLocationDeviceProgramModal={setLocationDeviceProgramModal}
          fetchCompanyLocationDevice={fetchCompanyLocationDevice}
        />
      )}
    </div>
  );
}
