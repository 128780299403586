import React, { useState, useContext, useEffect } from "react";
import Calendar from "../../../components/calendar/Calendar";
import MonthlySavings from "../../../components/calendar/charts/MonthlySavings";
import { ThemeContext } from "../../../components/context/themeContext/ThemeContext";
import { useSelectedCompany } from "../../../components/context/apiContext/SelectedCompanyContext";
import { useCompanyLocation } from "../../../components/context/apiContext/CompanyLocationContext";
import { useSelector } from "react-redux";
import ApiLink from "../../../components/api/ApiLink";
import { Link } from "react-router-dom/cjs/react-router-dom";
import DatePicker from "react-datepicker";

export default function LocationInfo({ isLoading }) {
  const {
    companyLocationData,
    setCompanyLocationData,
    CompanyLocationDetails,
  } = useCompanyLocation();
  const {
    color,
    textColor,
    backgroundColor,
    tableHeaderColor,
    tableHeaderTextColor,
  } = useContext(ThemeContext);
  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);

  const [companyLocationDeviceData, setCompanyLocationDeviceData] = useState(
    []
  );

  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [zoneLoading, setZoneLoading] = useState(false);
  const [manualControl, setManualControl] = useState({
    start_time: null,
    end_time: null,
  });

  const [getBinAlertsData, setGetBinAlertsData] = useState([]);
  const [alertLoading, setAlertLoading] = useState(false);

  const [selectedSevLevel, setSelectedSevLevel] = useState("");
  const [selectedAlertType, setSelectedAlertType] = useState("");
  const [alertCounts, setAlertCounts] = useState({});
  const [severityCounts, setSeverityCounts] = useState({
    high: 0,
    info: 0,
    low: 0,
    unknown: 0,
  });
  const [selectStartDate, setSelectStartDate] = useState(false);

  const [selectedDate, setSelectedDate] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
    endDate: new Date(new Date().setDate(new Date().getDate() + 0)),
  });

  const {
    selectedDeviceId,
    setSelectedDeviceId,
    updateSelectedDeviceId,
    updateSerialNumber,
    selectedLocation,
  } = useSelectedCompany();

  const [ZoneListProgramData, setZoneListProgramData] = useState({
    Programs: {},
    Zones: {},
  });

  const userType = useSelector((state) => state.auth.userType);

  useEffect(() => {
    setLoading(true);
    const fetchCompanyLocationDevice = async () => {
      try {
        const response = await ApiLink.post("/api/company-location-device", {
          email: email,
          password: password,
          company_location_id: selectedLocation.locationId,
        });
        if (response.status === 200) {
          if (
            Array.isArray(response.data.Data) &&
            response.data.Data.length > 0
          ) {
            setCompanyLocationDeviceData(response.data.Data);
            setSelectedDevice(response.data.Data[0]);
            updateSelectedDeviceId(response.data.Data[0]);
            const deviceData = response.data.Data[0];
            setManualControl({
              start_time: deviceData.manual_control_start,
              end_time: deviceData.manual_control_end,
            });
          } else if (response.data.Data && response.data.Data.results === 0) {
            // Handle the case when there are no results
            setCompanyLocationDeviceData([]);
            setSelectedDevice(null);
            updateSelectedDeviceId(null);
          } else {
            setCompanyLocationDeviceData([]);
            setSelectedDevice(null);
            updateSelectedDeviceId(null);
          }
        } else {
          setCompanyLocationDeviceData([]);
          setSelectedDevice(null);
          updateSelectedDeviceId(null);
        }
      } catch (error) {
        console.error("Error:", error);
        setCompanyLocationDeviceData([]);
        setSelectedDevice(null);
        updateSelectedDeviceId(null);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyLocationDevice();
  }, [email, password, selectedLocation.locationId]);

  useEffect(() => {
    if (selectedDeviceId && selectedDeviceId.company_location_device_id) {
      setZoneLoading(true);
      const fetchZoneListProgram = async () => {
        try {
          const response = await ApiLink.post("/api/zone-list", {
            email: email,
            password: password,
            company_location_device_id:
              selectedDeviceId.company_location_device_id,
          });
          if (response.status === 200 && response.data.Data) {
            setZoneListProgramData(response.data.Data);
          }
        } catch (error) {
          console.log("Error:", error);
        } finally {
          setZoneLoading(false);
        }
      };
      fetchZoneListProgram();
    } else {
      // Reset ZoneListProgramData when there's no selected device
      setZoneListProgramData({ Programs: {}, Zones: {} });
    }
  }, [email, password, selectedDeviceId]);

  const [activeTab, setActiveTab] = useState("Watering");

  // <------------------------------->

  const handleLocationDevices = async (event) => {
    const selectedDeviceId = Number(event.target.value);
    const selectedDevice = companyLocationDeviceData.find(
      (device) => device.company_location_device_id === selectedDeviceId
    );

    if (selectedDevice) {
      updateSelectedDeviceId({
        company_location_device_id: selectedDevice.company_location_device_id,
        device_identifier: selectedDevice.device_identifier,
      });
      console.log("SELECTED DEVICE: ");
      setSelectedDevice(selectedDevice);

      await fetchDecisionCalendar(selectedDevice.company_location_device_id);
    } else {
      setSelectedDevice(null);
      updateSelectedDeviceId(null);
    }
  };

  const fetchDecisionCalendar = async (deviceId) => {
    try {
      const response = await ApiLink.post("/api/decision-calendar", {
        email: email,
        password: password,
        company_location_device_id: deviceId,
      });

      if (response.status === 200) {
      }
    } catch (error) {
      console.error("Error fetching calendar data:", error);
    }
  };

  const handleSerialNumberClick = (deviceId) => {
    updateSerialNumber({ deviceId });
    toggleDetailsModal();
  };

  const toggleDetailsModal = () => {
    setDetailsModal(!detailsModal);
  };

  const convertTo12HourFormat = (time) => {
    let [hours, minutes, seconds] = time.split(":");
    let period = "AM";

    hours = parseInt(hours, 10);
    if (hours >= 12) {
      period = "PM";
      hours = hours > 12 ? hours - 12 : hours;
    } else if (hours === 0) {
      hours = 12;
    }

    const formattedHours = hours < 10 ? `0${hours}` : hours;

    return `${formattedHours}:${minutes} ${period}`;
  };

  const alertTypeMap = {
    V: "Very High Flow [high]",
    P: "Pump Disabled Irrigation [high]",
    D: "Disabled Irrigation [high]",
    H: "High Flow [low]",
    L: "Low Flow [low]",
    F: "Zone Overcurrent",
    M: "Manual Run Detected",
    E: "Manual Run Ended",
    U: "Unscheduled Flow Detected",
    CA: "Server Custom Program Added",
    CD: "Server Custom Program Deleted",
    SD: "Server Clock Skew or Progmod",
    NF: "Server No Feedback Reported",
    NFZ: "Server No Feedback on Some Zones",
    IF: "Server Inconsistent Feedback",
    MM: "Server Multiple Manual Events",
    UW: "Server Unauthorized Watering",
    OFF: "Server Offline Devices",
  };

  const fetchGetBinAlerts = async () => {
    setAlertLoading(true);
    try {
      const requestBody = {
        email: email,
        password: password,
        from_date: formatDate(selectedDate.startDate),
        to_date: formatDate(selectedDate.endDate),
        company_id: selectedLocation.companyId,
      };

      if (selectedAlertType) {
        requestBody.alert_type = selectedAlertType;
      }

      if (selectedSevLevel) {
        requestBody.severity = selectedSevLevel;
      }

      const response = await ApiLink.post("/api/get-bin-alerts", requestBody);

      if (response.status === 200) {
        if (
          Array.isArray(response.data.Data) &&
          response.data.Data.length > 0
        ) {
          setGetBinAlertsData(response.data.Data);

          // Initialize counts for all alert types
          const initialCounts = Object.values(alertTypeMap).reduce(
            (acc, alertType) => {
              acc[alertType] = 0;
              return acc;
            },
            {}
          );

          // Calculate counts for each alert type
          const counts = response.data.Data.reduce((acc, alert) => {
            const alertTypeFull =
              alertTypeMap[alert.alert_type] || alert.alert_type;
            acc[alertTypeFull] = (acc[alertTypeFull] || 0) + 1;
            return acc;
          }, initialCounts);

          setAlertCounts(counts);

          // Calculate severity counts
          const severityCounts = response.data.Data.reduce(
            (acc, alert) => {
              const severity = alert.severity.toLowerCase();
              acc[severity] = (acc[severity] || 0) + 1;
              return acc;
            },
            { high: 0, info: 0, low: 0, unknown: 0 }
          );

          setSeverityCounts(severityCounts);
        } else {
          setGetBinAlertsData([]);
          setAlertCounts(
            Object.values(alertTypeMap).reduce((acc, alertType) => {
              acc[alertType] = 0;
              return acc;
            }, {})
          );
          setSeverityCounts({ high: 0, info: 0, low: 0, unknown: 0 });
          console.log("No Bin Alerts Found");
        }
      }
    } catch (error) {
      console.log("Error:", error);
      setGetBinAlertsData([]);
      setAlertCounts(
        Object.values(alertTypeMap).reduce((acc, alertType) => {
          acc[alertType] = 0;
          return acc;
        }, {})
      );
      setSeverityCounts({ high: 0, info: 0, low: 0, unknown: 0 });
    } finally {
      setAlertLoading(false);
    }
  };
  const handleStartDateChange = (date) => {
    if (date) {
      const startDate = new Date(date);
      setSelectedDate((prev) => ({
        ...prev,
        startDate,
      }));
    }
  };

  const handleEndDateChange = (date) => {
    if (date) {
      const endDate = new Date(date);
      setSelectedDate((prev) => ({
        ...prev,
        endDate,
      }));
    }
  };

  const toggleDropdown = (dropdownName) => {
    if (dropdownName === "selectStartDate") {
      setSelectStartDate(!selectStartDate);
    }
  };

  const formatDate = (date) => {
    return date ? date.toISOString().split("T")[0] : "";
  };

  useEffect(() => {
    fetchGetBinAlerts();
  }, []);

  const handleSearchClick = () => {
    fetchGetBinAlerts();
  };

  const getColorCode = (color) => {
    switch (color) {
      case "blue":
        return "#1c64f2";
      case "green":
        return "#057a55";
      case "purple":
        return "#7e3af2";
      case "red":
        return "#FF0000";
      case "yellow":
        return "#9F580A";
      case "pink":
        return "#D61F69";
      default:
        return "#057a55";
    }
  };
  function groupByCompanyLocationDeviceId(data) {
    return data.reduce((acc, curr) => {
      (acc[curr.company_location_device_id] =
        acc[curr.company_location_device_id] || []).push(curr);
      return acc;
    }, {});
  }

  return (
    <>
      <div className="flex justify-between p-2">
        {loading ? (
          <div colSpan="7" className="h-8">
            <div
              role="status"
              className="flex justify-center items-center h-full"
            >
              <svg
                aria-hidden="true"
                className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <div className="text-sm">
              {selectedDevice?.serial_number ? (
                userType === 2 || userType === 4 ? (
                  <h1 className="flex items-center cursor-pointer text-black dark:text-white">
                    <div>
                      <svg
                        fill="#000000"
                        width="25px"
                        height="25px"
                        viewBox="-4 0 32 32"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-black dark:text-white"
                      >
                        <title>barcode</title>
                        <path d="M0 25.281h0.781v-18.563h-0.781v18.563zM2.344 25.281h1.531v-18.563h-1.531v18.563zM5.406 25.281h1.563v-18.563h-1.563v18.563zM8.5 25.281h3.125v-18.563h-3.125v18.563zM13.156 25.281h2.344v-18.563h-2.344v18.563zM17.031 25.281h1.563v-18.563h-1.563v18.563zM20.125 25.281h0.781v-18.563h-0.781v18.563zM22.469 25.281h1.531v-18.563h-1.531v18.563z"></path>
                      </svg>
                    </div>
                    {selectedDevice.serial_number}
                  </h1>
                ) : (
                  <Link to={`/devices/${selectedDevice.device_id}`}>
                    <h1
                      className="flex items-center hover:underline cursor-pointer text-black dark:text-white"
                      onClick={() =>
                        handleSerialNumberClick(selectedDevice.device_id)
                      }
                    >
                      <div>
                        <svg
                          fill="#000000"
                          width="25px"
                          height="25px"
                          viewBox="-4 0 32 32"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-black dark:text-white"
                        >
                          <title>barcode</title>
                          <path d="M0 25.281h0.781v-18.563h-0.781v18.563zM2.344 25.281h1.531v-18.563h-1.531v18.563zM5.406 25.281h1.563v-18.563h-1.563v18.563zM8.5 25.281h3.125v-18.563h-3.125v18.563zM13.156 25.281h2.344v-18.563h-2.344v18.563zM17.031 25.281h1.563v-18.563h-1.563v18.563zM20.125 25.281h0.781v-18.563h-0.781v18.563zM22.469 25.281h1.531v-18.563h-1.531v18.563z"></path>
                        </svg>
                      </div>
                      {selectedDevice.serial_number}
                    </h1>
                  </Link>
                )
              ) : (
                <h1 className="flex items-center text-gray-500 cursor-not-allowed dark:text-gray-400">
                  <div>
                    <svg
                      fill="#7a7a7a"
                      width="25px"
                      height="25px"
                      viewBox="-4 0 32 32"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-black dark:text-white"
                    >
                      <title>barcode</title>
                      <path d="M0 25.281h0.781v-18.563h-0.781v18.563zM2.344 25.281h1.531v-18.563h-1.531v18.563zM5.406 25.281h1.563v-18.563h-1.563v18.563zM8.5 25.281h3.125v-18.563h-3.125v18.563zM13.156 25.281h2.344v-18.563h-2.344v18.563zM17.031 25.281h1.563v-18.563h-1.563v18.563zM20.125 25.281h0.781v-18.563h-0.781v18.563zM22.469 25.281h1.531v-18.563h-1.531v18.563z"></path>
                    </svg>
                  </div>
                  N/A
                </h1>
              )}

              <h1 className="serialNumber text-black dark:text-white font-semibold">
                {selectedDevice?.device_identifier ?? "N/A"}
              </h1>
              <h1 className="text-black dark:text-white">
                LDID (Location Device ID):{" "}
                {selectedDevice?.company_location_device_id ?? "N/A"}
              </h1>
            </div>

            <div>
              {companyLocationDeviceData.length > 0 ? (
                <select
                  id="countries"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={handleLocationDevices}
                  disabled={loading}
                >
                  {companyLocationDeviceData.map((device) => (
                    <option
                      key={device.company_location_device_id}
                      value={device.company_location_device_id}
                    >
                      {device.device_identifier}
                    </option>
                  ))}
                </select>
              ) : (
                ""
              )}
            </div>
          </>
        )}
      </div>

      <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
        <ul
          className="flex flex-wrap -mb-px text-sm font-medium text-center"
          id="default-styled-tab"
          data-tabs-toggle="#default-styled-tab-content"
          data-tabs-active-classnames="text-blue-600 hover:text-blue-600 dark:text-gray-500 dark:hover:text-gray-500 border-gray-600 dark:border-gray-500"
          data-tabs-inactive-classnames="dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300"
          role="tablist"
        >
          <li className="me-2" role="presentation">
            <button
              className={`inline-block p-4 border-b-2 rounded-t-lg ${
                activeTab === "Watering"
                  ? "border-blue-600 text-blue-600"
                  : "text-gray-300 hover:text-gray-800 hover:border-gray-300 dark:hover:text-gray-300"
              }`}
              onClick={() => setActiveTab("Watering")}
              type="button"
              role="tab"
              aria-selected={activeTab === "Watering"}
            >
              Watering Decisions
            </button>
          </li>
          <li className="me-2" role="presentation">
            <button
              className={`inline-block p-4 border-b-2 rounded-t-lg ${
                activeTab === "Savings"
                  ? "border-blue-600 text-blue-600"
                  : "text-gray-300 hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              }`}
              onClick={() => setActiveTab("Savings")}
              type="button"
              role="tab"
              aria-selected={activeTab === "Savings"}
            >
              Savings
            </button>
          </li>
          {userType !== 2 && (
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-4 border-b-2 rounded-t-lg ${
                  activeTab === "Alerts"
                    ? "border-blue-600 text-blue-600"
                    : "text-gray-300 hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                }`}
                onClick={() => setActiveTab("Alerts")}
                type="button"
                role="tab"
                aria-selected={activeTab === "Alerts"}
              >
                Alerts
              </button>
            </li>
          )}
        </ul>
      </div>
      <div id="default-styled-tab-content">
        {activeTab === "Watering" && (
          <div>
            <div className="p-4 border-gray-200 border-dashed rounded-lg dark:border-gray-700 dark:text-white">
              <div className="relative w-full">
                <Calendar selectedDeviceId={selectedDeviceId} />
              </div>
              <div className="flex flex-row p-5 text-sm font-semibold text-left rtl:text-right text-gray-900 dark:text-white dark:bg-gray-800 items-center">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                    />
                  </svg>
                </div>
                <h1 className="italic">
                  The events are based on the end time of the sprinkling cycle.
                </h1>
              </div>

              <div className="flex flex-col md:flex-row gap-4">
                <div className="leftSide relative overflow-x-auto w-full ">
                  <div className="flex flex-row p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 dark:text-white dark:bg-gray-800 items-center">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                        />
                      </svg>
                    </div>
                    <h1>
                      Manual Control:{" "}
                      {manualControl.start_time
                        ? convertTo12HourFormat(manualControl.start_time)
                        : "N/A"}{" "}
                      -{" "}
                      {manualControl.end_time
                        ? convertTo12HourFormat(manualControl.end_time)
                        : "N/A"}
                    </h1>
                  </div>
                  <table className="border text-black dark:text-white w-full cursor-pointer text-sm text-left rtl:text-right">
                    <thead
                      className={`text-sm uppercase leading-4`}
                      style={{
                        backgroundColor: `${tableHeaderColor}`,
                        color: `${tableHeaderTextColor}`,
                      }}
                    >
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 border border-black "
                        >
                          Program
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 border border-black "
                        >
                          Start Time
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 border border-black "
                        >
                          End Time
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm leading-4">
                      {zoneLoading ? (
                        <tr>
                          <td colSpan="8" className="h-16">
                            <div
                              role="status"
                              className="flex justify-center items-center h-full"
                            >
                              <svg
                                aria-hidden="true"
                                className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                              <span className="sr-only">Loading...</span>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        Object.entries(ZoneListProgramData.Programs).map(
                          ([program, details]) => (
                            <tr
                              key={program}
                              className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white odd:dark:bg-gray-900 even:bg-purple-200 even:dark:bg-gray-800"
                            >
                              <td className="border border-black dark:border-white px-6 py-4">
                                {program}
                              </td>
                              <td className="border border-black dark:border-white px-6 py-4">
                                {details[0].schedule.start_time}
                              </td>
                              <td className="border border-black dark:border-white px-6 py-4">
                                {details[0].schedule.end_time}
                              </td>
                            </tr>
                          )
                        )
                      )}

                      {Object.entries(ZoneListProgramData.Programs).length ===
                        0 && (
                        <tr>
                          <td colSpan="7" className="text-center py-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="w-8 h-8 mx-auto text-gray-500 dark:text-gray-400"
                            >
                              <circle cx="12" cy="12" r="10"></circle>
                              <line x1="12" y1="8" x2="12" y2="12"></line>
                              <line x1="12" y1="16" x2="12.01" y2="16"></line>
                            </svg>
                            <p className="animate__animated animate__fadeIn mt-2 text-sm text-gray-500 dark:text-gray-400">
                              No Program Available
                            </p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="rightSide relative overflow-x-auto shadow-md sm:rounded-lg w-full ">
                  <div className="flex flex-row p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 dark:text-white dark:bg-gray-800 items-center">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                        />
                      </svg>
                    </div>
                    <h1>Icon Legend</h1>
                  </div>
                  <table className="w-full cursor-pointer text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 leading-4">
                    <tbody className="text-black dark:text-white ">
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600">
                        <td className="px-6 py-4">
                          <div className="flex flex-row items-center gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="red"
                              className="size-6 mr-1"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                              />
                            </svg>

                            <h1 className="text-sm">
                              Custom Watering Override
                            </h1>
                          </div>
                        </td>
                      </tr>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600">
                        <td className="px-6 py-4">
                          <div className="flex flex-row items-center gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="red"
                              className="size-6 mr-1"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                              />
                            </svg>

                            <h1 className="text-sm">Daytime Run Detected</h1>
                          </div>
                        </td>
                      </tr>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600">
                        <td className="px-6 py-4">
                          <div className="flex flex-row items-center gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="red"
                              className="size-6 mr-1"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 15a4.5 4.5 0 0 0 4.5 4.5H18a3.75 3.75 0 0 0 1.332-7.257 3 3 0 0 0-3.758-3.848 5.25 5.25 0 0 0-10.233 2.33A4.502 4.502 0 0 0 2.25 15Z"
                              />
                            </svg>
                            <h1 className="text-sm">Reason Rain</h1>
                          </div>
                        </td>
                      </tr>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600">
                        <td className="px-6 py-4">
                          <div className="flex flex-row items-center gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="red"
                              className="size-6 mr-1"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061A1.125 1.125 0 0 1 3 16.811V8.69ZM12.75 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061a1.125 1.125 0 0 1-1.683-.977V8.69Z"
                              />
                            </svg>

                            <h1 className="text-sm">Extended Skip</h1>
                          </div>
                        </td>
                      </tr>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600">
                        <td className="px-6 py-4">
                          <div className="flex flex-row items-center gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="red"
                              className="size-6 mr-1"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                              />
                            </svg>

                            <h1 className="text-sm">Custom</h1>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <-------------------SAvings------------------> */}
        {activeTab === "Savings" && (
          <div>
            <MonthlySavings />
          </div>
        )}

        {/* <-----------------Alerts-----------------------------> */}

        {activeTab === "Alerts" && (
          <div className="animate__animated animate__fadeIn">
            <div className="flex flex-row p-5 text-2xl font-semibold text-left rtl:text-right text-gray-900 dark:text-white dark:bg-gray-800 items-center">
              Alerts for Past 7 Days
            </div>
            <div className="flex gap-3 mt-3">
              <div>
                <label
                  htmlFor="alertType"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Include Alert Type
                </label>
                <select
                  id="alertType"
                  value={selectedAlertType}
                  onChange={(e) => setSelectedAlertType(e.target.value)}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-${color}-500 focus:border-${color}-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-${color}-500 dark:focus:border-${color}-500`}
                >
                  <option defaultValue value="">
                    Select Alert Type
                  </option>
                  <option value="V">Very High Flow [high]</option>
                  <option value="P">Pump Disabled Irrigation [high]</option>
                  <option value="D">Disabled Irrigation [high]</option>
                  <option value="H">High Flow [low]</option>
                  <option value="L">Low Flow [low]</option>
                  <option value="F">Zone Overcurrent</option>
                  <option value="M">Manual Run Detected</option>
                  <option value="E">Manual Run Ended</option>
                  <option value="U">Unscheduled Flow Detected</option>
                  <option value="CA">Server Custom Program Added</option>
                  <option value="CD">Server Custom Program Deleted</option>
                  <option value="SD">Server Clock Skew or Progmod</option>
                  <option value="NF">Server No Feedback Reported</option>
                  <option value="NFZ">Server No Feedback on Some Zones</option>
                  <option value="IF">Server Inconsistent Feedback</option>
                  <option value="MM">Server Multiple Manual Events</option>
                  <option value="UW">Server Unauthorized Watering</option>
                  <option value="OFF">Server Offline Devices</option>
                </select>
              </div>

              <div>
                <label
                  htmlFor="sevLevel"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Sev Level
                </label>
                <select
                  id="sevLevel"
                  value={selectedSevLevel}
                  onChange={(e) => setSelectedSevLevel(e.target.value)}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-${color}-500 focus:border-${color}-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-${color}-500 dark:focus:border-${color}-500`}
                >
                  <option defaultValue value="">
                    Choose Alert Sev Level
                  </option>
                  <option value="high">High</option>
                  <option value="low">Low</option>
                  <option value="info">Info</option>
                  <option value="unknown">Unknown</option>
                </select>
              </div>
              <div>
                <label
                  htmlFor="start-date-input"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Date
                </label>
                <button
                  id="dropdownStartDateButton"
                  className={`flex items-center border-2 bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-${color}-500 focus:border-${color}-500 w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-${color}-500 dark:focus:border-${color}-500`}
                  type="button"
                  onClick={() => toggleDropdown("selectStartDate")}
                >
                  <h1 onChange={handleStartDateChange}>
                    {formatDate(selectedDate.startDate) || "Select Start Date"}{" "}
                    - {formatDate(selectedDate.endDate) || ""}
                  </h1>
                </button>

                <div
                  id="dropdownStartDate"
                  className={`z-10 ${
                    selectStartDate
                      ? "block absolute animate__animated "
                      : "hidden"
                  } flex items-center bg-white divide-y divide-gray-100 rounded-md shadow dark:bg-gray-700 mt-1`}
                >
                  <div className="relative">
                    <DatePicker
                      selected={selectedDate.startDate}
                      onChange={handleStartDateChange}
                      dateFormat="yyyy-MM-dd"
                      className="cursor-pointer items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholderText="Select start date"
                    />
                  </div>
                  <div className="px-5 text-black dark:text-white">
                    <h1> to </h1>
                  </div>
                  <div className="relative">
                    <DatePicker
                      selected={selectedDate.endDate}
                      onChange={handleEndDateChange}
                      dateFormat="yyyy-MM-dd"
                      className="cursor-pointer items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholderText="Select end date"
                    />
                  </div>
                </div>
              </div>

              <div>
                <button
                  className={`text-white mt-7 font-medium rounded-md text-sm px-5 py-2.5 text-center me-2 mb-2 bg-${color}-600 hover:bg-${color}-800`}
                  style={{
                    backgroundColor: `${backgroundColor}`,
                    color: `${textColor}`,
                  }}
                  onClick={handleSearchClick}
                  disabled={alertLoading && true}
                >
                  Search
                </button>
              </div>
            </div>

            <div className="relative overflow-x-auto mb-4 mt-4">
              {alertLoading ? (
                <table className="w-full">
                  <tbody>
                    <tr>
                      <td colSpan="7" className="h-64">
                        <div
                          role="status"
                          className="flex justify-center items-center h-full"
                        >
                          <svg
                            aria-hidden="true"
                            className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span className="sr-only">Loading...</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : getBinAlertsData.length > 0 ? (
                Object.entries(
                  groupByCompanyLocationDeviceId(
                    getBinAlertsData.filter(
                      (alert) =>
                        selectedDeviceId &&
                        alert.company_location_device_id ===
                          selectedDeviceId.company_location_device_id
                    )
                  )
                ).map(([id, alerts]) => (
                  <table
                    key={id}
                    className="border animate__animated animate__fadeIn w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 cursor-pointer mb-8"
                  >
                    <caption
                      className={`border border-black p-2 text-lg font-semibold text-left rtl:text-right text-${color}-800 bg-${color}-200 dark:bg-${color}-200`}
                      style={{
                        backgroundColor: `${tableHeaderColor}`,
                        color: `${tableHeaderTextColor}`,
                      }}
                    >
                      <h1 className="ml-4">
                        {
                          new Date(alerts[0].alert_timestampt)
                            .toISOString()
                            .split("T")[0]
                        }{" "}
                        - {alerts[0].company_name}
                      </h1>
                    </caption>
                    <tbody className="text-sm text-black dark:text-white font-inter leading-4">
                      {alerts.map((binAlert) => (
                        <tr
                          key={binAlert.id}
                          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                        >
                          <td className="border border-black dark:border-white px-6 py-4">
                            {binAlert.location_name}
                          </td>

                          <td className="border border-black dark:border-white px-6 py-4 hover:underline">
                            {/* <Link
                              to={`/devices/${binAlert.company_location_device_id}`}
                            > */}
                            {binAlert.serial_number}
                            {/* </Link> */}
                          </td>

                          <td className="border border-black dark:border-white px-6 py-4">
                            {binAlert.device_identifier}
                          </td>
                          <td className="border border-black dark:border-white px-6 py-4">
                            {binAlert.alert_timestampt}
                          </td>
                          <td className="border border-black dark:border-white px-6 py-4">
                            {binAlert.severity}
                          </td>
                          <td className="border border-black dark:border-white px-6 py-4">
                            {binAlert.source}
                          </td>
                          <td className="border border-black dark:border-white px-6 py-4">
                            {alertTypeMap[binAlert.alert_type]}
                          </td>
                          <td
                            className="border border-black dark:border-white px-6 py-4"
                            dangerouslySetInnerHTML={{
                              __html: binAlert.message,
                            }}
                          />
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ))
              ) : (
                <table className="w-full">
                  <tbody>
                    <tr>
                      <td colSpan="7" className="text-center py-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="w-8 h-8 mx-auto text-gray-500 dark:text-gray-400"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <line x1="12" y1="8" x2="12" y2="12"></line>
                          <line x1="12" y1="16" x2="12.01" y2="16"></line>
                        </svg>
                        <p className="animate__animated animate__fadeIn mt-2 text-sm text-gray-500 dark:text-gray-400">
                          No bin alerts found
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
