import React, { useEffect, useState, useRef, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import logopicture from "../assets/Group-2394-2.png";
import FloatingSettings from "./FloatingSettings";
import { ThemeContext } from "./context/themeContext/ThemeContext";
import "animate.css";
import { useAuth } from "./context/authContext/AuthContext";
import LocationInfo from "../pages/ADMINMENU/LocationInfo/LocationInfo";
import LocationControlPanel from "../pages/ADMINMENU/LocationInfo/LocationControlPanel";
import LocationAuditActivityLogs from "../pages/ADMINMENU/LocationInfo/LocationAuditActivityLogs";
import LocationLeakDetection from "../pages/ADMINMENU/LocationInfo/LocationLeakDetection";
import LocationDevices from "../pages/ADMINMENU/LocationInfo/LocationDevices";
import { useCompanyLocation } from "./context/apiContext/CompanyLocationContext";
import LocationChangePassword from "../pages/ADMINMENU/LocationInfo/AvatarDropdownContent/LocationChangePassword";
import { useSelectedCompany } from "./context/apiContext/SelectedCompanyContext";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import ApiLink from "./api/ApiLink";
import LocationBillingHistory from "../pages/ADMINMENU/LocationInfo/LocationBillingHistory";
import { useDispatch } from "react-redux";
import { setSelectedLocation } from "../store/selectedCompanySlice";
import { logoutAndClearState } from "../store/logoutAndClearState";
import Swal from "sweetalert2";
import CompanyType8 from "../pages/ADMINMENU/AcountInfo/CompanyType8";

export default function LocationAside() {
  const history = useHistory();

  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);
  const userType = useSelector((state) => state.auth.userType);
  const company_location_id = useSelector(
    (state) => state.auth.company_location_id
  );

  const { updateSelectedLocation, selectedCompanyName } = useSelectedCompany();
  const dispatch = useDispatch();

  const { logoutUser, username, lastname } = useAuth();
  const [accountDropdown, setAccountDropdown] = useState(true);
  const [companyNames, setCompanyNames] = useState("");
  const [neym, setNeym] = useState("");

  const [company_location_data, setcompany_location_data] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(() => {
    return sessionStorage.getItem("activeItemLocation") || "dashboard"; // Default to dashboard
  });
  const sidebarRef = useRef(null);
  const [avatarDropdown, setAvatarDropdown] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const { color, textColor, backgroundColor, sideBarOpen, setSideBarOpen } =
    useContext(ThemeContext);
  const {
    selectedLocation,
    setSelectedLocation,
    viewLocationCompanyLocation,
    updateSelectedCompanyName,
  } = useSelectedCompany();

  const {
    companyLocationData,
    setCompanyLocationData,
    CompanyLocationDetails,
  } = useCompanyLocation();

  const { companyName } = useParams();

  const [loadingAccountHome, setLoadingAccountHome] = useState(true);

  useEffect(() => {
    if (userType === 4) {
      const fetchCompany = async () => {
        try {
          const response = await ApiLink.post("/api/company-info", {
            email: email,
            password: password,
          });

          if (response.status === 200) {
            console.log("Account data:", response.data.Data);
            const {
              name: companyName,
              company_id,
              city,
              state,
              zip,
              type,
            } = response.data.Data.Company;

            updateSelectedCompanyName({
              companyName,
              company_id,
              city,
              state,
              zip,
              type,
            });
          } else {
            console.error("Error:", response.data);
          }
        } catch (error) {
          console.error("Error:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchCompany();
    }
  }, [userType, email, password]);

  useEffect(() => {
    if (userType === 4) {
      const fetchCompanyLocation = async () => {
        try {
          const response = await ApiLink.post("/api/company-locations", {
            email: email,
            password: password,
            company_id: selectedCompanyName.company_id,
          });

          if (response.status === 200) {
            console.log("companylocation usertype4", response.data.Data);
            const firstLocation = response.data.Data.Company_location[0];
            if (firstLocation) {
              const updatedLocation = {
                locationId: firstLocation.company_location_id,
                locationName: firstLocation.name,
                companyId: firstLocation.company_id,
                city: firstLocation.city,
                state: firstLocation.state,
                zip: firstLocation.zip,
                address: firstLocation.address_line1,
              };

              // Update selectedLocation state
              updateSelectedLocation(updatedLocation);
              console.log("Updated selectedLocation:", updatedLocation);
            } else {
              console.log("No locations found.");
            }
          }
        } catch (error) {
          console.error("Error:", error);
        }
      };

      fetchCompanyLocation();
    }
  }, [email, password, selectedLocation.companyId]);

  useEffect(() => {
    const decodedCompanyName = companyName.replace(/-/g, " ");
  }, [companyName]);

  useEffect(() => {
    const fetchCompanyList = async () => {
      try {
        const response = await ApiLink.post("/api/company-list", {
          email: email,
          password: password,
          status: 1,
        });

        if (response.status === 200) {
          setCompanyData(response.data.Data);
          const company = response.data.Data.find(
            (company) => company.company_id === selectedLocation.companyId
          );

          if (company) {
            setCompanyNames(company.name);

            const companyname = company.name;
            setNeym(companyname);
          }
        } else {
          console.error("Error:", response.data);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoadingAccountHome(false);
      }
    };
    fetchCompanyList();
  }, [email, password, selectedLocation.companyId]);

  useEffect(() => {
    const fetchCompanyLocation = async () => {
      try {
        const response = await ApiLink.post("/api/company-locations", {
          email: email,
          password: password,
          company_id: selectedLocation.companyId,
        });

        if (response.status === 200) {
          if (Array.isArray(response.data.Data.Company_location)) {
            const companyLocationData = response.data.Data.Company_location;

            // Check if userType is 2 or 4
            if (userType === 2 || userType === 4) {
              const companyLocationIdString = company_location_id;

              if (companyLocationIdString !== null) {
                const validLocationIds = JSON.parse(companyLocationIdString);
                console.log("validLocationIds:", validLocationIds);

                const filteredLocations = companyLocationData.filter(
                  (location) =>
                    validLocationIds.includes(
                      location.company_location_id.toString()
                    )
                );

                setcompany_location_data(filteredLocations); // Update state with filtered data
              } else {
                setcompany_location_data(companyLocationData); // Set all data if company_location_id is null
              }
            } else {
              setcompany_location_data(companyLocationData); // Set all data if userType is not 2 or 4
            }
          } else {
            setcompany_location_data([]); // Set empty array if no company locations
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchCompanyLocation();
  }, [
    email,
    password,
    selectedLocation.companyId,
    userType,
    company_location_id,
  ]);

  const [loading, setLoading] = useState(true); //loading screen for table accounts
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // After 2 seconds, set loading to false
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const savedTheme = localStorage.getItem("color-theme");
    if (
      savedTheme === "dark" ||
      (!savedTheme && window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      setIsDarkMode(true);
      document.documentElement.classList.add("dark");
    } else {
      setIsDarkMode(false);
      document.documentElement.classList.remove("dark");
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarVisible(false);
      }
    };

    if (sidebarVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarVisible]);

  useEffect(() => {
    const savedTheme = localStorage.getItem("color-theme");
    if (
      savedTheme === "dark" ||
      (!savedTheme && window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      setIsDarkMode(true);
      document.documentElement.classList.add("dark");
    } else {
      setIsDarkMode(false);
      document.documentElement.classList.remove("dark");
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarVisible(false);
      }
    };

    if (sidebarVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarVisible]);

  const toggleSidebar = () => {
    setSidebarVisible((prevSidebarVisible) => !prevSidebarVisible);
    setSideBarOpen((prevSideBarOpen) => !prevSideBarOpen);
  };

  const toggleAvatarDropdown = () => {
    setAvatarDropdown(!avatarDropdown);
  };

  const toggleDarkMode = () => {
    if (isDarkMode) {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("color-theme", "light");
    } else {
      document.documentElement.classList.add("dark");
      localStorage.setItem("color-theme", "dark");
    }
    setIsDarkMode(!isDarkMode);
  };

  const toggleAccountDropdown = () => {
    setAccountDropdown(!accountDropdown);
  };

  const handleItemClick = (item) => {
    setActiveItem(item);
  };
  const getColorCode = (color) => {
    switch (color) {
      case "blue":
        return "#1c64f2";
      case "green":
        return "#057a55";
      case "purple":
        return "#7e3af2";
      case "red":
        return "#FF0000";
      case "yellow":
        return "#9F580A";
      case "pink":
        return "#D61F69";
      default:
        return "#057a55";
    }
  };
  const clearCompanyDetails = () => {
    setCompanyLocationData([]);
    setcompany_location_data([]);
    sessionStorage.removeItem("activeItem");
    sessionStorage.removeItem("activeItemLocation");
  };

  useEffect(() => {}, [selectedLocation]);

  const handleAccountHomeClick = (
    companyName,
    company_id,
    city,
    state,
    zip
  ) => {
    updateSelectedCompanyName({
      companyName,
      company_id,
      city,
      state,
      zip,
    });
  };

  const handleLocationChange = (event) => {
    const selectedLocationId = event.target.value;
    const selectedLocationData = company_location_data.find(
      (location) =>
        location.company_location_id.toString() === selectedLocationId
    );

    if (selectedLocationData) {
      const newLocation = {
        locationId: selectedLocationData.company_location_id,
        locationName: selectedLocationData.name,
        companyId: selectedLocationData.company_id,
        city: selectedLocationData.city,
        state: selectedLocationData.state,
        zip: selectedLocationData.zip,
        address: selectedLocationData.address_line1,
      };

      dispatch(setSelectedLocation(newLocation));
      updateSelectedLocation(newLocation);
    }
  };

  useEffect(() => {
    const savedItem = sessionStorage.getItem("activeItemLocation");
    if (!savedItem) {
      setActiveItem("dashboard"); // Only set if no item is saved in session storage
    }
  }, [location]);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      const savedItem = sessionStorage.getItem("activeItemLocation");
      if (savedItem) {
        setActiveItem(savedItem);
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    sessionStorage.setItem("activeItemLocation", activeItem);
  }, [activeItem]);

  const handleLogout = () => {
    dispatch(logoutAndClearState()); // Dispatch both actions
    sessionStorage.removeItem("activeItemLocation");
    sessionStorage.removeItem("activeItem");
    localStorage.removeItem("authTokens");
  };

  useEffect(() => {
    console.log("SideBarOpen: ", sideBarOpen);
  }, []);

  const handleClickGenerateCode = () => {
    // Call the global showSessionCode function
    if (window.showSessionCode) {
      window.showSessionCode();
    } else {
      console.error("showSessionCode function not found");
    }
  };

  const handleGetSupportClick = () => {
    Swal.fire({
      html: `
        <h3 style="font-size: 18px; font-weight: bold; margin-bottom: 10px;">
          Choose an option to proceed
        </h3>
        <p style="font-size: 14px; margin-bottom: 20px;">
          Generate Code for immediate processing, or Create a Ticket for support.
        </p>
      `,
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: "Generate Code",
      denyButtonText: "Create a Ticket",
      confirmButtonColor: "#62CB31",
      denyButtonColor: "#337AB7",
      customClass: {
        popup: "swal-custom-popup",
        confirmButton: "swal-custom-confirm-button",
        denyButton: "swal-custom-deny-button",
        closeButton: "swal-custom-close-button",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleClickGenerateCode();
      } else if (result.isDenied) {
        history.push("/get-support");
      }
    });
  };

  return (
    <div>
      <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <div className="flex ms-2 md:me-24">
                <img
                  src={logopicture}
                  className="h-14 w-36 me-9"
                  alt="FlowBite Logo"
                />
                <button
                  onClick={toggleSidebar}
                  data-drawer-target="logo-sidebar"
                  data-drawer-toggle="logo-sidebar"
                  aria-controls="logo-sidebar"
                  type="button"
                  className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                >
                  <span className="sr-only">Open sidebar</span>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      fillRule="evenodd"
                      d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>

            <div className="relative">
              <div className="flex items-center">
                <div className={`hidden xl:block md:hidden`}>
                  <h1
                    className="text-3xl font-semibold mr-6"
                    style={{ color: "#9d9fa2" }}
                  >
                    Welcome {username} {lastname}
                  </h1>
                </div>

                <div className="p-1 mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill={`${textColor}`}
                    className="size-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.25 9a6.75 6.75 0 0 1 13.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 0 1-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 1 1-7.48 0 24.585 24.585 0 0 1-4.831-1.244.75.75 0 0 1-.298-1.205A8.217 8.217 0 0 0 5.25 9.75V9Zm4.502 8.9a2.25 2.25 0 1 0 4.496 0 25.057 25.057 0 0 1-4.496 0Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>

                <button
                  type="button"
                  className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600 "
                  aria-expanded="false"
                  data-dropdown-toggle="dropdown-user"
                  onClick={toggleAvatarDropdown}
                >
                  <span className="sr-only">Open user menu</span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="white"
                    className="size-6 w-8 h-8 rounded-full "
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                </button>

                {avatarDropdown && (
                  <div
                    className="absolute top-10 right-0 z-50 mt-2 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
                    id="dropdown-user"
                  >
                    <div className="px-4 py-3">
                      <p className="text-sm text-gray-900 dark:text-white">
                        {username} {lastname}
                      </p>
                      <p className="text-sm text-gray-900 truncate dark:text-gray-300">
                        {email}
                      </p>
                    </div>
                    <ul className="py-1 cursor-pointer" role="none">
                      <li onClick={() => setActiveItem("changepassword")}>
                        <div
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                          role="menuitem"
                        >
                          Change Password
                        </div>
                      </li>
                      <li onClick={handleGetSupportClick}>
                        <div
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                          role="menuitem"
                        >
                          Get Support
                        </div>
                      </li>

                      <li onClick={logoutUser}>
                        <div
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                          role="menuitem"
                        >
                          Logout
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>

      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 min-h-screen mt-4 pt-20 transition-transform overflow-y-auto ${
          sideBarOpen ? "-translate-x-0 sm:translate-x-0" : "-translate-x-full"
        } bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700 overflow-y-auto`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-4 overflow-y-auto bg-white dark:bg-gray-800 text-base font-inter leading-5 font-normal">
          <ul className="space-y-2 font-medium cursor-pointer">
            {userType !== 4 && (
              <li
                className={`pt-4 space-y-2 border-gray-500 dark:border-gray-300 items-center text-center font-normal ${
                  loadingAccountHome ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={() => {
                  if (!loadingAccountHome) {
                    handleAccountHomeClick(
                      companyNames,
                      selectedLocation.companyId,
                      selectedLocation.city,
                      selectedLocation.state,
                      selectedLocation.zip
                    );
                  }
                }}
              >
                {loadingAccountHome ? (
                  <div className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white group">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="size-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.793 2.232a.75.75 0 0 1-.025 1.06L3.622 7.25h10.003a5.375 5.375 0 0 1 0 10.75H10.75a.75.75 0 0 1 0-1.5h2.875a3.875 3.875 0 0 0 0-7.75H3.622l4.146 3.957a.75.75 0 0 1-1.036 1.085l-5.5-5.25a.75.75 0 0 1 0-1.085l5.5-5.25a.75.75 0 0 1 1.06.025Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="ms-3 uppercase">Loading...</span>
                  </div>
                ) : (
                  <Link
                    to={
                      selectedLocation?.type === 8
                        ? "/portfolio-account-home"
                        : userType === 2
                        ? `/accounts/${
                            selectedCompanyName?.companyName?.replace(
                              /\s+/g,
                              "-"
                            ) || ""
                          }`
                        : `/accounts/${companyNames.replace(/\s+/g, "-") || ""}`
                    }
                  >
                    <div className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="size-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.793 2.232a.75.75 0 0 1-.025 1.06L3.622 7.25h10.003a5.375 5.375 0 0 1 0 10.75H10.75a.75.75 0 0 1 0-1.5h2.875a3.875 3.875 0 0 0 0-7.75H3.622l4.146 3.957a.75.75 0 0 1-1.036 1.085l-5.5-5.25a.75.75 0 0 1 0-1.085l5.5-5.25a.75.75 0 0 1 1.06.025Z"
                          clipRule="evenodd"
                        />
                      </svg>

                      <span className="ms-3 uppercase">Account Home</span>
                    </div>
                  </Link>
                )}
              </li>
            )}

            {userType !== 2 && userType !== 4 && (
              <Link to={`/accounts`}>
                <li
                  className="space-y-2 border-b border-gray-500 dark:border-gray-300 items-center text-center font-normal"
                  onClick={clearCompanyDetails}
                >
                  <div className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                      />
                    </svg>

                    <span className="ms-3 uppercase">Admin menu</span>
                  </div>
                </li>
              </Link>
            )}
            <li>
              <ul id="dropdown-example" className="py-2 space-y-2">
                <div className="pb-1">
                  <li
                    style={
                      activeItem === "dashboard"
                        ? { backgroundColor, color: textColor }
                        : {}
                    }
                    className={`flex items-center w-full p-2 transition duration-75 rounded-2xl pl-11 group ${
                      activeItem === "dashboard"
                        ? ""
                        : "text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                    }`}
                    onClick={() => setActiveItem(`dashboard`)}
                  >
                    <div className="flex justify-center gap-2 items-center text-center font-normal">
                      <div className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z"
                          />
                        </svg>
                      </div>
                      <h1 className="whitespace-nowrap">Dashboard</h1>
                    </div>
                  </li>
                </div>

                <div className="pb-1">
                  <li
                    style={
                      activeItem === "controlpanel"
                        ? { backgroundColor, color: textColor }
                        : {}
                    }
                    className={`flex items-center w-full p-2 transition duration-75 rounded-2xl pl-11 group ${
                      activeItem === "controlpanel"
                        ? ""
                        : "text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                    }`}
                    onClick={() => setActiveItem(`controlpanel`)}
                  >
                    <div className="flex justify-center gap-2 items-center text-center font-normal">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21.75 6.75a4.5 4.5 0 0 1-4.884 4.484c-1.076-.091-2.264.071-2.95.904l-7.152 8.684a2.548 2.548 0 1 1-3.586-3.586l8.684-7.152c.833-.686.995-1.874.904-2.95a4.5 4.5 0 0 1 6.336-4.486l-3.276 3.276a3.004 3.004 0 0 0 2.25 2.25l3.276-3.276c.256.565.398 1.192.398 1.852Z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.867 19.125h.008v.008h-.008v-.008Z"
                          />
                        </svg>
                      </div>
                      <h1 className="whitespace-nowrap">Control Panel</h1>
                    </div>
                  </li>
                </div>

                {userType !== 2 && userType !== 4 && (
                  <div className="pb-1">
                    <li
                      style={
                        activeItem === "activitylogs"
                          ? { backgroundColor, color: textColor }
                          : {}
                      }
                      className={`flex items-center w-full p-2 transition duration-75 rounded-2xl pl-11 group ${
                        activeItem === "activitylogs"
                          ? ""
                          : "text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      }`}
                      onClick={() => setActiveItem(`activitylogs`)}
                    >
                      <div className="flex justify-center gap-2 items-center text-center font-normal">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5m.75-9 3-3 2.148 2.148A12.061 12.061 0 0 1 16.5 7.605"
                            />
                          </svg>
                        </div>
                        <h1 className="whitespace-nowrap">Activity Logs</h1>
                      </div>
                    </li>
                  </div>
                )}

                <div className="pb-1">
                  <li
                    style={
                      activeItem === "leakdetection"
                        ? { backgroundColor, color: textColor }
                        : {}
                    }
                    className={`flex items-center w-full p-2 transition duration-75 rounded-2xl pl-11 group ${
                      activeItem === "leakdetection"
                        ? ""
                        : "text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                    }`}
                    onClick={() => setActiveItem(`leakdetection`)}
                  >
                    <div className="flex justify-center gap-2 items-center text-center font-normal">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z"
                          />
                        </svg>
                      </div>
                      <h1 className="whitespace-nowrap">Leak Detection</h1>
                    </div>
                  </li>
                </div>
                <li
                  style={
                    activeItem === "devices"
                      ? { backgroundColor, color: textColor }
                      : {}
                  }
                  className={`flex items-center w-full p-2 transition duration-75 rounded-2xl pl-11 group ${
                    activeItem === "devices"
                      ? ""
                      : "text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  }`}
                  onClick={() => setActiveItem(`devices`)}
                >
                  <div className="flex justify-center gap-2 items-center text-center font-normal">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 20.25h12m-7.5-3v3m3-3v3m-10.125-3h17.25c.621 0 1.125-.504 1.125-1.125V4.875c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125Z"
                        />
                      </svg>
                    </div>
                    <h1 className="whitespace-nowrap">Devices</h1>
                  </div>
                </li>

                {userType !== 2 && userType !== 4 && (
                  <li
                    style={
                      activeItem === "billinghistory"
                        ? { backgroundColor, color: textColor }
                        : {}
                    }
                    className={`flex items-center w-full p-2 transition duration-75 rounded-2xl pl-11 group ${
                      activeItem === "billinghistory"
                        ? ""
                        : "text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                    }`}
                    onClick={() => setActiveItem(`billinghistory`)}
                  >
                    <div className="flex justify-center gap-2 items-center text-center font-normal">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
                          />
                        </svg>
                      </div>
                      <h1 className="whitespace-nowrap">Billing History</h1>
                    </div>
                  </li>
                )}
              </ul>
            </li>
          </ul>
        </div>
      </aside>
      <FloatingSettings toggleDarkMode={toggleDarkMode} />

      <div
        className="maincontent min-h-screen bg-gray-100 dark:bg-gray-700 font-inter"
        style={{ marginLeft: sideBarOpen ? "16rem" : "0" }}
      >
        <div className="p-4 mt-20">
          <div className="font-medium p-2 bg-white dark:bg-gray-800 mb-2 shadow-md rounded-md">
            {loading ? (
              <div role="status" className="flex items-center">
                <svg
                  aria-hidden="true"
                  className="w-7 h-7 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <div className="flex items-center justify-between p-2 text-gray-900 rounded-lg dark:text-white group">
                <div className="flex flex-col text-left">
                  <div>
                    <h1 className="font-bold text-md ">
                      Location: {selectedLocation.locationName}
                    </h1>
                    <h1 className="text-sm">
                      Location ID: {selectedLocation.locationId}
                    </h1>
                  </div>
                  <div>
                    <h1 className="text-sm">
                      {selectedLocation.address}, {selectedLocation.city},{" "}
                      {selectedLocation.state}, {selectedLocation.zip}
                    </h1>
                  </div>
                </div>
                <div className="pr-2.5">
                  <select
                    id="countries"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    defaultValue="Change Location"
                    onChange={handleLocationChange}
                  >
                    <option value="Change Location" disabled>
                      Change Location
                    </option>
                    {company_location_data.map((location) => (
                      <option
                        key={location.company_location_id}
                        value={location.company_location_id}
                      >
                        {location.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
          </div>

          <div className="p-4 shadow-md bg-white border-gray-200 border-dashed rounded-lg dark:border-gray-700 dark:bg-gray-800">
            {activeItem === "dashboard" && <LocationInfo />}
            {activeItem === "controlpanel" && <LocationControlPanel />}
            {activeItem === "activitylogs" && <LocationAuditActivityLogs />}
            {activeItem === "leakdetection" && <LocationLeakDetection />}
            {activeItem === "devices" && <LocationDevices />}
            {activeItem === "changepassword" && <LocationChangePassword />}
            {activeItem === "billinghistory" && <LocationBillingHistory />}
            {activeItem === "companyType8" && <CompanyType8 />}
          </div>
        </div>
      </div>
    </div>
  );
}
