import React, { useEffect, useState, useContext, useRef } from "react";
import Aside from "../../../components/Aside";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useSelectedCompany } from "../../../components/context/apiContext/SelectedCompanyContext";
import ApiLink from "../../../components/api/ApiLink";
import { useSelector } from "react-redux";
import { ThemeContext } from "../../../components/context/themeContext/ThemeContext";
import FlowVisual from "../../../components/charts/DeviceDetails/FlowVisual";
import PressureVisual from "../../../components/calendar/PressureVisual";
import Swal from "sweetalert2";

export default function DeviceDetails() {
  const { deviceID } = useParams();

  const { selectedSerialNumber, updateSelectedLocation } = useSelectedCompany();
  const [getDeviceIdData, setGetDeviceIdData] = useState([]);

  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);

  const [ldid, setldid] = useState("");
  const [hbData, setHbData] = useState([]);

  const {
    backgroundColor,
    color,
    textColor,
    tableHeaderColor,
    tableHeaderTextColor,
    sideBarOpen,
  } = useContext(ThemeContext);
  const [accordionHB, setAccordionHB] = useState(false);
  const [accordionHeader, setAccordionHeader] = useState(false);
  const [alertsModal, setAlertsModal] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [zoneListLoading, setZoneListLoading] = useState(false);
  const [getDeviceAlertsData, setGetDeviceAlertsData] = useState([]);
  const [alertsData, setAlertsData] = useState([]);
  const [zonePrograms, setZonePrograms] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [dateHeaders, setDateHeaders] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedAlerts, setSelectedAlerts] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [maxZones, setMaxZones] = useState(0);
  const [zoneVisualData, setZoneVisualData] = useState({});
  const [deviceProgramModal, setDeviceProgramModal] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [zoneValues, setZoneValues] = useState({});
  const dropdownRef = useRef(null);
  const [searchInputs, setSearchInputs] = useState({});
  const [uploadedImages, setUploadedImages] = useState([]);
  const [zoneLabels, setZoneLabels] = useState({});
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [imagePreviews, setImagePreviews] = useState({});
  const [hasFlowSensor, setHasFlowSensor] = useState(false);
  const [flowData, setFlowData] = useState(null);
  const [hasflowSensorGraph, setHasflowSensorGraph] = useState(false);
  const [hasPressureSensor, setHasPressureSensor] = useState(false);
  const [pressureJsonString, setPressureJsonString] = useState(null);
  const [debugDateClick, setDebugDateClicked] = useState(false);
  const [debugDetailClick, setDebugDetailClick] = useState(false);

  const [activePopover, setActivePopover] = useState(null);
  const [activeDetailPopover, setActiveDetailPopover] = useState(null);

  const [firmwarePackage, setFirmwarePackage] = useState({});
  const [scheduleDebugData, setScheduleDebugData] = useState([]);
  const [companyname, setCompanyName] = useState("");

  const [alertsDetails, seAlertsDetails] = useState({
    timestamp: null,
    code: null,
    decisionDetails: null,
  });

  const [zoneListData, setZoneListData] = useState({ Programs: {}, Zones: {} });

  const [selectedProgramDetails, setSelectedProgramDetails] = useState({
    program: null,
    start: null,
    end: null,
    zone: null,
    type: null,
    days: null,
  });

  const [zoneCount, setZoneCount] = useState(0);
  const [firmwareVersion, setFirmwareVersion] = useState(null);

  const [locationDetails, setLocationDetails] = useState({
    name: null,
    company_location_id: null,
    company_id: null,
    address_line1: null,
    city: null,
    state: null,
    zip: null,
    company_location_id: null,
    company_name: "",
  });

  const [editDeviceModal, setEditDeviceModal] = useState(false);

  const [editDeviceModalDetails, setEditDeviceModalDetails] = useState({
    device_id: null,
    firmwarePackage: null,
    device_identifier: null,
    ota_enabled: null,
    type: null,
    ota_last_request: null,
    model: null,
    last_firmware: null,
    firmware_package_id: null,
    status: null,
    sim_phone_number: null,
    modem_number: null,
    sim_carrier: null,
    notes: null,
    serial_number: null,
  });

  const fileInputRef = useRef(null);

  console.log("DEVICE ID: ", deviceID);
  useEffect(() => {
    const fetchGetDeviceID = async () => {
      setLoader(true);
      try {
        const response = await ApiLink.post("/api/get-device-id", {
          email,
          password,
          device_id: deviceID,
        });

        if (response.status === 200) {
          // Check if response.data.Data exists and is an array
          if (Array.isArray(response.data.Data)) {
            setGetDeviceIdData(response.data.Data);
          } else if (response.data.Data) {
            setGetDeviceIdData([response.data.Data]);
          } else {
            setGetDeviceIdData([]);
          }
        } else {
          console.error("Unexpected response status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching device ID data:", error);
      }
    };

    fetchGetDeviceID();
  }, [email, password, selectedSerialNumber.deviceId]);

  useEffect(() => {
    

    fetchDeviceVisual();
  }, [email, password, selectedSerialNumber.deviceId]);
  const fetchDeviceVisual = async () => {
    try {
      const response = await ApiLink.post("/api/device-visual", {
        email: email,
        password: password,
        device_id: deviceID,
      });

      if (response.status === 200) {
        console.log("Response data:", response.data.Data); // Log the Data object

        console.log("Device:", response.data.Data.device);
        console.log("Heartbeats (hbs):", response.data.Data.hbs);
        console.log("Location Info:", response.data.Data.locationInfo);
        console.log("LDID: ", response.data.Data.ldid);
        //
        console.log(
          "Device_id devicevisual: ",
          response.data.Data.device.device_id
        );
        console.log("FirmwarePackage: ", response.data.Data.device.firmware);
        console.log(
          "Friendly Device Name: ",
          response.data.Data.device.device_identifier
        );
        console.log("OTA enabled:", response.data.Data.device.ota_enabled);
        console.log("Device Type: ", response.data.Data.device.type);
        console.log(
          "OTA Last request: ",
          response.data.Data.device.ota_last_request
        );
        console.log("deviceModel: ", response.data.Data.device.model);
        console.log(
          "Last Firmware: ",
          response.data.Data.device.last_firmware
        );
        console.log(
          "Firmware Package Id: ",
          response.data.Data.device.firmware_package_id
        );
        console.log("Status: ", response.data.Data.device.status);
        console.log(
          "sim_phone_number: ",
          response.data.Data.device.sim_phone_number
        );
        console.log("modem_number: ", response.data.Data.device.modem_number);
        console.log("sim_carrier: ", response.data.Data.device.sim_carrier);
        console.log("notes: ", response.data.Data.device.notes);

        setEditDeviceModalDetails({
          device_id: response.data.Data.device.device_id,
          firmwarePackage: response.data.Data.device.firmware,
          device_identifier: response.data.Data.device.device_identifier,
          ota_enabled: response.data.Data.device.ota_enabled,
          type: response.data.Data.device.type,
          ota_last_request: response.data.Data.device.ota_last_request,
          model: response.data.Data.device.model,
          last_firmware: response.data.Data.device.last_firmware,
          firmware_package_id: response.data.Data.device.firmware_package_id,
          status: response.data.Data.device.status,
          sim_phone_number: response.data.Data.device.sim_phone_number,
          modem_number: response.data.Data.device.modem_number,
          sim_carrier: response.data.Data.device.sim_carrier,
          notes: response.data.Data.device.notes,
          serial_number: response.data.Data.device.serial_number,
        });
        //

        setldid(response.data.Data.ldid);
        setHbData(response.data.Data.hbs);
        const companyID = response.data.Data.locationInfo.company_id;
        if (companyID) {
          try {
            const response = await ApiLink.post("/api/company-list", {
              email: email,
              password: password,
              status: 1,
            });

            if (
              response.status === 200 &&
              response.data &&
              response.data.Data
            ) {
              // Find the company with the matching companyID
              const company = response.data.Data.find(
                (company) => company.company_id == companyID
              );

              if (company) {
                console.log("Company name:", company.name);
                setCompanyName(company.name);
              } else {
                console.log("No company found with the given ID");
              }
            } else {
              console.log("Invalid response from server");
            }
          } catch (error) {
            console.error("Error fetching company info:", error);
            // Handle error case appropriately
          }
        }
        setLocationDetails({
          name: response.data.Data.locationInfo.name,
          company_location_id:
            response.data.Data.locationInfo.company_location_id,
          company_id: response.data.Data.locationInfo.company_id,
          address_line1: response.data.Data.locationInfo.address_line1,
          city: response.data.Data.locationInfo.city,
          state: response.data.Data.locationInfo.state,
          zip: response.data.Data.locationInfo.zip,
          company_location_id:
            response.data.Data.locationInfo.company_location_id,
        });
      }
    } catch (error) {
      console.log("Error fetching device visual data:", error);
    }
  };

  useEffect(() => {
    if (locationDetails.company_name) {
      console.log(
        "COMPANY NAME FROM COMPANY INFO: ",
        locationDetails.company_name
      );
    }
  }, [locationDetails.company_name]);

  useEffect(() => {
    setLoading(true);

    const fetchZoneVisual = async () => {
      setZoneListLoading(true);
      try {
        const today = new Date();
        const fiveDaysAgo = new Date(today.getTime() - 5 * 24 * 60 * 60 * 1000); // 5 days ago including today
        const dateHeaders = [];
        const alertsData = {};
        let fifthDateFlowData = null;
        let flowSensorGraph = false;

        for (
          let date = fiveDaysAgo;
          date < today; // Include today
          date.setDate(date.getDate() + 1)
        ) {
          const formattedDate = date.toISOString().split("T")[0];
          dateHeaders.push(formattedDate);

          // Fetch data for each day
          const response = await ApiLink.post("/api/zone-visual", {
            email,
            password,
            company_location_device_id: ldid,
            date: formattedDate,
          });

          const dailyAlerts = response.data.Data.alerts[formattedDate] || [];
          alertsData[formattedDate] = dailyAlerts;
          const zoneVisualData = response.data.Data.zoneVisual[formattedDate];
          setZoneVisualData((prevState) => ({
            ...prevState,
            [formattedDate]: zoneVisualData,
          }));
          setZoneCount(response.data.Data.zoneCount);

          setHasFlowSensor(response.data.Data.hasFlowSensor);
          console.log("Has Flow Sensor: ", response.data.Data.hasFlowSensor);

          if (dateHeaders.length === 5) {
            flowSensorGraph = response.data.Data.hasFlowSensor;
            fifthDateFlowData = response.data.Data.flow[formattedDate];

            console.log("Flow Sensor Graph: ", flowSensorGraph);

            setPressureJsonString(response.data.Data.pressureJson);
            setHasPressureSensor(response.data.Data.hasPressureSensor);
            console.log(
              "DATA: ",
              response.data.Data.pressureJson,
              response.data.Data.hasPressureSensor
            );

            setFlowData(fifthDateFlowData);
            setHasflowSensorGraph(flowSensorGraph);
          }
        }

        // Sort dateHeaders in descending order
        dateHeaders.sort((a, b) => new Date(b) - new Date(a));

        setDateHeaders(dateHeaders);
        setAlertsData(alertsData);
      } catch (error) {
        console.error(error);
      } finally {
        setZoneListLoading(false);
        setLoading(false);
      }
    };

    fetchZoneVisual();
  }, [email, password, ldid]);

  useEffect(() => {
    setLoading(true);
    const fetchZoneList = async () => {
      try {
        const response = await ApiLink.post("/api/zone-list", {
          email: email,
          password: password,
          company_location_device_id: ldid,
        });

        if (response.status === 200 && response.data.Data) {
          setZoneListData(response.data.Data);
        }
      } catch (error) {
        console.error("Error fetching zone list:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchZoneList();
  }, [email, password, ldid]);

  useEffect(() => {
    const fetchUploadImage = async () => {
      try {
        const response = await ApiLink.post("/api/get-upload-image", {
          email: email,
          password: password,
          company_location_device_id: ldid,
        });

        if (response.status === 200 && response.data.Data) {
          console.log("Upload image:", response.data.Data);
          setUploadedImages(response.data.Data.image_data);
        }
      } catch (error) {
        console.error("Error fetching upload image:", error);
      }
    };
    fetchUploadImage();
  }, [email, password, ldid]);

  useEffect(() => {
    const fetchZoneLabels = async () => {
      try {
        const response = await ApiLink.post("/api/company-location-device", {
          email: email,
          password: password,
          company_location_id: locationDetails.company_location_id,
        });

        if (
          response.status === 200 &&
          response.data.Data &&
          response.data.Data.length > 0
        ) {
          const zoneLabelsData = JSON.parse(response.data.Data[0].zone_labels);
          setZoneLabels(zoneLabelsData);
          console.log("Zone Labels:", zoneLabelsData);
        }
      } catch (error) {
        console.error("Error fetching zone labels:", error);
      }
    };

    fetchZoneLabels();
  }, [email, password, locationDetails.company_location_id]);

  useEffect(() => {
    ApiLink.get("/api/firmware-package")
      .then((response) => {
        setFirmwarePackage(response.data.FirmwarePackage);
      })
      .catch((error) => {
        console.error("Error fetching firmware package:", error);
      });
  }, []);

  useEffect(() => {
    ApiLink.get("/api/firmware-version")
      .then((response) => {
        setFirmwareVersion(response.data.FirmwareVersion);
      })
      .catch((error) => {});
  }, []);

  const calculateDuration = (start, end) => {
    const startTime = new Date(`2000-01-01T${start}`);
    const endTime = new Date(`2000-01-01T${end}`);
    if (endTime < startTime) endTime.setDate(endTime.getDate() + 1);
    return Math.round((endTime - startTime) / 60000);
  };
  const getProgramType = (schedule) => {
    if (schedule.is_regular === 1) return "Regular";
    if (schedule.is_half_sprinkle === 1) return "Half Sprinkle";
    if (schedule.is_next_day == 1) return "is Day Overlap";
    return "Default";
  };

  const formatDays = (days) => {
    const dayNames = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    return dayNames
      .filter((day) => days[day.toLowerCase()] === 1)
      .map((day) => (
        <span key={day} className="block">
          {day}
        </span>
      ));
  };
  const handleSelectedProgramDetails = (
    program,
    start,
    end,
    zone,
    type,
    days
  ) => {
    console.log(
      "SelectedProgramDetails: ",
      program,
      start,
      end,
      zone,
      type,
      days
    );

    setDeviceProgramModal(true);
    setSelectedProgramDetails({
      program: program,
      start: start,
      end: end,
      zone: zone,
      type: type,
      days: days,
    });
  };

  const formatZones = (zones, uploadedImages, zoneLabels = {}, program) => {
    return zones.map((zone, index) => {
      const [zoneName, duration] = Object.entries(zone)[0];
      const zoneNumber = parseInt(zoneName.replace("Zone", "").trim());

      const customLabel = (zoneLabels && zoneLabels[`zone${zoneNumber}`]) || "";

      const displayName = customLabel
        ? `${zoneName}: ${customLabel}`
        : zoneName;

      const isZoneA = program === "A";

      const matchingImage = uploadedImages.find(
        (img) => img.zone_number === zoneNumber
      );

      const imageElement = isZoneA ? (
        matchingImage ? (
          <img
            src={matchingImage.image_url}
            alt={displayName}
            className="w-10 h-10 mr-2 cursor-pointer"
            onClick={() => toggleFullScreenImage(matchingImage.image_url)}
          />
        ) : (
          <div>
            <input
              className="hidden"
              id={`file_input_${index}`}
              type="file"
              accept="image/*"
              onChange={(e) => handleImageChange(e, index)}
            />
            <div
              onClick={() =>
                document.getElementById(`file_input_${index}`).click()
              }
              className="cursor-pointer inline-flex items-center"
            >
              {imagePreviews[index] ? (
                <img
                  src={imagePreviews[index]}
                  alt="Selected"
                  className="w-6 h-6 ml-2"
                />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 ml-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15M9 12l3 3m0 0 3-3m-3 3V2.25"
                  />
                </svg>
              )}
            </div>
          </div>
        )
      ) : null;

      return (
        <div key={index} className="flex justify-between items-center mb-2">
          <h1>{`${displayName}: ${duration} minutes `}</h1>{" "}
          <div>{imageElement}</div>
        </div>
      );
    });
  };

  const toggleFullScreenImage = (imageUrl) => {
    setFullScreenImage(imageUrl);
  };

  const handleImageChange = (e, zoneIndex) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);

      // Update the image preview for the specific zone index
      setImagePreviews((prevPreviews) => ({
        ...prevPreviews,
        [zoneIndex]: imageUrl,
      }));
    }
  };

  function formatTimestamp(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000); // Multiply by 1000 to convert to milliseconds
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }

  const getBackgroundColorClass = (lastHb) => {
    const currentTime = new Date();
    const lastHbTime = new Date(lastHb);
    const timeDifference = (currentTime - lastHbTime) / (1000 * 60); // Difference in minutes

    if (timeDifference <= 30) {
      return "bg-[#62CB31]";
    } else if (timeDifference > 30 && timeDifference <= 60) {
      return "bg-orange-600";
    } else if (timeDifference > 60) {
      return "bg-red-600";
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Ensures two-digit month
    const day = date.getDate().toString().padStart(2, "0"); // Ensures two-digit day

    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0"); // Ensures two-digit minutes
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12; // Convert to 12-hour format

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}${ampm}`;
    return formattedDate;
  }

  const toggleAccordionHB = () => {
    setAccordionHB(!accordionHB);
  };

  const toggleAccordionHeader = (index) => {
    setAccordionHeader(accordionHeader === index ? null : index);
  };

  const toggleAlertsModal = (date) => {
    if (alertsModal) {
      // Close the modal
      setAlertsModal(false);
    } else {
      // Open the modal and set the alerts for the selected date
      setSelectedAlerts(alertsData[date] || []);
      setSelectedDate(date); // Store the selected date
      setAlertsModal(true);
    }
  };

  const toggleDetailsModal = (date) => {
    setSelectedDate(date);
    setDetailsModal(!detailsModal);
  };

  const daysOfWeek = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const toggleDropdown = (zone) => {
    setOpenDropdown(openDropdown === zone ? null : zone);
  };

  const getZoneValue = (zone) => {
    return zoneValues[`Zone${zone}`] !== undefined
      ? zoneValues[`Zone${zone}`]
      : "Select";
  };
  const handleZoneValueChange = (zone, value) => {
    setZoneValues((prev) => ({ ...prev, [`Zone${zone}`]: value }));

    const updatedZones = selectedProgramDetails.program.zones.map((z) => {
      if (Object.keys(z)[0].trim() === `Zone${zone}`) {
        return { [`Zone${zone}`]: value };
      }
      return z;
    });

    setSelectedProgramDetails({
      ...selectedProgramDetails,
      program: {
        ...selectedProgramDetails.program,
        zones: updatedZones,
      },
    });
    setOpenDropdown(null);
  };

  const DEVICE_TYPES = {
    0: "Raspberry Pi",
    1: "(S3) Renew Battery",
    2: "(S3) Home",
  };
  const handleLocationClick = (
    locationId,
    locationName,
    companyId,
    city,
    state,
    zip,
    address
  ) => {
    updateSelectedLocation({
      locationId,
      locationName,
      companyId,
      city,
      state,
      zip,
      address,
    });
  };

  function formatTimeTo12Hour(time) {
    const [hour, minute] = time.split(":");
    const period = +hour >= 12 ? "PM" : "AM";
    const formattedHour = +hour % 12 || 12;
    return `${formattedHour}:${minute} ${period}`;
  }

  const handleSvgClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setFullScreenImage(imageUrl);
    }
  };

  const toggleBodyScroll = (disable) => {
    if (disable) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    toggleBodyScroll(deviceProgramModal || alertsModal || detailsModal);
    return () => toggleBodyScroll(false);
  }, [deviceProgramModal, alertsModal]);

  const getFirmwareVersion = (firmwarePackageId) => {
    if (firmwarePackageId === null || firmwarePackageId === undefined) {
      return "?";
    }

    const matchingEntry = Object.entries(firmwarePackage).find(
      ([key, value]) => key === firmwarePackageId.toString()
    );

    if (matchingEntry) {
      return matchingEntry[1].split("/")[1].split("-")[1];
    }
    return "?";
  };

  const fetchScheduleDebug = async () => {
    try {
      const response = await ApiLink.post("/api/schedule-debug", {
        company_location_device_id: ldid,
        email: email,
        password: password,
      });

      if (response.status === 200) {
        setScheduleDebugData(response.data.Data);
        console.log(response.data.Data);
      } else {
        setScheduleDebugData([]);
      }
    } catch (error) {
      console.log("Error: ", error);
      setScheduleDebugData([]);
    }
  };

  useEffect(() => {
    fetchScheduleDebug();
  }, [ldid, email, password]);

  const handleDebugDateClick = (id) => {
    setDebugDateClicked(!debugDateClick);
    setActivePopover((prev) => (prev === id ? null : id)); // Toggle the specific popover
  };

  const handleDetailClick = (id) => {
    setDebugDetailClick(!debugDetailClick);
    setActiveDetailPopover((prev) => (prev === id ? null : id)); // Toggle the specific popover
  };

  const handleEditDeviceSubmit = (e) => {
    e.preventDefault();
    Swal
    .fire({
      title: "Do you want to update this device?",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#FF0000",
    })
    .then(async (result) => {
      
      if (result.isConfirmed) {
        try {
          const response = await ApiLink.post("/api/update-device", {
            email: email,
            password: password,
            device_id: editDeviceModalDetails.device_id,
            serial_number: editDeviceModalDetails.serial_number,
            device_identifier: editDeviceModalDetails.device_identifier,
            type: editDeviceModalDetails.type,
            model: editDeviceModalDetails.model,
            status: editDeviceModalDetails.status,
            modem_number: editDeviceModalDetails.modem_number,
            firmware_package_id: editDeviceModalDetails.firmware_package_id,
            ota_enabled: editDeviceModalDetails.ota_enabled,
            ota_last_request: editDeviceModalDetails.ota_last_request,
            firmware: editDeviceModalDetails.firmwarePackage,
            sim_phone_number: editDeviceModalDetails.sim_phone_number,
            sim_carrier: editDeviceModalDetails.sim_carrier,
            notes: editDeviceModalDetails.notes,
          });
  
          if (response.status === 200) {
            try {
              const zohoResponse = await ApiLink.post("/api/zoho-crm-update-device",{
                serial_number: editDeviceModalDetails.serial_number,
                device_identifier: editDeviceModalDetails.device_identifier,
                type: editDeviceModalDetails.type,
                model: editDeviceModalDetails.model,
                status: editDeviceModalDetails.status,
                modem_number: editDeviceModalDetails.modem_number,
                firmware_package_id: editDeviceModalDetails.firmware_package_id,
                ota_enabled: editDeviceModalDetails.ota_enabled,
                ota_last_request: editDeviceModalDetails.ota_last_request,
                firmware: editDeviceModalDetails.firmwarePackage,
                sim_phone_number: editDeviceModalDetails.sim_phone_number,
                sim_carrier: editDeviceModalDetails.sim_carrier,
                notes: editDeviceModalDetails.notes,
                recordid: editDeviceModalDetails.device_id
              })

              if (zohoResponse.data[0].code == "SUCCESS") {
                Swal.fire({
                  title: "Device Updated Successfully",
                  icon: "success",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
                setEditDeviceModal(false);
                fetchDeviceVisual();
              }
            } catch (error) {
              
            }

            console.log("response update: ", response.data);
          

        
          }
        } catch (error) {
          console.log("Error: ", error);
          Swal.fire({
            title: "Error Occured in updating device",
            icon: "error",
            toast: true,
            timer: 4000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      }
    })

  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const SIM_CARRIER = {
    0: "Verizon",
    1: "T-mobile",
    2: "Sprint",
    3: "AT&T",
  }

  const extractDecisionDetails = (details) => {
    const match = details.match(/\[DecisionDetails\] => ([^\n]+)/);
    return match ? match[1] : 'No Decision Details found';
  };
  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-700 transition-all duration-300">
      <Aside />

      <div
        className="p-4 mt-20 font-inter"
        style={{ marginLeft: sideBarOpen ? "16rem" : "0" }}
      >
        <div className="p-4 bg-white shadow-md rounded-md dark:bg-gray-800">
          <div
            className="flex justify-center px-5 py-3 text-gray-700 border border-gray-200 rounded-lg bg-gray-200 dark:bg-gray-800 dark:border-gray-700"
            aria-label="Breadcrumb"
          >
            <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
              <li className="inline-flex items-center">
                <Link to="/accounts">
                  <div className="cursor-pointer inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                    <svg
                      className="w-3 h-3 me-2.5"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                    </svg>
                    Admin Menu
                  </div>
                </Link>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    className="rtl:rotate-180 block w-3 h-3 mx-1 text-gray-400 "
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                  <Link to="/devices">
                    <div className="cursor-pointer ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">
                      Devices
                    </div>
                  </Link>
                </div>
              </li>
              <li aria-current="page">
                <div className="flex items-center">
                  <svg
                    className="rtl:rotate-180  w-3 h-3 mx-1 text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                  <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-white">
                    {selectedSerialNumber.deviceId}
                  </span>
                </div>
              </li>
            </ol>
          </div>
          <div className="relative overflow-x-auto sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-black dark:text-white">
              {Array.isArray(getDeviceIdData) && getDeviceIdData.length > 0 ? (
                getDeviceIdData.map((deviceIdData) => (
                  <tbody>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        PK ID / LDID
                      </th>
                      <td className="px-6 py-4">
                        {deviceIdData.device_id} / {ldid}
                      </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Serial Number
                      </th>
                      <td className="flex items-center px-6 py-4">
                        <div>
                          <svg
                            fill="#000000"
                            width="25px"
                            height="25px"
                            viewBox="-4 0 32 32"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <title>barcode</title>
                            <path d="M0 25.281h0.781v-18.563h-0.781v18.563zM2.344 25.281h1.531v-18.563h-1.531v18.563zM5.406 25.281h1.563v-18.563h-1.563v18.563zM8.5 25.281h3.125v-18.563h-3.125v18.563zM13.156 25.281h2.344v-18.563h-2.344v18.563zM17.031 25.281h1.563v-18.563h-1.563v18.563zM20.125 25.281h0.781v-18.563h-0.781v18.563zM22.469 25.281h1.531v-18.563h-1.531v18.563z"></path>
                          </svg>
                        </div>{" "}
                        {deviceIdData.serial_number}
                      </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Device
                      </th>
                      <td className="px-6 py-4">
                        <div>
                          <h1>{deviceIdData.device_identifier}</h1>
                          <p>{DEVICE_TYPES[deviceIdData.type]}</p>
                          <p>{deviceIdData.model}</p>
                        </div>
                      </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Location
                      </th>
                      <td className="px-6 py-4">
                        <div className="flex flex-col">
                          <Link
                            to={`/location/${companyname.replace(/\s+/g, "-")}`}
                          >
                            <div
                              className="font-semibold hover:underline"
                              onClick={() =>
                                handleLocationClick(
                                  locationDetails.company_location_id,
                                  locationDetails.name,
                                  locationDetails.company_id,
                                  locationDetails.city,
                                  locationDetails.state,
                                  locationDetails.zip,
                                  locationDetails.address_line1
                                )
                              }
                            >
                              {locationDetails.name}{" "}
                            </div>
                          </Link>
                          <div>
                            <h1 className="text-sm">
                              {locationDetails.address_line1},{" "}
                              {locationDetails.city}, {locationDetails.state},{" "}
                              {locationDetails.zip}
                            </h1>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Last Heart Beat
                      </th>
                      <td className="px-6 py-4 flex items-center ">
                        <div
                          className={`p-1 px-2 flex items-center w-fit text-white font-medium rounded-lg text-sm text-center me-2 mb-2 ${getBackgroundColorClass(
                            deviceIdData.last_hb
                          )}`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="size-4"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                            />
                          </svg>
                          <h1 className="">
                            {formatDate(deviceIdData.last_hb)}
                          </h1>
                        </div>
                      </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Phone Number
                      </th>
                      <td className="px-6 py-4">{deviceIdData.last_phone}</td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Sim Carrier
                      </th>
                      <td className="px-6 py-4">{SIM_CARRIER[deviceIdData.sim_carrier]}</td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Firmware
                      </th>
                      <td className="px-6 py-4">
                        {" "}
                        <p
                          className={`flex p-1.5 w-fit items-center justify-center sm:me-0 sm:mb-0 whitespace-nowrap focus:outline-none focus:ring-4 font-medium rounded-md text-sm text-center me-2 mb-2 ${
                            getFirmwareVersion(
                              deviceIdData.firmware_package_id
                            ) !== deviceIdData.last_firmware
                              ? "text-yellow-900 bg-yellow-300 focus:ring-yellow-300"
                              : "text-white focus:ring-green-300"
                          }`}
                          style={
                            getFirmwareVersion(
                              deviceIdData.firmware_package_id
                            ) === deviceIdData.last_firmware
                              ? { backgroundColor: "#62cb30" } // Apply custom green color if condition is met
                              : {}
                          }
                        >
                          {getFirmwareVersion(
                            deviceIdData.firmware_package_id
                          ) !== deviceIdData.last_firmware
                            ? `${getFirmwareVersion(
                                deviceIdData.firmware_package_id
                              )} / ${
                                deviceIdData.last_firmware == null
                                  ? "?"
                                  : deviceIdData.last_firmware
                              }`
                            : getFirmwareVersion(
                                deviceIdData.firmware_package_id
                              )}
                        </p>
                      </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Date Added
                      </th>
                      <td className="px-6 py-4">
                        {formatDate(deviceIdData.date_added)}
                      </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Actions
                      </th>
                      <td className="px-6 py-4">
                        <div className="flex flex-row gap-2">
                          <div className="px-2 flex flex-row p-1 bg-red-600 text-white rounded-lg items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="size-4"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                              />
                            </svg>
                            <h1>Heartbeat </h1>
                          </div>
                          <div
                            onClick={() => setEditDeviceModal(true)}
                            className="cursor-pointer px-2 flex flex-row p-1 bg-orange-500 text-white rounded-lg items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="size-5"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>

                            <h1>Edit</h1>
                          </div>
                          {editDeviceModal && (
                            <div
                              id="defaultModal"
                              tabindex="-1"
                              aria-hidden="true"
                              className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full font-inter"
                              style={{
                                // backdropFilter: "blur(5px)",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                              }}
                            >
                              <div className="relative p-4 w-full max-w-5xl h-full md:h-auto">
                                {/* <!-- Modal content --> */}
                                <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                                  {/* <!-- Modal header --> */}
                                  <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                      Edit Device
                                    </h3>
                                    <button
                                      type="button"
                                      className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                      data-modal-toggle="defaultModal"
                                      onClick={() => setEditDeviceModal(false)}
                                    >
                                      <svg
                                        aria-hidden="true"
                                        className="w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                          clipRule="evenodd"
                                        ></path>
                                      </svg>
                                      <span className="sr-only">
                                        Close modal
                                      </span>
                                    </button>
                                  </div>
                                  {/* <!-- Modal body --> */}
                                  <form onSubmit={handleEditDeviceSubmit}>
                                    <div className="grid gap-4 mb-4 sm:grid-cols-3">
                                      <div>
                                        <label
                                          htmlFor="name"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Serial Number
                                        </label>
                                        <input
                                          type="text"
                                          name="name"
                                          value={
                                            editDeviceModalDetails.serial_number
                                          }
                                          onChange={(e) =>
                                            setEditDeviceModalDetails({
                                              ...editDeviceModalDetails,
                                              serial_number: e.target.value,
                                            })
                                          }
                                          id="name"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        />
                                      </div>
                                      <div>
                                        <label
                                          htmlFor="firmwarePackage"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Firmware Package
                                        </label>
                                        <select
                                          id="firmwarePackage"
                                          value={editDeviceModalDetails.firmware_package_id}
                                          onChange={(e) => setEditDeviceModalDetails({ ...editDeviceModalDetails, firmware_package_id: e.target.value })}
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        >
                                          <option defaultValue>
                                            Select a Firmware Package
                                          </option>
                                          {Object.entries(firmwarePackage).map(([key, value]) => (
                          <option key={key} value={key}>
                            {value}
                          </option>
                        ))}
                                        </select>
                                      </div>
                                      <div>
                                        <label
                                          htmlFor="friendlyDeviceName"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Friendly Device Name
                                        </label>
                                        <input
                                          type="text"
                                          name="friendlyDeviceName"
                                          value={
                                            editDeviceModalDetails.device_identifier
                                          }
                                          onChange={(e) =>
                                            setEditDeviceModalDetails({
                                              ...editDeviceModalDetails,
                                              device_identifier: e.target.value,
                                            })
                                          }
                                          id="friendlyDeviceName"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        />
                                      </div>

                                      <div>
                                        <label
                                          htmlFor="countries"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          OTA Enabled
                                        </label>
                                        <select
                                          id="countries"
                                          value={
                                            editDeviceModalDetails.ota_enabled
                                          }
                                          onChange={(e) =>
                                            setEditDeviceModalDetails({
                                              ...editDeviceModalDetails,
                                              ota_enabled: e.target.value,
                                            })
                                          }
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        >
                                          <option value="1">Yes</option>
                                          <option value="0">No</option>
                                        </select>
                                      </div>
                                      <div>
                                        <label
                                          htmlFor="deviceType"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Device Type
                                        </label>
                                        <select
                                          id="countries"
                                          value={editDeviceModalDetails.type}
                                          onChange={(e) =>
                                            setEditDeviceModalDetails({
                                              ...editDeviceModalDetails,
                                              type: e.target.value,
                                            })
                                          }
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        >
                                          <option value="0">
                                            Raspberry Pi
                                          </option>
                                          <option value="1">
                                            S3 Renew Battery
                                          </option>
                                          <option value="2">S3 Home</option>
                                        </select>
                                      </div>
                                      <div>
                                        <label
                                          htmlFor="otaLastRequest"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          OTA Last Request
                                        </label>
                                        <input
                                          type="text"
                                          name="otaLastRequest"
                                          value={
                                            editDeviceModalDetails.ota_last_request
                                          }
                                          onChange={(e) =>
                                            setEditDeviceModalDetails({
                                              ...editDeviceModalDetails,
                                              ota_last_request: e.target.value,
                                            })
                                          }
                                          id="otaLastRequest"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        />
                                      </div>
                                      <div>
                                        <label
                                          htmlFor="deviceModel"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Device Model
                                        </label>
                                        <input
                                          type="text"
                                          name="deviceModel"
                                          value={editDeviceModalDetails.model}
                                          onChange={(e) =>
                                            setEditDeviceModalDetails({
                                              ...editDeviceModalDetails,
                                              model: e.target.value,
                                            })
                                          }
                                          id="deviceModel"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        />
                                      </div>
                                      <div>
                                        <label
                                          htmlFor="oldFirmware"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          OLD Firmware (deprecated)
                                        </label>
                                        <select
                                          id="oldFirmware"
                                          value={editDeviceModalDetails.last_firmware}
                                  onChange={(e) => setEditDeviceModalDetails({...editDeviceModalDetails, last_firmware: e.target.value})}

                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        >
                                          <option defaultValue>
                                            Select Version
                                          </option>
                                          {Object.entries(firmwareVersion).map(
                          ([category, versions]) => (
                            <optgroup key={category} label={category}>
                              {Object.entries(versions).map(
                                ([version, label]) => (
                                  <option
                                    key={`${category}-${version}`}
                                    value={version}
                                  >
                                    {label}
                                  </option>
                                )
                              )}
                            </optgroup>
                          )
                        )}
                                        </select>
                                      </div>
                                      <div>
                                        <label
                                          htmlFor="status"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Status
                                        </label>
                                        <select
                                          id="status"
                                          value={editDeviceModalDetails.status}
                                          onChange={(e) =>
                                            setEditDeviceModalDetails({
                                              ...editDeviceModalDetails,
                                              status: e.target.value,
                                            })
                                          }
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        >
                                          <option value="2">Pending</option>
                                          <option value="1">Enabled</option>
                                          <option value="0">Disabled</option>
                                          <option value="3">Deleted</option>
                                        </select>
                                      </div>
                                      <div>
                                        <label
                                          htmlFor="deviceSimNumber"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Device Sim Number
                                        </label>
                                        <input
                                          type="text"
                                          value={
                                            editDeviceModalDetails.sim_phone_number
                                          }
                                          onChange={(e) =>
                                            setEditDeviceModalDetails({
                                              ...editDeviceModalDetails,
                                              sim_phone_number: e.target.value,
                                            })
                                          }
                                          name="deviceSimNumber"
                                          id="deviceSimNumber"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        />
                                      </div>
                                      <div>
                                        <label
                                          htmlFor="modemNumber"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Modem Number
                                        </label>
                                        <input
                                          type="text"
                                          name="modemNumber"
                                          value={
                                            editDeviceModalDetails.modem_number
                                          }
                                          onChange={(e) =>
                                            setEditDeviceModalDetails({
                                              ...editDeviceModalDetails,
                                              modem_number: e.target.value,
                                            })
                                          }
                                          id="modemNumber"
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        />
                                      </div>
                                      <div>
                                        <label
                                          htmlFor="countries"
                                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                          Sim Carrier{" "}
                                        </label>
                                        <select
                                          id="countries"
                                          value={
                                            editDeviceModalDetails.sim_carrier
                                          }
                                          onChange={(e) =>
                                            setEditDeviceModalDetails({
                                              ...editDeviceModalDetails,
                                              sim_carrier: e.target.value,
                                            })
                                          }
                                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        >
                                          <option value="" disabled>
                                            Select Carrier Type
                                          </option>
                                          <option value="0">Verizon</option>
                                          <option value="1">T-Mobile</option>
                                          <option value="2">Sprint</option>
                                          <option value="3">AT & T</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div>
                                      <label
                                        htmlFor="message"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                      >
                                        Your message
                                      </label>
                                      <textarea
                                        id="message"
                                        rows="4"
                                        value={editDeviceModalDetails.notes}
                                        onChange={(e) =>
                                          setEditDeviceModalDetails({
                                            ...editDeviceModalDetails,
                                            notes: e.target.value,
                                          })
                                        }
                                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Write your thoughts here..."
                                      ></textarea>
                                    </div>

                                    <div className="flex items-center justify-end mt-2">
                                      <button
                                        className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                                        style={{ backgroundColor: "red" }}
                                        onClick={() =>
                                          setEditDeviceModal(false)
                                        }
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        type="submit"
                                        className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center"
                                        style={{ backgroundColor: "#009D01" }}
                                      >
                                        Update
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          )}
                          <div
                            onClick={handleRefresh}
                            className="cursor-pointer px-2 flex flex-row p-1 bg-blue-900 text-white rounded-lg items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="size-4"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                              />
                            </svg>

                            <h1>Refresh</h1>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="2"
                    className="px-6 py-4 flex justify-center text-center"
                  >
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
              )}
            </table>
          </div>

          <div className="my-8 p-2 shadow-lg border border-gray-200 dark:border-gray-600 rounded-lg">
            <div className="relative overflow-x-auto">
              <div className="p-1 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
                <h1 className="ml-5">Zone Visual </h1>
              </div>
              {zoneListLoading ? (
                <div colSpan="3" className="h-64">
                  <div
                    role="status"
                    className="flex justify-center items-center h-full"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                dateHeaders.map((date, index) => (
                  <div key={index}>
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mb-6">
                      <tbody>
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 ">
                          <th
                            scope="col"
                            className="px-6 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            <h1>Date: {date}</h1>
                            <h1
                              className="hover:underline cursor-pointer text-blue-600"
                              onClick={() => toggleAlertsModal(date)}
                            >
                              Alerts
                            </h1>
                            <h1
                              className="hover:underline cursor-pointer text-blue-600"
                              onClick={() => toggleDetailsModal(date)}
                            >
                              Details
                            </h1>
                          </th>

                          {zoneListLoading ? (
                            <div
                              role="status"
                              className="flex justify-center items-center h-16"
                            >
                              <svg
                                aria-hidden="true"
                                className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : (
                            <div className="overflow-x-auto px-3">
                              <table className="secondTable w-full text-sm text-left rtl:text-right text-black dark:text-white mb-6">
                                <thead>
                                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 items-center">
                                    <th>Prg</th>
                                    {Array.from(
                                      { length: zoneCount },
                                      (_, i) => (
                                        <th
                                          key={i}
                                          className="border border-black dark:border-white"
                                        >
                                          <div className="flex justify-center ">
                                            {i + 1}
                                          </div>
                                        </th>
                                      )
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.keys(
                                    zoneVisualData[date]?.zones || {}
                                  ).map((program) => (
                                    <tr key={program}>
                                      <td>{program}</td>
                                      {Array.from(
                                        { length: zoneCount },
                                        (_, i) => {
                                          const actualValue =
                                            zoneVisualData[date]?.zones[
                                              program
                                            ]?.[i]?.actual;
                                          const expectedValue =
                                            zoneVisualData[date]?.zones[
                                              program
                                            ]?.[i]?.expected;
                                          const flowValue =
                                            zoneVisualData[date]?.zones[
                                              program
                                            ]?.[i]?.flow;

                                          // Determine the background color based on the new conditions
                                          let backgroundColor = "#62cb31";
                                          if (actualValue === 0) {
                                            backgroundColor = "gray";
                                          } else if (
                                            actualValue <
                                              0.25 * expectedValue &&
                                            actualValue > 0
                                          ) {
                                            backgroundColor = "orange";
                                          }

                                          return (
                                            <td
                                              key={i}
                                              className="border border-black dark:border-white "
                                            >
                                              <div className="flex justify-center items-center">
                                                <h1
                                                  style={{
                                                    color: "white",
                                                    backgroundColor,
                                                    margin: 0,
                                                  }}
                                                  className="p-1 text-xs text-center rounded-sm w-fit px-2"
                                                >
                                                  {actualValue !== undefined
                                                    ? actualValue
                                                    : "-"}{" "}
                                                  mins
                                                </h1>{" "}
                                                &nbsp;
                                                {hasFlowSensor && <h1> / </h1>}
                                              </div>

                                              {hasFlowSensor && (
                                                <div className="flex justify-center mr-2.5">
                                                  <h1
                                                    className={`mt-1 p-1 text-xs text-center rounded-sm w-fit px-2 `}
                                                    style={
                                                      flowValue == 0
                                                        ? {
                                                            backgroundColor:
                                                              "gray",
                                                            color: "white",
                                                          }
                                                        : {
                                                            backgroundColor:
                                                              "#337ab7",
                                                            color: "white",
                                                          }
                                                    }
                                                  >
                                                    {flowValue} GPM
                                                  </h1>
                                                </div>
                                              )}
                                            </td>
                                          );
                                        }
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </tr>
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            Manual runs
                          </th>
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          ></th>
                        </tr>
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            Legend
                          </th>
                          <th
                            scope="row"
                            className="px-6 py-4 text-sm font-medium text-white whitespace-nowrap dark:text-white"
                          >
                            <div className="flex flex-row gap-2">
                              <div
                                className="p-1 px-2 rounded-xl flex items-center gap-1"
                                style={{ backgroundColor: "gray" }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="size-4"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M6 18 18 6M6 6l12 12"
                                  />
                                </svg>

                                <h1 className="text-xs">Sprinkling OFF</h1>
                              </div>
                              <div
                                className="p-1 px-2 rounded-xl flex items-center gap-1"
                                style={{ backgroundColor: "#057a55" }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="size-4"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="m4.5 12.75 6 6 9-13.5"
                                  />
                                </svg>
                                <h1 className="text-xs">Sprinkling ON</h1>
                              </div>
                              <div
                                className="p-1 px-2 rounded-xl flex items-center gap-1"
                                style={{ backgroundColor: "orange" }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="size-4"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
                                  />
                                </svg>

                                <h1 className="text-xs">Warning</h1>
                              </div>
                              <div className="p-1 px-2 bg-red-600 rounded-xl flex items-center gap-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="size-4"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                                  />
                                </svg>

                                <h1 className="text-xs">Alert</h1>
                              </div>
                            </div>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ))
              )}
            </div>
          </div>

          {hasflowSensorGraph && !hasPressureSensor && (
            <div className="mt-4 shadow-md rounded-md">
              <h1 className="text-lg font-semibold ml-2 text-black dark:text-white">
                Flow Visual
              </h1>
              <div className="flex justify-center">
                <FlowVisual flowData={flowData} />
              </div>
            </div>
          )}

          {!hasflowSensorGraph && hasPressureSensor && (
            <div className="mt-4 shadow-md rounded-md">
              <h1 className="text-lg font-semibold ml-2 text-black dark:text-white">
                Pressure Visual
              </h1>
              <div className="flex justify-center">
                <PressureVisual pressureJsonString={pressureJsonString} />
              </div>
            </div>
          )}

          <div className="flex flex-col md:flex-row justify-between mt-6 gap-5">
            <div className="leftSide w-1/2 shadow-lg border border-gray-50 rounded-lg">
              <h1 className="text-xl font-semibold p-4 text-black dark:text-white ">
                Last 10 Heartbeats
              </h1>

              {hbData.length > 0 ? (
                hbData.map((hb, index) => {
                  // Ensure hb and hb_details exist
                  const hbDetails = hb?.hb_details || {};

                  return (
                    <div className="overflow-x-auto">
                      <table
                        key={hb.hb_id}
                        className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mb-8"
                      >
                        <caption
                          className={`border border-black dark:border-white p-3 text-md rounded-t-md font-bold text-left rtl:text-right bg-${color}-200 text-${color}-800`}
                          style={{
                            backgroundColor: `${backgroundColor}`,
                            color: `${textColor}`,
                          }}
                        >
                          {formatDate(hb.hb_datetime)}
                        </caption>

                        <tbody>
                          <div>
                            <tr className="bg-white border-b text-black dark:text-white dark:bg-gray-800 dark:border-gray-700">
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black dark:border-white"
                              >
                                up_time
                              </th>
                              <td className="px-6 py-4 border border-black dark:border-white">
                                {hbDetails.up_time || "-"}
                              </td>
                            </tr>
                            <tr className="bg-white border-b text-black dark:text-white dark:bg-gray-800 dark:border-gray-700">
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black dark:border-white"
                              >
                                config_id
                              </th>
                              <td className="px-6 py-4 border border-black dark:border-white">
                                {hbDetails.config_id || "-"}
                              </td>
                            </tr>
                            <tr className="bg-white border-b text-black dark:text-white dark:bg-gray-800 dark:border-gray-700">
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black dark:border-white"
                              >
                                temperature
                              </th>
                              <td className="px-6 py-4 border border-black dark:border-white">
                                {hbDetails.temperature
                                  ? `${hbDetails.temperature}°C`
                                  : "-"}
                              </td>
                            </tr>
                            <tr className="bg-white border-b text-black dark:text-white dark:bg-gray-800 dark:border-gray-700">
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black dark:border-white"
                              >
                                signal
                              </th>
                              <td className="px-6 py-4 border border-black dark:border-white">
                                {hbDetails.signal || "-"}
                              </td>
                            </tr>
                            <tr className="bg-white border-b text-black dark:text-white dark:bg-gray-800 dark:border-gray-700">
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black dark:border-white"
                              >
                                schedule_id
                              </th>
                              <td className="px-6 py-4 border border-black dark:border-white">
                                {hbDetails.schedule_id || "-"}
                              </td>
                            </tr>
                            <tr className="bg-white border-b text-black dark:text-white dark:bg-gray-800 dark:border-gray-700">
                              <th
                                scope="col"
                                className="px-6 py-3 cursor-pointer border border-black dark:border-white"
                                onClick={() => toggleAccordionHeader(index)}
                              >
                                header
                              </th>
                              <td className="px-6 flex flex-col border border-black dark:border-white">
                                <button
                                  className="flex justify-end py-4 "
                                  onClick={() => toggleAccordionHeader(index)}
                                  aria-expanded={accordionHeader === index}
                                >
                                  <svg
                                    data-accordion-icon
                                    className={`w-3 h-3 transition-transform ${
                                      accordionHeader === index
                                        ? "rotate-180"
                                        : ""
                                    }`}
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 10 6"
                                    aria-hidden="true"
                                  >
                                    <path
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M9 5 5 1 1 5"
                                    />
                                  </svg>
                                </button>
                                <div
                                  className={`transition-all duration-500 overflow-hidden ${
                                    accordionHeader === index
                                      ? "max-h-screen"
                                      : "max-h-0"
                                  }`}
                                >
                                  <pre className="whitespace-pre-wrap p-4 rounded font-mono">
                                    {JSON.stringify(hbDetails.header, null, 2)}
                                  </pre>
                                </div>
                              </td>
                            </tr>

                            <tr className="bg-white border-b text-black dark:text-white dark:bg-gray-800 dark:border-gray-700">
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black dark:border-white"
                              >
                                state
                              </th>
                              <td className="px-6 py-4 border border-black dark:border-white">
                                {hbDetails.state || "-"}
                              </td>
                            </tr>
                            <tr className="bg-white border-b text-black dark:text-white dark:bg-gray-800 dark:border-gray-700">
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black dark:border-white"
                              >
                                flow_id
                              </th>
                              <td className="px-6 py-4 border border-black dark:border-white">
                                {hbDetails.flow_id || "-"}
                              </td>
                            </tr>
                            <tr className="bg-white border-b text-black dark:text-white dark:bg-gray-800 dark:border-gray-700">
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black dark:border-white"
                              >
                                extra_content
                              </th>
                              <td className="px-6 py-4 border border-black dark:border-white">
                                {hbDetails.extra_content || "-"}
                              </td>
                            </tr>
                            <tr className="bg-white border-b text-black dark:text-white dark:bg-gray-800 dark:border-gray-700">
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black dark:border-white"
                              >
                                firmware
                              </th>
                              <td className="px-6 py-4 border border-black dark:border-white">
                                {hbDetails.firmware || "-"}
                              </td>
                            </tr>
                            <tr className="bg-white border-b text-black dark:text-white dark:bg-gray-800 dark:border-gray-700">
                              <th
                                scope="col"
                                className="px-6 py-3 border border-black dark:border-white"
                              >
                                ip
                              </th>
                              <td className="px-6 py-4 border border-black dark:border-white">
                                {hbDetails.ip || "-"}
                              </td>
                            </tr>
                          </div>
                        </tbody>
                      </table>
                    </div>
                  );
                })
              ) : (
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mb-8">
                  <tbody>
                    <tr>
                      <td colSpan="7" className="text-center py-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="w-8 h-8 mx-auto text-gray-500 dark:text-gray-400"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <line x1="12" y1="8" x2="12" y2="12"></line>
                          <line x1="12" y1="16" x2="12.01" y2="16"></line>
                        </svg>
                        <p className="animate__animated animate__fadeIn mt-2 text-sm text-gray-500 dark:text-gray-400">
                          No last heartbeat data found
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
            <div className="rightSide w-1/2 shadow-lg rounded-lg">
              <div className="border-2 h-full rounded-lg">
                <h1 className="text-xl font-semibold p-4 text-black dark:text-white ">
                  Bin Schedule [Debug]
                </h1>
                <div className="relative">
                  <table class="w-full text-sm text-left rtl:text-right text-black dark:text-white">
                    <thead class="text-md text-black uppercase bg-gray-50 dark:bg-gray-700 dark:text-white">
                      <tr
                        className="border border-black dark:border-white"
                        style={{
                          backgroundColor: `${backgroundColor}`,
                          color: `${textColor}`,
                        }}
                      >
                        <th scope="col" class="px-6 py-3">
                          Effective Date Extra Info
                        </th>
                        <th scope="col" class="px-6 py-3">
                          Details
                        </th>
                        <th scope="col" class="px-6 py-3"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {scheduleDebugData.map((data) => (
                        <tr
                          class="bg-white dark:bg-gray-800 border border-black dark:border-white"
                          key={data.id}
                        >
                          <td class="px-3 py-4 border border-black dark:border-white">
                            <div>
                              <div
                                onClick={() => handleDebugDateClick(data.id)}
                                className="relative flex cursor-pointer items-center gap-1 p-1 border border-black w-fit rounded-sm"
                              >
                             <span>{data.effective_date}</span> 

                                <h1>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="size-5"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                                    />
                                  </svg>
                                </h1>

                                {activePopover === data.id && (
                                  <div
                                    data-popover
                                    id="popover-default"
                                    role="tooltip"
                                    className="absolute z-50 w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-100 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800"
                                    style={{ top: "100%", left: "0" }} // Adjust position as needed
                                  >
                                    <div
                                      style={{
                                        backgroundColor: `${backgroundColor}`,
                                      }}
                                      className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700"
                                    >
                                      <h3
                                        style={{
                                          color: `${textColor}`,
                                        }}
                                        className="font-semibold text-black dark:text-white"
                                      >
                                        Decision Details
                                      </h3>
                                    </div>
                                    <div className="px-3 py-2">
                                      <p className="text-black dark:text-white">
                                        {data.decision_details}
                                      </p>
                                    </div>
                                    <div data-popper-arrow></div>
                                  </div>
                                )}
                              </div>
                              <h1>ID: {data.id}</h1>

                              <h1>Added Date: {data.date_added}</h1>
                            </div>
                          </td>
                          <td class="px-6 py-4 text-red-600 border border-black dark:border-white">
                            <div>
                              <h1>PK ID = {data.company_location_device_id}</h1>
                              <h1>Days = {data.days}</h1>
                              <h1>Shift = {data.is_shift}</h1>
                              <h1>Start = {data.program_start_times}</h1>
                              <h1>Zones = {data.zone_duration}</h1>
                              <h1>Custom = {data.custom_programs}</h1>
                            </div>
                          </td>
                          <td className="px-6 py-4 border border-black dark:border-white">
                            <div
                              onClick={() => handleDetailClick(data.id)}
                              className="relative flex cursor-pointer items-center gap-1 p-1 border border-black w-fit rounded-sm"
                            >
                              <h1>Details</h1>
                              <h1>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="size-5"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                                  />
                                </svg>
                              </h1>
                              {activeDetailPopover === data.id && (
                                <div
                                  data-popover
                                  id="popover-default"
                                  role="tooltip"
                                  className="absolute z-50 w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-100 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800"
                                  style={{ top: "110%", right: "3%" }} // Adjust the right to position the popover on the left
                                >
                                  <div
                                    style={{
                                      backgroundColor: `${backgroundColor}`,
                                    }}
                                    className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700"
                                  >
                                    <h3
                                      style={{ color: `${textColor}` }}
                                      className="font-semibold text-black dark:text-white"
                                    >
                                      Decision Details
                                    </h3>
                                  </div>
                                  <div className="px-3 py-2">
                                    <p className="text-black dark:text-white">
                                      {extractDecisionDetails(data.decision_details)}
                                    </p>
                                  </div>
                                  <div data-popper-arrow></div>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8">
            <div className="p-1 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
              <h1 className="ml-5">Location Device Programs </h1>
            </div>
            <div className="relative overflow-x-auto">
              <table className="border border-black dark:border-white w-full text-sm text-left rtl:text-right text-black dark:text-white">
                <thead
                  className={`text-sm uppercase leading-4`}
                  style={{
                    backgroundColor: `${tableHeaderColor}`,
                    color: `${tableHeaderTextColor}`,
                  }}
                >
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 border border-black dark:border-white "
                    >
                      Program
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 border border-black dark:border-white "
                    >
                      Start Time
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 border border-black dark:border-white "
                    >
                      End Time
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 border border-black dark:border-white "
                    >
                      Duration (mins)
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 border border-black dark:border-white "
                    >
                      Program Type
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 border border-black dark:border-white "
                    >
                      Scheduled Days
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 border border-black dark:border-white "
                    >
                      Zone Info
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 border border-black dark:border-white "
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="leading-4">
                  {loading ? (
                    <tr>
                      <td colSpan="8" className="h-16">
                        <div
                          role="status"
                          className="flex justify-center items-center h-full"
                        >
                          <svg
                            aria-hidden="true"
                            className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span className="sr-only">Loading...</span>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    Object.entries(zoneListData.Programs).map(
                      ([program, details]) => (
                        <tr
                          key={program}
                          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                        >
                          <td className="border border-black dark:border-white dark:border-gray-300 px-6 py-4">
                            {program}
                          </td>
                          <td className="border border-black dark:border-white dark:border-gray-300 px-6 py-4">
                            {formatTimeTo12Hour(details[0].schedule.start_time)}
                          </td>
                          <td className="border border-black dark:border-white dark:border-gray-300 px-6 py-4">
                            {formatTimeTo12Hour(details[0].schedule.end_time)}
                          </td>

                          <td className="border border-black dark:border-white dark:border-gray-300 px-6 py-4">
                            {calculateDuration(
                              details[0].schedule.start_time,
                              details[0].schedule.end_time
                            )}
                          </td>
                          <td className="border border-black dark:border-white dark:border-gray-300 px-6 py-4">
                            <button
                              className={`relative w-fit mt-2 inline-flex items-center justify-center p-0.5 mb-2 me-2 text-sm font-medium text-gray-900 rounded-lg group dark:text-white bg-${color}-200`}
                              style={{
                                backgroundColor: `${backgroundColor}`,
                                color: `${textColor}`,
                              }}
                            >
                              <span
                                className={`relative px-2 py-2 transition-all ease-in duration-75 text-white font-bold rounded-md`}
                              >
                                {details[0].schedule.is_regular
                                  ? "Regular"
                                  : ""}

                                {details[0].schedule.is_half_sprinkle === 1
                                  ? " 1/2 Sprinkle"
                                  : ""}

                                {!(
                                  details[0].schedule.is_regular ||
                                  details[0].schedule.is_next_day === 1 ||
                                  details[0].schedule.is_half_sprinkle === 1
                                ) && "Default"}
                              </span>

                              <span class>
                                {details[0].schedule.is_next_day === 1 ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="blue"
                                    class="size-4"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                                    />
                                  </svg>
                                ) : (
                                  ""
                                )}
                              </span>
                            </button>
                          </td>
                          <td className="border border-black dark:border-white dark:border-gray-300 px-6 py-4">
                            <div className="flex flex-col">
                              {formatDays(details[0].days)}
                            </div>
                          </td>
                          <td className="border border-black dark:border-white dark:border-gray-300 px-6 py-4">
                            <div className="flex flex-col">
                              {formatZones(
                                zoneListData.Zones[program],
                                uploadedImages,
                                zoneLabels,
                                program
                              )}{" "}
                            </div>
                          </td>
                          <td className="border border-black dark:border-white dark:border-gray-300 px-6 py-4 leading-4">
                            <div className="flex gap-3 items-center">
                              <button
                                className="edit"
                                onClick={() =>
                                  handleSelectedProgramDetails({
                                    program,
                                    start: details[0].schedule.start_time,
                                    end: details[0].schedule.end_time,
                                    zones: zoneListData.Zones[program],
                                    type: details[0].schedule,
                                    days: details[0].days,
                                  })
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="blue"
                                  className="size-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                  />
                                </svg>
                              </button>
                              <button className="delete">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="red"
                                  className="size-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                  />
                                </svg>
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex mt-8 shadow-md">
            {fullScreenImage && (
              <div
                className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
                onClick={() => setFullScreenImage(null)}
              >
                <img
                  src={fullScreenImage}
                  alt="Full screen"
                  className="max-h-screen max-w-screen object-contain"
                />
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />

                  <button onClick={handleSvgClick}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="white"
                      className="size-8"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15M9 12l3 3m0 0 3-3m-3 3V2.25"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {alertsModal && (
        <div
          id="defaultModal"
          tabindex="-1"
          aria-hidden="true"
          className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full font-inter"
          style={{
            // // backdropFilter: "blur(5px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="relative p-4 w-full max-w-4xl h-full md:h-auto">
            <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
              <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Feedback Alerts for {selectedDate}
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal"
                  onClick={() => toggleAlertsModal(null)}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>

              <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right text-black dark:text-white">
                  <thead
                    className="text-xs uppercase border border-black dark:border-white bg-gray-50 dark:bg-gray-700 text-black dark:text-white"
                    style={{
                      backgroundColor: `${tableHeaderColor}`,
                      color: `${tableHeaderTextColor}`,
                    }}
                  >
                    <tr
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 "
                      style={{
                        backgroundColor: `${tableHeaderColor}`,
                        color: `${tableHeaderTextColor}`,
                      }}
                    >
                      <th
                        scope="col"
                        className="px-6 py-3 border border-black dark:border-white "
                      >
                        Timestamp
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 border border-black dark:border-white "
                      >
                        Code
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 border border-black dark:border-white "
                      >
                        Description
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedAlerts.length > 0 ? (
                      selectedAlerts.map((alert, alertIndex) => (
                        <tr
                          key={alertIndex}
                          className="border border-black dark:border-white bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white odd:dark:bg-gray-900 even:bg-purple-200 even:dark:bg-gray-800"
                        >
                          <td className="px-6 py-4 border border-black dark:border-white">
                            {formatTimestamp(alert._timestamp)}
                          </td>
                          <td className="px-6 py-4 border border-black dark:border-white">
                            {alert._type}
                          </td>
                          <td className="px-6 py-4 border border-black dark:border-white">
                            {alert._details}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center py-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="w-8 h-8 mx-auto text-gray-500 dark:text-gray-400"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="12" y1="8" x2="12" y2="12"></line>
                            <line x1="12" y1="16" x2="12.01" y2="16"></line>
                          </svg>
                          <p className="animate__animated animate__fadeIn mt-2 text-sm text-gray-500 dark:text-gray-400">
                            No Device Alerts Available
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

      {detailsModal && (
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed inset-0 z-50 flex items-center justify-center p-4 font-inter"
          style={{
            // // backdropFilter: "blur(5px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="relative w-full max-w-8xl max-h-full overflow-auto">
            {/* <!-- Modal content --> */}
            <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
              {/* <!-- Modal header --> */}
              <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Details for {selectedDate}
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal"
                  onClick={toggleDetailsModal}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* <!-- Modal body --> */}

              <div className="overflow-x-auto">
                <div>
                  <h1 className="text-lg font-semibold mb-2 text-black dark:text-white">
                    Zone Runs
                  </h1>
                  <div className="overflow-x-auto">
                    <table className="w-full text-sm text-left rtl:text-right text-black dark:text-white">
                      <thead
                        className="text-xs uppercase bg-gray-50 dark:bg-gray-700 text-black dark:text-white sticky top-0 z-10"
                        style={{
                          backgroundColor: `${tableHeaderColor}`,
                          color: `${tableHeaderTextColor}`,
                        }}
                      >
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 border border-black dark:border-white "
                          >
                            Program
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border border-black dark:border-white "
                          >
                            Start Offset
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border border-black dark:border-white "
                          >
                            Zone #
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border border-black dark:border-white "
                          >
                            Volt Duration
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border border-black dark:border-white "
                          >
                            Actual
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border border-black dark:border-white "
                          >
                            Expected
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border border-black dark:border-white "
                          >
                            Volt
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border border-black dark:border-white "
                          >
                            Curr
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border border-black dark:border-white "
                          >
                            Flow
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border border-black dark:border-white "
                          >
                            Xfrm Volt
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(
                          zoneVisualData[selectedDate]?.zones || {}
                        ).length > 0 ? (
                          Object.entries(
                            zoneVisualData[selectedDate]?.zones || {}
                          ).map(([program, zones]) =>
                            zones.map((zone, index) => (
                              <tr
                                key={`${program}-${index}`}
                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white odd:dark:bg-gray-900 even:bg-purple-200 even:dark:bg-gray-800"
                              >
                                <td className="px-6 py-4 border border-black dark:border-white">
                                  {program}
                                </td>
                                <td className="px-6 py-4 border border-black dark:border-white">
                                  {zoneVisualData[selectedDate]?.start_offset[
                                    program
                                  ][index] || 0}
                                </td>
                                <td className="px-6 py-4 border border-black dark:border-white">
                                  {zone.Zone_number + 1}
                                </td>
                                <td className="px-6 py-4 border border-black dark:border-white">
                                  {zone.volt_duration}
                                </td>
                                <td className="px-6 py-4 border border-black dark:border-white">
                                  {zone.actual}
                                </td>
                                <td className="px-6 py-4 border border-black dark:border-white">
                                  {zone.expected}
                                </td>
                                <td className="px-6 py-4 border border-black dark:border-white">
                                  {zone.voltage}
                                </td>
                                <td className="px-6 py-4 border border-black dark:border-white">
                                  {zone.current}
                                </td>
                                <td className="px-6 py-4 border border-black dark:border-white">
                                  {zone.flow}
                                </td>
                                <td className="px-6 py-4 border border-black dark:border-white">
                                  {zone.xfmr_voltage}
                                </td>
                              </tr>
                            ))
                          )
                        ) : (
                          <tr>
                            <td colSpan="10" className="text-center py-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="w-8 h-8 mx-auto text-gray-500 dark:text-gray-400"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="12"></line>
                                <line x1="12" y1="16" x2="12.01" y2="16"></line>
                              </svg>
                              <p className="animate__animated animate__fadeIn mt-2 text-sm text-gray-500 dark:text-gray-400">
                                No Zone Runs Data Available
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="pt-4 border-t-2 border-gray-200 mt-2">
                  <h1 className="text-lg font-semibold mb-2 text-black dark:text-white">
                    Manual Runs
                  </h1>
                  <div className="overflow-x-auto">
                    <table className="w-full text-sm text-left rtl:text-right text-black dark:text-white">
                      <thead
                        className="text-xs uppercase bg-gray-50 dark:bg-gray-700 text-black dark:text-white"
                        style={{
                          backgroundColor: `${tableHeaderColor}`,
                          color: `${tableHeaderTextColor}`,
                        }}
                      >
                        <tr className="">
                          <th
                            scope="col"
                            className="px-6 py-3 border border-black dark:border-white"
                          >
                            Timestamp
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border border-black dark:border-white"
                          >
                            Formatted Time
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 border border-black dark:border-white"
                          >
                            Duration
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(
                          zoneVisualData[selectedDate]?.manual_run || {}
                        ).length > 0 ? (
                          Object.entries(
                            zoneVisualData[selectedDate]?.manual_run || {}
                          ).map(([program, runs]) =>
                            runs.map((run, index) => {
                              const startTime = new Date(run.start_time * 1000);
                              const endTime = new Date(run.end_time * 1000);
                              let duration = (endTime - startTime) / 60000;
                              if (duration < 0) {
                                duration += 1440;
                              }

                              const formatTime = (date) => {
                                return date.toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                });
                              };

                              return (
                                <tr
                                  key={`${program}-${index}`}
                                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white odd:dark:bg-gray-900 even:bg-purple-200 even:dark:bg-gray-800"
                                >
                                  <td className="px-6 py-4">{program}</td>
                                  <td className="px-6 py-4">
                                    {formatTime(startTime)} -{" "}
                                    {formatTime(endTime)}
                                  </td>
                                  <td className="px-6 py-4">
                                    {duration.toFixed(2)} minutes
                                  </td>
                                </tr>
                              );
                            })
                          )
                        ) : (
                          <tr>
                            <td colSpan="3" className="text-center py-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="w-8 h-8 mx-auto text-gray-500 dark:text-gray-400"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="12"></line>
                                <line x1="12" y1="16" x2="12.01" y2="16"></line>
                              </svg>
                              <p className="animate__animated animate__fadeIn mt-2 text-sm text-gray-500 dark:text-gray-400">
                                No Manual Runs Data Available
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {deviceProgramModal && (
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed inset-0 z-50 flex items-center justify-center p-4 font-inter"
          style={{
            // // backdropFilter: "blur(5px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="relative w-full max-w-8xl max-h-full overflow-auto">
            {/* <!-- Modal content --> */}
            <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
              {/* <!-- Modal header --> */}
              <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Details for {selectedDate}
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal"
                  onClick={() => setDeviceProgramModal(!deviceProgramModal)}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* <!-- Modal body --> */}

              <div className="ml-1">
                <h1 className="font-semibold text-xl my-3 text-gray-900 dark:text-white">
                  Days of Week
                </h1>
                <div className="grid gap-4 mb-4 sm:grid-cols-6 border-b border-gray-300 pb-5">
                  <div>
                    <label
                      htmlFor="program"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Program Name
                    </label>
                    <select
                      id="program"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      defaultValue={selectedProgramDetails.program?.program}
                    >
                      <option value="A">A</option>
                      <option value="B">B</option>
                      <option value="C">C</option>
                      <option value="D">D</option>
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="startTime"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Start Time
                    </label>
                    <input
                      type="time"
                      id="startTime"
                      defaultValue={selectedProgramDetails.program?.start}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="endTime"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      End Time
                    </label>
                    <input
                      type="time"
                      id="endTime"
                      defaultValue={selectedProgramDetails.program?.end}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  <div className="flex items-center">
                    <input
                      id="isRegular"
                      type="checkbox"
                      checked={
                        selectedProgramDetails.program?.type.is_regular === 1
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="isRegular"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Is Regular
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="isHalfSprinkle"
                      type="checkbox"
                      checked={
                        selectedProgramDetails.program?.type
                          .is_half_sprinkle === 1
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="isHalfSprinkle"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      1/2 Sprinkle
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="isDayOverlap"
                      type="checkbox"
                      checked={
                        selectedProgramDetails.program?.type.is_next_day === 1
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="isDayOverlap"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      is Day Overlap
                    </label>
                  </div>
                </div>
                <div className="grid gap-4 mb-4 sm:grid-cols-7 border-b border-gray-300 pb-5 my-3">
                  {daysOfWeek.map((day) => (
                    <div key={day} className="flex items-center">
                      <input
                        id={day}
                        type="checkbox"
                        defaultChecked={
                          selectedProgramDetails.program?.days[day] === 1
                        }
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor={day}
                        className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        {day.charAt(0).toUpperCase() + day.slice(1)}
                      </label>
                    </div>
                  ))}
                </div>

                <div className="grid gap-4 mb-4 sm:grid-cols-6 border-b border-gray-300 pb-5 my-3">
                  {Array.from({ length: 42 }, (_, i) => i + 1).map((zone) => (
                    <div key={zone} className="relative">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Zone {zone}
                      </label>
                      <div className="relative">
                        <button
                          type="button"
                          onClick={() => toggleDropdown(zone)}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          {getZoneValue(zone)}
                        </button>
                        {openDropdown === zone && (
                          <div
                            ref={dropdownRef}
                            className="absolute z-10 w-full bg-white border border-gray-300 mt-1 rounded-lg shadow-lg max-h-60 overflow-y-auto"
                          >
                            <div className="p-2">
                              <input
                                type="text"
                                placeholder="Search..."
                                className="w-full p-2 border border-gray-300 rounded"
                                value={searchInputs[zone] || ""}
                                onChange={(e) =>
                                  setSearchInputs({
                                    ...searchInputs,
                                    [zone]: e.target.value,
                                  })
                                }
                                onClick={(e) => e.stopPropagation()}
                              />
                            </div>
                            {Array.from({ length: 121 }, (_, i) => i)
                              .filter((value) =>
                                value
                                  .toString()
                                  .includes(searchInputs[zone] || "")
                              )
                              .map((value) => (
                                <button
                                  key={value}
                                  onClick={() =>
                                    handleZoneValueChange(zone, value)
                                  }
                                  className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                                >
                                  {value}
                                </button>
                              ))}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex items-center justify-end">
                  <button
                    className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                    style={{ backgroundColor: "red" }}
                    onClick={() => setDeviceProgramModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center"
                    style={{ backgroundColor: "#009D01" }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
