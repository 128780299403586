import React, { useContext, useEffect, useState } from "react";
import AsideLayout from "./AsideLayout/AsideLayout";
import { ThemeContext } from "../../components/context/themeContext/ThemeContext";
import { useSelector } from "react-redux";
import ApiLink from "../../components/api/ApiLink";

export default function Firmware() {
  const { sideBarOpen } = useContext(ThemeContext);

  const {
    color,
    textColor,
    backgroundColor,
    tableHeaderColor,
    tableHeaderTextColor,
  } = useContext(ThemeContext);
  const [editFirmware, setEditFirmware] = useState(false);
  const is_read_only = useSelector((state) => state.auth.is_read_only);
  const [firmwareData, setFirmwareData] = useState([]);
  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);

  useEffect(() => {
    fetchFirmwareList();
  }, [email, password]);

  const fetchFirmwareList = async () => {
    try {
      const response = await ApiLink.post("/api/firmware-list", {
        email: email,
        password: password,
      });
      if (response.status == 200) {
        if (Array.isArray(response.data.FirmwarePackage)) {
          setFirmwareData(response.data.FirmwarePackage);
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };
  // const is_read_only = useState(0);
  const toggleEditFirmware = () => {
    setEditFirmware(!editFirmware);
  };

  const handleEditFirmwareSubmit = () => {};

  return (
    <AsideLayout>
      <div
        className={`min-h-screen bg-gray-100 dark:bg-gray-700 transition-all duration-300 `}
        style={{ marginLeft: sideBarOpen ? "16rem" : "0" }}
      >
        <div className="p-4 mt-20">
          <div className="p-4 shadow-md bg-white rounded-lg dark:bg-gray-800">
            <div className="flex flex-col">
              <div
                className={`p-3 text-xl font-bold text-left rtl:text-right text-black dark:text-white `}
              >
                List of Firmware Packages
              </div>
              <div className="flex flex-col my-2">
                <input
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  id="file_input"
                  type="file"
                />
              </div>
              <div className="p-2 border-gray-300 text-lg font-semibold ml-4 text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                Raspberry Pi
              </div>
              {/* <div className="py-2">
                <select
                  id="countries"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-fit p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="1">Enabled</option>
                  <option value="3">United States</option>
                  <option value="CA">Canada</option>
                  <option value="FR">France</option>
                  <option value="DE">Germany</option>
                </select>
              </div> */}
            </div>
            <div className="relative overflow-x-auto bg-white-200 dark:bg-gray-800 mb-4 ">
              <table className="border animate__animated animate__fadeIn w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 cursor-pointer font-inter leading-4">
                <thead
                  className="border border-black text-sm uppercase font-inter leading-4"
                  style={{
                    backgroundColor: `${tableHeaderColor}`,
                    color: `${tableHeaderTextColor}`,
                  }}
                >
                  <tr
                    className="odd:bg-gray-100 odd:dark:bg-gray-900 even:bg-purple-200 even:dark:bg-gray-800"
                    style={{
                      backgroundColor: `${tableHeaderColor}`,
                      color: `${tableHeaderTextColor}`,
                    }}
                  >
                    <th scope="col" className="px-6 py-3 border border-black">
                      <div>
                        <h1>Name</h1>
                        <h1>MD5</h1>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 border border-black">
                      Version
                    </th>
                    <th scope="col" className="px-6 py-3 border border-black">
                      Protocol
                    </th>
                    <th scope="col" className="px-6 py-3 border border-black">
                      Date Added
                    </th>
                    <th scope="col" className="px-6 py-3 border border-black">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm font-inter leading-4">
                  {firmwareData.length > 0 ? (
                    firmwareData.map((item, index) => (
                      <tr
                        key={index}
                        className="odd:bg-gray-100 odd:dark:bg-gray-900 even:bg-purple-200 even:dark:bg-gray-800 bg-white border-b text-black dark:text-white dark:bg-gray-800 dark:border-gray-700"
                      >
                        <td className="border border-black dark:border-white px-6 py-4">
                          <div>
                            <h1 className="text-gray-900 dark:text-white">
                              {item.name}
                            </h1>
                            <h1>{item.payload_md5}</h1>
                          </div>
                        </td>
                        <td className="border border-black dark:border-white px-6 py-4">
                          {item.version}
                        </td>
                        <td className="border border-black dark:border-white px-6 py-4">
                          {item.protocol_name}
                        </td>
                        <td className="border border-black dark:border-white px-6 py-4">
                          {item.date_added}
                        </td>
                        <td className="border border-black dark:border-white px-6 py-4">
                          {is_read_only !== 1 && (
                            <button
                              className="edit p-1 hover:bg-white dark:hover:bg-gray-200 rounded-full"
                              onClick={toggleEditFirmware}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="blue"
                                className="size-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                />
                              </svg>
                            </button>
                          )}
                          {is_read_only !== 1 && (
                            <button className="delete p-1 hover:bg-white dark:hover:bg-gray-200 rounded-full">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="red"
                                className="size-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                />
                              </svg>
                            </button>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                    <td colSpan="7" className="text-center py-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-8 h-8 mx-auto text-gray-500 dark:text-gray-400"
                      >
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="12" y1="8" x2="12" y2="12"></line>
                        <line x1="12" y1="16" x2="12.01" y2="16"></line>
                      </svg>

                      <p className="animate__animated animate__fadeIn mt-2 text-sm text-gray-500 dark:text-gray-400">
                        No Firmware List Found
                      </p>
                    </td>
                  </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {editFirmware && (
          <div
            id="defaultModal"
            tabindex="-1"
            aria-hidden="true"
            className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full font-inter"
            style={{
              // backdropFilter: "blur(5px)",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
              {/* <!-- Modal content --> */}
              <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                {/* <!-- Modal header --> */}
                <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    Edit Firmware Packages
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="defaultModal"
                    onClick={toggleEditFirmware}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                {/* <!-- Modal body --> */}
                <form action={handleEditFirmwareSubmit}>
                  <div className="grid gap-4 mb-4 sm:grid-cols-2">
                    <div>
                      <label
                        for="name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>
                    <div>
                      <label
                        for="version"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Version
                      </label>
                      <input
                        type="text"
                        name="version"
                        id="version"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>
                    <div>
                      <label
                        for="firmwareType"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Firmware Type
                      </label>
                      <select
                        id="firmwareType"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option selected>Select Device Type</option>
                        <option>Raspberry Pi</option>
                        <option>S3 Renew (battery)</option>
                        <option>S3 Home</option>
                      </select>
                    </div>
                    <div>
                      <label
                        for="firmwareProtocol"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Firmware Protocol
                      </label>
                      <select
                        id="firmwareProtocol"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option selected>Select Firmware Protocol</option>
                        <option>sample</option>
                        <option>sample</option>
                        <option>sample</option>
                      </select>
                    </div>
                    <div>
                      <label
                        for="status"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Status
                      </label>
                      <select
                        id="isDefault"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option selected>Enabled</option>
                        <option>Disabled</option>
                        <option>Pending</option>
                        <option>Deleted</option>
                      </select>
                    </div>
                    <div>
                      <label
                        for="message"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Note
                      </label>
                      <textarea
                        id="message"
                        rows="4"
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Your message here"
                      ></textarea>
                    </div>
                  </div>

                  <div className="flex items-center justify-end">
                    <button
                      className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                      style={{ backgroundColor: "red" }}
                      onClick={toggleEditFirmware}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center"
                      style={{ backgroundColor: "#009D01" }}
                    >
                      Save Flow Sensor
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </AsideLayout>
  );
}
